import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';

function PlayerInfo({ playerData, position, overall, league }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    let atAGlanceStats = [];
    let overall_rating = "";
    let playStyle = "";
    let Position = "";
    let record = "";
    let winPerc = "";
    let currentImage;
    
    try {
        currentImage = require(`./${league}/Logos/${playerData['transformedTeamName']}.png`);
    } catch (err) {
        currentImage = require(`./${league}/Logos/${league}_Logo.png`); // Fallback image if team logo not found
    }

    if (['C', 'LW', 'RW', 'LD', 'RD'].includes(position)) {
        atAGlanceStats = [
            { label: 'GP', value: playerData["Games Played"] },
            { label: 'G', value: playerData.Goals },
            { label: 'A', value: playerData.Assists },
            { label: 'P', value: playerData.Points },
        ];

        playStyle = (
            <span>
                <span>&#8226;</span> {playerData['Playstyle']}
            </span>
        );
        overall_rating = overall;
        Position = position;
        record = playerData.Record;
        winPerc = playerData["Win %"];

    } else {
        atAGlanceStats = [
            { label: 'GGP', value: playerData["Goalie games played"] },
            { label: 'GAA', value: playerData.GAA },
            { label: 'SV%', value: playerData["Save %"] },
            { label: 'SO', value: playerData.Shutouts },
        ];

        playStyle = "";
        overall_rating = overall;
        Position = "G";
        record = playerData["Goalie record"];
        winPerc = String(((parseInt(playerData["Goalie wins"]) / parseInt(playerData["Goalie games played"])) * 100).toFixed(1));
    }

    return (
        <Box sx={{
            maxHeight: '500px',
            width: '95%',
            borderRadius: 5,
            backgroundColor: '#171717',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            color: 'white',
            marginTop: 8,
            marginX: 'auto',
            fontSize: 22,
            overflow: 'clip',
            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
            border: 'solid #656565 .001rem',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: windowWidth < 600 ? -60 : windowWidth < 900 ? 20 : 20,
                bottom: 0,
                backgroundImage: `url(${currentImage})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: windowWidth < 600 ? '75%' : windowWidth < 900 ? '30%' : '30%',
                opacity: 0.165,
                zIndex: 2,
            }
        }}>
            <div style={{ zIndex: 10, marginTop: 15, marginLeft: 15 }}>
                <Typography sx={{ fontSize: 30, fontWeight: 750, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData['USERNAME']?.toUpperCase()}</Typography>
                <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData['Team Name']?.toUpperCase()} <span>&#8226;</span> {Position} </Typography>
                <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{overall_rating} OVR {playStyle}</Typography>
            </div>
            <img src={currentImage} alt="" style={{ display: 'none' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', marginTop: 1 }}>
                {atAGlanceStats.map((stat, index) => (
                    <React.Fragment key={stat.label}>
                        {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                        <Box sx={{ padding: 2, paddingX: windowWidth < 600 ? 2 : windowWidth < 900 ? 5 : 5, textAlign: 'start' }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#e1e1e1', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.label}
                            </Typography>
                            <Typography variant="h7" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.value}
                            </Typography>

                        </Box>
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
}

export default PlayerInfo;
