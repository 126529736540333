import React, {useEffect} from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Container } from '@mui/material';
import AdComponent from './AdComponent';

// Data for all sections
const tableData = [
    {
        title: "Shooting",
        headers: ["Stat", "Wingers", "Centers", "Defense"],
        rows: [
            ["Goals per game", "0.95", "0.95", "1.75"],
            ["Shot %", "0.50", "0.50", "1.80"],
            ["Breakaway %", "0.20", "0.20", "0.40"],
            ["Penalty shot %", "0.20", "0.20", "0.40"],
            ["Shots on net %", "0.25", "0.25", "0.60"],
            ["Shots per game", "0.01", "0.01", "0.08"]
        ]
    },
    {
        title: "Passing",
        headers: ["Stat", "Wingers", "Centers", "Defense"],
        rows: [
            ["Assists per game", "1.10", "1.10", "1.20"],
            ["Pass %", "0.80", "0.70", "0.65"],
            ["Passes per game", "0.02", "0.02", "0.02"],
            ["Saucer passes per game", "0.90", "0.70", "0.70"],
            ["Giveaways per game", "-0.11", "-0.11", "-0.14"]
        ]
    },
    {
        title: "Handeye",
        headers: ["Stat", "Wingers", "Centers", "Defense"],
        rows: [
            ["Dekes per game", "0.50", "0.50", "2.25"],
            ["Faceoff %", "0.80", "0.60", "1.00"],
            ["Deflections per game", "0.80", "0.80", "2.00"],
            ["Possession per min", "0.08", "0.08", "0.15"],
            ["Offsides per game", "-0.40", "-0.40", "-1.50"]
        ]
    },
    {
        title: "Physicality",
        headers: ["Stat", "Wingers", "Centers", "Defense"],
        rows: [
            ["Hits per game", "0.28", "0.28", "0.30"],
            ["Fights won %", "0.50", "0.50", "0.50"],
            ["PIMs per game", "-0.35", "-0.35", "-0.35"],
            ["Penalties drawn per game", "1.20", "1.20", "1.60"]
        ]
    },
    {
        title: "Defense",
        headers: ["Stat", "Wingers", "Centers", "Defense"],
        rows: [
            ["Interceptions per game", "0.20", "00.18", "0.20"],
            ["Blocked shots per game", "0.35", "0.35", "0.25"],
            ["Takaways per game", "0.20", "0.15", "0.20"],
            ["+/- per game", "0.06", "0.06", "0.06"]
        ]
    },
    {
        title: "Reflexes",
        headers: ["Stat", "Goalies"],
        rows: [
            ["Diving saves per game", "0.30", ],
            ["Poke checks per game", "0.30", ],
            ["Saves per game", "0.15", ],
        ]
    },
    {
        title: "Consistency",
        headers: ["Stat", "Goalies"],
        rows: [
            ["Save %", "0.20", ],
            ["GAA", "-0.15", ],
        ]
    },
    {
        title: "Clutchness",
        headers: ["Stat", "Goalies"],
        rows: [
            ["Shutouts per game", "5.00",],
            ["Shutout periods per game", "1.70", ],
            ["Penalty shot save %", "1.00", ],
        ]
    },
    // Add other sections here following the same structure
];

const StatsTable = ({ section }) => (
    <Paper sx={{ margin: 1, padding: 1 }}>
        <Typography variant="h6" gutterBottom component="div">
            {section.title}
        </Typography>
        <Table size="small">
            <TableHead>
                <TableRow>
                    {section.headers.map((header, index) => (
                        <TableCell key={index}>{header}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {section.rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>
);

const StatsInfo = () => {
    useEffect(() => {
        document.title = `Chel stats | Overall Ratings Info`;
      }, []);
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom sx={{ marginTop: 3, color: 'white' }}>
                Overall Ratings Info
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Overall ratings are generated for your career stats and recent game stats (the average of every player on the clubs roster).
                Each stat is multiplied by it's corresponding weight to calculate a category rating (Shooting, Passing, Handeye, Physicality, Defense) score and modified by the amount of games played per position. The category "score" is then inflated with an exponential growth function to generate a number between 50-99 (The minimum and maximum a rating can be). The overall rating is the average of each category rating.
            </Typography>

            <Typography paragraph sx={{ color: 'white' }}>
                Playstyles are determined by the ranking of overall rating categories and thus which related stats are more prominant in a player. Playstyle rules for highest rated categories:
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Shooting: If the player is a forward they will be determined a "Sniper". If the player is a defenseman they will be labeled as an "Offensive Defenseman", unless their second highest category is physicality or defense, in which case they will be deemed a "Two-way Defenseman".
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Passing: If the player is a forward they will be determined a "Playmaker". If the player is a defenseman they will be labeled as an "Puck-moving Defenseman", unless their second highest category is physicality or defense, in which case they will be deemed a "Two-way Defenseman".
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Handeye: If the player is a forward they will be determined a "Dangler". If the player is a defenseman they will be labeled as an "Offensive Defenseman", unless their second highest category is physicality or defense, in which case they will be deemed a "Two-way Defenseman".
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Physicality: If the player is a forward they will be determined a "Hitting" followed by their second highest rating's playstyle. If the player is a defenseman they will be labeled as a "Defensive defenseman".
            </Typography>
            <Typography paragraph sx={{ color: 'white' }}>
                Defense: If the player is a forward they will be determined a "Hitting" followed by their second highest rating's playstyle. If the player is a defenseman they will be labeled as a "Defensive defenseman".
            </Typography>

            <Typography paragraph sx={{ color: 'white' }}>
                Category ratings are made up of related stats, as described below:
            </Typography>

            {tableData.map((section, index) => (
                <StatsTable key={index} section={section} />
            ))}
        </Container>
    );
};

export default StatsInfo;
