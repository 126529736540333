import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, Card, CardActionArea } from '@mui/material';


function ScoutingTile({ position, console }) {

    return (
        <Card sx={{ borderRadius: 5, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', minWidth: 300, margin: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <CardActionArea>
                <Grid container spacing={1.5} alignItems="center" sx={{ padding: 1.5 }}>
                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                        <Grid item xs={8} display="flex" alignItems="center">
                            <Typography noWrap sx={{color: 'white'}}>{position}</Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                            <Typography noWrap sx={{color: 'white'}}>{console}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}

function ClubScouting({ scoutingInfo }) {
    const scrollRef = useRef(null);
    if (scoutingInfo)
    return (
        <Box ref={scrollRef} sx={{
            display: 'flex',
            overflowX: 'scroll',
            p: 1, '&::-webkit-scrollbar': { display: 'none' }, // For WebKit browsers
            scrollbarWidth: 'none', // For Firefox
            '-ms-overflow-style': 'none'
        }}>

            {scoutingInfo.map((info) => (
                <ScoutingTile key={info._id} position={info.position} console={info.console} />
            ))}
        </Box>
    );
    else {
        return (
            <Typography sx={{color:'white', fontSize: '20px', textAlign: 'center', margin: 'auto'}}>No scouting info found</Typography>
        )
    }
}

export default ClubScouting;