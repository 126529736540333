import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function convertSecondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
  const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60
  const sec = (seconds % 60).toFixed(); // remainder from minutes is seconds

  // Output as "HH:MM:SS" if there are hours, otherwise "MM:SS"
  const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = sec.toString().padStart(2, '0');

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}

function SharedProgressBar({ team1Stats, team2Stats, label }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const total = parseFloat(team1Stats) + parseFloat(team2Stats);
  const team1Percentage = total !== 0 ? (parseFloat(team1Stats) / total) * 100 : 0;

  // Determine if the label is one of the types that require formatting
  const shouldFormatTime = label === "Possession" || label === "Time on Attack";

  // Conditionally format stats based on label
  const team1FormattedStats = shouldFormatTime ? convertSecondsToHMS(team1Stats) : parseFloat(team1Stats).toFixed(0);
  const team2FormattedStats = shouldFormatTime ? convertSecondsToHMS(team2Stats) : parseFloat(team2Stats).toFixed(0);

  return (
    <Box sx={{ width: '100%', marginBottom: windowWidth < 600 ? 2 : windowWidth < 900 ? 3 : 3 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
        <Typography variant="body2" color="white" sx={{ fontSize: '18px' }}>{label}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" sx={{ color: 'white', fontSize: '18px', marginLeft: 2, marginRight: 1 }}>{team1FormattedStats}</Typography>
        <Box sx={{ width: '100%' }}>
          <BorderLinearProgress variant="determinate" value={team1Percentage} />
        </Box>
        <Typography variant="body2" sx={{ color: 'white', fontSize: '18px', marginLeft: 2, marginRight: 2 }}>{team2FormattedStats}</Typography>
      </Box>
    </Box>
  );
}

export default SharedProgressBar;
