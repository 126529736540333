import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, Card, CardActionArea, Link } from '@mui/material';


function ClubInfo({ clubInfo }) {
    const scrollRef = useRef(null);
    if (clubInfo)
    return (
        <Card sx={{ padding: 2, borderRadius: 5, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', minWidth: 300, margin: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <Typography noWrap sx={{color: 'white'}}>Owner: {clubInfo.owner}</Typography>
            <Typography noWrap sx={{color: 'white', marginTop: .5}}>Captain: {clubInfo.info.captain}</Typography>
            <Typography noWrap sx={{color: 'white', marginTop: .5}}>Alternate Captain: {clubInfo.info.alternateCaptain1}</Typography>
            <Typography noWrap sx={{color: 'white', marginTop: .5}}>Alternate Captain: {clubInfo.info.alternateCaptain2}</Typography>
            <Typography noWrap sx={{color: 'white', marginTop: .5}}>Discord server: <Link href={clubInfo.info.discordServer}>{clubInfo.info.discordServer}</Link></Typography>
        </Card>
    );
    else {
        return (
            <Typography sx={{color:'white', fontSize: '20px', textAlign: 'center', margin: 'auto'}}>No club info found</Typography>
        )
    }
}

export default ClubInfo;