import React, { useState } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, TextField, IconButton, List, ListItem, ListItemText } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

function ClubOptions({ onSave, clubId, roster }) {
  const [discordError, setDiscordError] = useState(false);
  const [discordHelperText, setDiscordHelperText] = useState('');
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    discordServer: '',
    captain: '',
    alternateCaptain1: '',
    alternateCaptain2: '',
  });
  const removeCaptainOrAlternate = (field) => {
    setInfo({ ...info, [field]: '' });
  };
  const [scouting, setScouting] = useState([{ position: '', console: '' }]);
  const isValidDiscordLink = (link) => {
    const discordLinkPattern = /^https:\/\/(discord\.gg\/|discordapp\.com\/invite\/)[a-zA-Z0-9]+$/;
    return discordLinkPattern.test(link);
  };
  const handleOpen = async () => {
    setOpen(true);
    try {
      const response = await fetch(`/api/club/options?clubId=${clubId}`);
      if (response.ok) {
        const currentOptions = await response.json();
        setInfo(currentOptions.info || {
          discordServer: '',
          captain: '',
          alternateCaptain1: '',
          alternateCaptain2: '',
        });
        setScouting(currentOptions.scouting || [{ position: '', console: '' }]);
      } else {
        console.error("Failed to fetch club options");
      }
    } catch (error) {
      console.error("Error fetching club options:", error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleSaveOptions = async () => {
    if (info.discordServer && !isValidDiscordLink(info.discordServer)) {
      setDiscordError(true);
      setDiscordHelperText('Please enter a valid Discord server link.');
      return;
    } else {
      setDiscordError(false);
      setDiscordHelperText('');
    }
    const clubOptionsData = { info, scouting };
    try {
      // Replace '/api/club/options' with your actual API endpoint
      const response = await fetch(`/api/club/options?clubId=${clubId}`, {
        method: 'POST', // or 'PUT' if you are updating an existing entry
        headers: {
          'Content-Type': 'application/json',
          // Include authorization header if needed, e.g., 'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify(clubOptionsData),
      });
 
      const responseData = await response.json();
      if (response.ok) {
        console.log('Club options saved successfully:', responseData);
        // Call onSave callback if you want to perform additional actions after saving
        onSave(clubOptionsData);
      } else {
        console.error('Failed to save club options:', responseData);
        // Handle server errors or validation errors
      }
    } catch (error) {
      console.error('Error saving club options:', error);
      // Handle network errors
    }

    handleClose();
  };

  const handleScoutingChange = (index, field, value) => {
    const updatedScouting = [...scouting];
    updatedScouting[index][field] = value;
    setScouting(updatedScouting);
  };

  const addScoutingEntry = () => {
    setScouting([...scouting, { position: '', console: '' }]);
  };

  const removeScoutingEntry = (index) => {
    const updatedScouting = [...scouting];
    updatedScouting.splice(index, 1);
    setScouting(updatedScouting);
  };

  const handleInfoChange = (field, value) => {
    setInfo({ ...info, [field]: value });
  };

  if (roster) {

    return (
      <>
        <Button onClick={handleOpen} sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}>Club Options</Button>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Club Options</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <FormControl fullWidth margin="dense">

                  <InputLabel>Captain</InputLabel>
                  <Select
                    value={info.captain}
                    label="Captain"
                    onChange={(e) => handleInfoChange('captain', e.target.value)}
                  >
                    {roster.map((member) => (
                      <MenuItem value={member}>{member}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton onClick={() => removeCaptainOrAlternate('captain')}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <FormControl fullWidth margin="dense">

                  <InputLabel>Alternate</InputLabel>
                  <Select
                    value={info.alternateCaptain1}
                    label="Alternate"
                    onChange={(e) => handleInfoChange('alternateCaptain1', e.target.value)}
                  >
                    {roster.map((member) => (
                      <MenuItem value={member}>{member}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton onClick={() => removeCaptainOrAlternate('alternateCaptain1')}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <FormControl fullWidth margin="dense">

                  <InputLabel>Alternate</InputLabel>
                  <Select
                    value={info.alternateCaptain2}
                    label="Alternate"
                    onChange={(e) => handleInfoChange('alternateCaptain2', e.target.value)}
                  >
                    {roster.map((member) => (
                      <MenuItem value={member}>{member}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <IconButton onClick={() => removeCaptainOrAlternate('alternateCaptain2')}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <TextField
                  error={discordError}
                  helperText={discordHelperText}
                  label="Discord Server Link"
                  variant="outlined"
                  fullWidth
                  value={info.discordServer}
                  onChange={(e) => handleInfoChange('discordServer', e.target.value)}
                  sx={{ marginTop: 1 }}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={() => removeCaptainOrAlternate('discordServer')}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>

            <List>
              {scouting.map((entry, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => removeScoutingEntry(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      <>
                        <FormControl fullWidth sx={{ mb: 1, mr: 2 }}>
                          <InputLabel>Position</InputLabel>
                          <Select
                            value={entry.position}
                            label="Position"
                            onChange={(e) => handleScoutingChange(index, 'position', e.target.value)}
                          >
                            {/* Populate with positions */}
                            <MenuItem value="Center">Center</MenuItem>
                            <MenuItem value="Left wing">Left wing</MenuItem>
                            <MenuItem value="Right wing">Right wing</MenuItem>
                            <MenuItem value="Left defense">Left defense</MenuItem>
                            <MenuItem value="Right defense">Right defense</MenuItem>
                            <MenuItem value="Goalie">Goalie</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel>Console</InputLabel>
                          <Select
                            value={entry.console}
                            label="Console"
                            onChange={(e) => handleScoutingChange(index, 'console', e.target.value)}
                          >
                            {/* Populate with console options */}
                            <MenuItem value="PS5">PS5</MenuItem>
                            <MenuItem value="Xbox Series X">Xbox Series X</MenuItem>
                            <MenuItem value="PS4">PS4</MenuItem>
                            <MenuItem value="Xbox One">Xbox One</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    }
                  />
                </ListItem>
              ))}
              <ListItem>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={addScoutingEntry}>
                  Add Scouting Entry
                </Button>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveOptions}>Save</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ClubOptions;
