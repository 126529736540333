import React, { useState, useEffect } from 'react';
import { Avatar, Box, Paper, Stack, Typography, Divider, Grid } from '@mui/material';

function PlayerRow({ player, stat, category }) {

    return (
        <Grid container alignItems="center" sx={{ width: '100%', paddingY: 1 }}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar imgProps={{ loading: "lazy" }} src={`https://cdn.discordapp.com/avatars/${player.discordInfo.discordInfo.discordId}/${player.discordInfo.discordInfo.avatar}.png?size=256`} sx={{ width: 30, height: 30 }} />
                <Typography variant="subtitle1" sx={{ flexGrow: 1, color: 'white', marginLeft: 1 }}>
                    {player.Username}
                </Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{category}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ width: 'fit-content', textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );
}

function ClubLeaders({ clubData }) {
    const { topScorers, topPlaymakers, topDefenders } = clubData.topPlayers;

    const StatHeader = ({ stat }) => (
        <Grid container spacing={1} paddingY={1}>
            <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>Player</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>OVR</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>{stat}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Stack spacing={2} sx={{
            width: '100%',
            marginX: 'auto',
            marginTop: 2,
        }}>
            {/* Top Scorers */}
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5, }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Top shooters</Typography>
                <Divider light />
                <StatHeader stat={'Goals'} />
                {topScorers
                    .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].SHT !== undefined)
                    .map(player => (
                        <PlayerRow key={player.Username} player={player} stat={player.Goals} category={player.overallRating["Category Ratings"].SHT} />
                    ))}            </Paper>
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5, }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Top passers</Typography>
                <Divider light />
                <StatHeader stat={'Assists'} />
                {
                    topPlaymakers
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].PSS !== undefined)
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Assists} category={player.overallRating["Category Ratings"].PSS} />
                        ))
                }
            </Paper>
            <Paper sx={{ backgroundColor: '#171717', padding: 2, paddingY: 2, boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', borderRadius: 5, }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Top defenders</Typography>
                <Divider light />
                <StatHeader stat={'Ints'} />
                {
                    topDefenders
                        .filter(player => player.overallRating && player.overallRating["Category Ratings"] && player.overallRating["Category Ratings"].DEF !== undefined)
                        .map(player => (
                            <PlayerRow key={player.Username} player={player} stat={player.Interceptions} category={player.overallRating["Category Ratings"].DEF} />
                        ))
                }
            </Paper>
        </Stack>
    );
}

export default ClubLeaders;
