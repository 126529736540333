import React, { useEffect, useState } from 'react';
import { Box, ToggleButtonGroup, ToggleButton, Paper } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext'; 
import { useLocation, useNavigate } from 'react-router-dom';

const PositionToggle = () => {
  const { state, dispatch } = useStats(); // Use the global state
  const { positionState } = state;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for position in the URL hash and update state if it differs
    const hash = location.hash.replace('#', '');
    if (hash && hash !== positionState && (hash === 'skater' || hash === 'goalie')) {
      dispatch({ type: 'SET_POSITION', payload: hash });
    }
  }, [location.hash]); // Only re-run the effect if the hash changes

  const handlePosition = (event, newPosition) => {
    if (newPosition !== null) { // Prevent deselecting both options
      dispatch({ type: 'SET_POSITION', payload: newPosition });
      navigate(`${location.pathname}${location.search}#${newPosition}`, { replace: true });
    }
  };

  return (
    <Paper elevation={3} sx={{marginTop: '8px', bgcolor: '#1f1f1f', position: 'relative', borderRadius: '20px', overflow: 'hidden', height: '30px', width: '140px' }}>
      <Box
        sx={{
          position: 'absolute',
          width: '50%',
          height: '100%',
          bgcolor: '#1976d2',
          transform: positionState === 'skater' ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.3s ease-in-out',
          borderRadius: '20px',
          textAlign: 'center'
        }}
      />
      <ToggleButtonGroup
        value={positionState}
        exclusive
        onChange={handlePosition}
        sx={{
          '& .MuiToggleButtonGroup-grouped': {
            border: 0,
            borderRadius: '20px',
            width: '70px',
            height: '30px'
          },
        }}
      >
        <ToggleButton value="skater" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
          Skater
        </ToggleButton>
        <ToggleButton value="goalie" sx={{ color: 'white', zIndex: 1, bgcolor: 'transparent', fontSize: '12px' }}>
          Goalie
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );
};

export default PositionToggle;
