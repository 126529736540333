import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import Autocomplete from '@mui/lab/Autocomplete';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, TextField } from '@mui/material';

function GoalieLeaderboardSixes({league}) {

    useEffect(() => {
        document.title = `Chel stats | ${league.toLocaleUpperCase()} Goalie Leaderboard`;
    }, []);

    const [sheetData, setSheetData] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'Wins',
        direction: 'desc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterUsername, setFilterUsername] = useState('');
    const [usernameFilter, setUsernameFilter] = useState(null);
    const usernameOptions = useMemo(() => {
        const usernames = new Set(sheetData.slice(1).map(row => {
            const usernameIndex = sheetData[0].indexOf('USERNAME');
            return row[usernameIndex];
        }));
        return Array.from(usernames);
    }, [sheetData]);
    const visibleColumns = [
        { key: 'USERNAME', sortable: true },
        { key: 'Team Name', sortable: true },
        { key: 'Platform', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Wins', sortable: true },
        { key: 'Losses', sortable: true },
        { key: 'Shutouts', sortable: true },
        { key: 'Goals Against', sortable: true },
        { key: 'Saves', sortable: true },
        { key: 'Shots against', sortable: true },
        { key: 'GAA', sortable: true },
        { key: 'Save %', sortable: true },
        { key: 'Bways Faced', sortable: true },
        { key: 'Bway save %', sortable: true },
        { key: 'Pen Shots Faced', sortable: true },
        { key: 'Pen shot save %', sortable: true },
        { key: 'Diving saves', sortable: true },
        { key: 'EV TOI', sortable: true },
        { key: 'EV SA', sortable: true },
        { key: 'Sv% at EV', sortable: true },
        { key: 'FA EV', sortable: true },
        { key: 'FA SH', sortable: true },
        { key: 'EVD', sortable: true },
        { key: 'SHD', sortable: true },
        { key: 'PTA', sortable: true },
        { key: 'PTD', sortable: true },
        { key: 'GSAA', sortable: true },
        { key: 'GWAR', sortable: true }
    ];
    

    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/leaderboard?league=${league}&type=goalie-playoffs`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                data = data.values.slice(2);
                setSheetData(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        fetchSheetData();
    }, [setLoading]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const filteredData = useMemo(() => {
        if (!usernameFilter) return sheetData.slice(1); // Assumes first row is headers
        return sheetData.filter(row => {
            const columnIndex = sheetData[0].indexOf('USERNAME');
            if (columnIndex === -1) return true; // If USERNAME column doesn't exist, return all rows
            return row[columnIndex] === usernameFilter;
        });
    }, [sheetData, usernameFilter]);

    const sortedData = useMemo(() => {
        // Make sure to use filteredData here
        if (filteredData.length > 0 && filteredData[0].length > sheetData[0].indexOf(sortConfig.key)) {
            const columnIndex = sheetData[0].indexOf(sortConfig.key);
            if (columnIndex > -1) {
                return filteredData.sort((a, b) => {
                    if (sortConfig.key === 'Individual Record') {
                        const extractWins = (record) => {
                            const match = record.match(/^\((\d*)/);
                            return match ? parseInt(match[1], 10) : 0;
                        };
    
                        const aValue = extractWins(a[columnIndex]);
                        const bValue = extractWins(b[columnIndex]);
    
                        return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
                    } else {
                        const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                        const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];
    
                        if (aValue < bValue) {
                            return sortConfig.direction === 'asc' ? -1 : 1;
                        }
                        if (aValue > bValue) {
                            return sortConfig.direction === 'asc' ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }
        }
        return filteredData; // Return filteredData directly if not sorting
    }, [filteredData, sortConfig]);


    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    return (
        <Box sx={{ maxWidth: '95%', m: 'auto', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ m: 'auto', marginTop: 4, color: 'white' }}>{league} Goalie Leaderboard - Playoffs</Typography>
            <Autocomplete
                options={usernameOptions}
                getOptionLabel={(option) => option ? option.toString() : ''}
                value={usernameFilter}
                onChange={(event, newValue) => {
                    setUsernameFilter(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Filter by Username" variant="outlined" />}
                sx={{ marginTop: 2, marginBottom: 0.5, width: 300,input: { color: 'white' }, // Text color
                label: { color: 'gray' }, // Label color when not focused
                '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'gray' }, // Border color
                    '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                    '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
                },
                '& .MuiInputLabel-root.Mui-focused': { color: 'white' } }}
                freeSolo // Allows arbitrary input not limited to the options
                componentsProps={{
                    clearIndicator: {
                      style: {
                        color: 'gray', // Change to your desired color
                      },
                    },
                  }}
            />
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 5 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1C4587' }}>
                            {visibleColumns.map((column, columnIndex) => {
                                const { key, sortable } = column;
                                return (
                                    <TableCell
                                        key={key}
                                        sx={{
                                            backgroundColor: '#1C4587',
                                            color: 'white',
                                            p: '2px',
                                            paddingLeft: '6px',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRight: columnIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                            position: columnIndex === 0 ? 'sticky' : undefined,
                                            left: columnIndex === 0 ? 0 : undefined,
                                            zIndex: columnIndex === 0 ? 2 : 1,
                                        }}
                                    >
                                        {sortable ? (
                                            <TableSortLabel
                                                active={sortConfig.key === key}
                                                direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                                                onClick={() => handleSort(key)}
                                            >
                                                {key.toLocaleUpperCase()}
                                            </TableSortLabel>
                                        ) : key.toLocaleUpperCase()}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {visibleColumns.map(({ key }, colIndex) => {
                                    const cellValue = row[sheetData[0].indexOf(key)] || '0';;
                                    return <TableCell
                                        key={colIndex}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            width: '50px',
                                            fontWeight: '500',
                                            borderRight: colIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                            position: colIndex === 0 ? 'sticky' : undefined,
                                            left: colIndex === 0 ? 0 : undefined,
                                            backgroundColor: colIndex === 0 ? 'white' : undefined, // Use your table's background color
                                            zIndex: 1,
                                        }}>{cellValue}</TableCell>;
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    );
}

export default GoalieLeaderboardSixes;
