import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useUser } from '../Contexts/UserContext';
import { refreshUserData } from '../EASHL/Players/PlayerStats';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FaDiscord } from 'react-icons/fa';
import SavedSearches from './SavedSearches';
import AdComponent from './AdComponent';

function HomePage() {
  const { user, setUser } = useUser();
  useEffect(() => {
    refreshUserData(setUser);
  }, [setUser]);
  const navigate = useNavigate();
  const handlePlayerClick = (username, teamname, consolegen) => {
    navigate(`/players?username=${username}&teamname=${teamname}&console=${consolegen}`);
  };
  useEffect(() => {
    document.title = `Chel stats | Home`;
  }, []);
  const handleClubClick = (teamname, consolegen) => {

    let targetUrl = `/clubs?teamname=${teamname}&console=${consolegen}`;

    navigate(targetUrl);

  };

  const handleExploreAboutPage = () => {
    navigate(`/about`);
  };

  let username = '';
  let teamname = ''
  let consolegen = ''
  let profile = ''
  let profilePicURL = ''
  let userId = ''
  let userImage = ''
  let welcomeMsg
  let resume
  if (user != null && user.claimedProfile.username && user.claimedClub.clubId) {
    username = user.claimedProfile.username;
    teamname = user.claimedProfile.teamname;
    consolegen = user.claimedProfile.consolegen;
    userImage = user.avatar;
    profile = user.username
    userId = user.userId
    profilePicURL = `https://cdn.discordapp.com/avatars/${userId}/${userImage}.png?size=256`

    welcomeMsg = (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>Welcome back, {username}!</Typography>
      </Box>
    )

    resume = (
      <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom sx={{ marginBottom: 2 }}>
          Claimed profile
        </Typography>
        <Typography variant="h7" color="white" fontWeight="800">
          Player stats
        </Typography>
        <Button endIcon={<ChevronRightIcon />} variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginBottom: 2, marginTop: 1 }} onClick={() => handlePlayerClick(username, teamname, consolegen)}>
          <Typography sx={{ color: 'white', fontWeight: "800" }}>{username}</Typography>
          <Typography sx={{ color: 'white', fontWeight: "800" }}>({teamname})</Typography>
        </Button>
        <Typography variant="h7" color="white" fontWeight="800">
          Club stats
        </Typography>
        <Button endIcon={<ChevronRightIcon />} variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleClubClick(teamname, consolegen)}>
          <Typography sx={{ color: 'white', fontWeight: "800" }}>{teamname}</Typography>
        </Button>
      </Box>
    )
  } else if (user != null && user.claimedProfile.username) {
    username = user.claimedProfile.username;
    teamname = user.claimedProfile.teamname;
    consolegen = user.claimedProfile.consolegen;
    userImage = user.avatar;
    profile = user.username
    userId = user.userId
    profilePicURL = `https://cdn.discordapp.com/avatars/${userId}/${userImage}.png?size=256`

    welcomeMsg = (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>Welcome back, {username}!</Typography>
      </Box>
    )

    resume = (
      <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom sx={{ marginBottom: 2 }}>
          Claimed profile
        </Typography>
        <Typography variant="h7" color="white" fontWeight="800">
          Player stats
        </Typography>
        <Button endIcon={<ChevronRightIcon />} variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginBottom: 2, marginTop: 1 }} onClick={() => handlePlayerClick(username, teamname, consolegen)}>
          <Typography sx={{ color: 'white', fontWeight: "800" }}>{username}</Typography>
          <Typography sx={{ color: 'white', fontWeight: "800" }}>({teamname})</Typography>
        </Button>
      </Box>
    )
  } else {
    welcomeMsg = (
      <><Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>Welcome to Chel stats!</Typography>
      </Box><Typography variant="body1" color="white" paragraph>Chel Stats is the #1 place for all things EASHL. Please enjoy this advanced platform designed by and for the community, aiming to bring together community resources into one easy-to-access location. Our goal is clear: to be the definitive resource for every EASHL player and fan.</Typography></>

    )
    resume = (
      <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Claimed profile
        </Typography>
        <Typography variant="h7" color="white" fontWeight="300" sx={{ marginBottom: 4 }}>
          Login with discord to save profiles!
        </Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1, width: 'fit-content' }} href="https://discord.com/oauth2/authorize?client_id=1201905788386283570&response_type=code&redirect_uri=https%3A%2F%2Fwww.chelstats.app%2Fauth%2Fdiscord%2Fcallback&scope=identify+guilds">Login</Button>
      </Box>
    )
  }


  const logoPath = require(`./Logos/Chelstats.png`);


  return (
    <Container>
      <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '40px' }}> {/* my={4} adds margin to the top and bottom */}
        {welcomeMsg}

      </Box>

      {resume}
      <SavedSearches></SavedSearches>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Join the Chel stats discord server!
        </Typography>
        <Typography variant="h7" color="white" fontWeight="300" sx={{ marginBottom: 4 }}>
          Including: LFG channels, tournaments, and the Chel stats discord bot for viewing stats in discord!
        </Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1, width: 'fit-content' }} href="https://discord.gg/GHx7Zvwk2P"><FaDiscord style={{ marginRight: '4px', width: '25px', height: '25px' }} />Discord</Button>

      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px', padding: 2 }}>
        <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
          Explore Features
        </Typography>
        <Typography variant="h7" color="white" fontWeight="300" sx={{ marginBottom: 4 }}>
          Learn all about what Chel stats has to offer!
        </Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleExploreAboutPage()}>About page</Button>

      </Box>


    </Container >
  );
}



export default HomePage;