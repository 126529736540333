import React, { useEffect, useState, useMemo, useRef  } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material';
import LeagueTeamBanner from './LeagueTeamBanner'
import { useNavigate, useLocation  } from 'react-router-dom';
function LeaguesTeamPageSixes({ team, league, lowercaseteam, color }) {

    useEffect(() => {
        document.title = `Chel stats | ${team.toLocaleUpperCase()}`;
    }, []);

    const [skaterScrollPos, setSkaterScrollPos] = useState({ scrollLeft: 0, scrollTop: 0 });
    const [goalieScrollPos, setGoalieScrollPos] = useState({ scrollLeft: 0, scrollTop: 0 });

    const { loading, setLoading } = useLoading();
    const [skaterHeaders, setSkaterHeaders] = useState([]);
    const [goalieHeaders, setGoalieHeaders] = useState([]);
    const [scrollPosition, setScrollPosition] = useState({ scrollLeft: 0, scrollTop: 0 });
    const [sortConfigSkaters, setSortConfigSkaters] = useState({
        key: 'Points',
        direction: 'desc',
    });

    const [sortConfigGoalies, setSortConfigGoalies] = useState({
        key: 'Wins',
        direction: 'desc',
    });
    const [skaterData, setSkaterData] = useState([]);
    const [goalieData, setGoalieData] = useState([]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const visibleColumns = [
        { key: 'Username', sortable: true },
        { key: 'Platform', sortable: true },
        { key: 'Position', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Offense rating', sortable: true },
        { key: 'Defense rating', sortable: true },
        { key: 'Teamplay rating', sortable: true },
        { key: 'Goals', sortable: true },
        { key: 'Powerplay goals', sortable: true },
        { key: 'Shorthanded goals', sortable: true },
        { key: 'Team PPOs', sortable: true },
        { key: 'Assists', sortable: true },
        { key: 'Points', sortable: true },
        { key: '(+/-)', sortable: true },
        { key: 'Shots', sortable: true },
        { key: 'Shot attempts', sortable: true },
        { key: 'Shot %', sortable: true },
        { key: 'Shots on net %', sortable: true },
        { key: 'Deflections', sortable: true },
        { key: 'Passes', sortable: true },
        { key: 'Saucer passes', sortable: true },
        { key: 'Pass attempts', sortable: true },
        { key: 'Pass %', sortable: true },
        { key: 'Giveaways', sortable: true },
        { key: 'Hits', sortable: true },
        { key: 'Interceptions', sortable: true },
        { key: 'Takeaways', sortable: true },
        { key: 'Blocked shots', sortable: true },
        { key: 'PK zone clears', sortable: true },
        { key: 'Possession (Min Per Game)', sortable: true },
        { key: 'Faceoff wins', sortable: true },
        { key: 'Faceoff losses', sortable: true },
        { key: 'Faceoff %', sortable: true },
        { key: 'PIM', sortable: true },
        { key: 'Penalties drawn', sortable: true },
        { key: 'TOI (mins)', sortable: true },
        { key: 'SH TOI', sortable: true },
        { key: 'PP TOI', sortable: true },
        { key: 'EV TOI', sortable: true },
        { key: 'Shot Attempts', sortable: true },
        { key: 'Shots On Net', sortable: true },
        { key: 'Missed Shots', sortable: true },
        { key: 'Skater EV TOI', sortable: true },
        { key: 'EV Shots For (CF)', sortable: true },
        { key: 'EV Shots Against (CA)', sortable: true },
        { key: 'CF Rel', sortable: true },
        { key: 'Sht% at EV', sortable: true },
        { key: 'WAR', sortable: true },
        { key: 'EVO', sortable: true },
        { key: 'EVD', sortable: true },
        { key: 'PPO', sortable: true },
        { key: 'SHD', sortable: true },
        { key: 'PTA', sortable: true },
        { key: 'PDA', sortable: true },
        { key: 'TO', sortable: true },
        { key: 'TD', sortable: true },
        { key: 'TPG', sortable: true },
        { key: 'GAR', sortable: true },
        // Include any additional stats here
    ];

    const visibleGoalieColumns = [
        { key: 'GOALIES', sortable: true },
        { key: 'Platform', sortable: true },
        { key: 'Position', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Wins', sortable: true },
        { key: 'Losses', sortable: true },
        { key: 'Shutouts', sortable: true },
        { key: 'GAA', sortable: true },
        { key: 'Saves', sortable: true },
        { key: 'Shots against', sortable: true },
        { key: 'Save %', sortable: true },
        { key: 'Breakaway saves', sortable: true },
        { key: 'Breakaway save %', sortable: true },
        { key: 'Penalty shot saves', sortable: true },
        { key: 'Penalty shot save %', sortable: true },
        { key: 'Diving saves', sortable: true },
        { key: 'Pokechecks', sortable: true },
        { key: 'PK zone clears', sortable: true },
        { key: 'EV TOI', sortable: true },
        { key: 'EV SA', sortable: true },
        { key: 'Sv% at EV', sortable: true },
        { key: 'FA EV', sortable: true },
        { key: 'FA SH', sortable: true },
        { key: 'EVD', sortable: true },
        { key: 'SHD', sortable: true },
        { key: 'PTA', sortable: true },
        { key: 'PTD', sortable: true },
        { key: 'GSAA', sortable: true },
        { key: 'GWAR', sortable: true }
    ];

    useEffect(() => {
        setLoading(true);

        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/teampage?league=${league}&team=${team}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const rawData = await response.json();
                processData(rawData.values);

                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        const processData = (rawData) => {
            const headersIndex = rawData.findIndex(row => row.includes('Username'));
            const goaliesIndex = rawData.findIndex(row => row.includes('GOALIES'));
            const rawHeaders = rawData[headersIndex];
            const goalieHeaders = rawData[goaliesIndex];

            let skaterRows = rawData.slice(headersIndex + 1, goaliesIndex);
            let goalieRows = rawData.slice(goaliesIndex + 1);

            const visibleColumnKeys = visibleColumns.map(column => column.key);
            const filteredHeaders = rawHeaders.filter(header => visibleColumnKeys.includes(header));

            setSkaterHeaders(filteredHeaders);

            const filteredDataRows = rawData.slice(headersIndex + 1).map(row =>
                row.filter((_, index) => filteredHeaders.includes(rawHeaders[index]))
            );

            for (let i = filteredDataRows.length - 1; i >= 0; i--) {
                if (filteredDataRows[i].length <= 2) {
                    filteredDataRows.splice(i, 1);
                }
            }

            const visibleGoalieColumnKeys = visibleGoalieColumns.map(column => column.key);
            const filteredGoalieHeaders = goalieHeaders.filter(header => visibleGoalieColumnKeys.includes(header));

            setGoalieHeaders(filteredGoalieHeaders);

            const filteredGoalieDataRows = rawData.slice(goaliesIndex + 1).map(row =>
                row.filter((_, index) => filteredGoalieHeaders.includes(rawHeaders[index]))
            );

            for (let i = filteredGoalieDataRows.length - 1; i >= 0; i--) {
                if (filteredGoalieDataRows[i].length <= 2) {
                    filteredGoalieDataRows.splice(i, 1);
                }
            }

            setSkaterHeaders(rawHeaders.filter(header => visibleColumns.map(column => column.key).includes(header)));
            setGoalieHeaders(goalieHeaders.filter(header => visibleGoalieColumns.map(column => column.key).includes(header)));

            const removeEmptyColumns = (dataset, headers) => {
                // Identify indexes of empty headers
                const emptyIndexes = headers.reduce((acc, value, index) => (value === "" ? [...acc, index] : acc), []);
            
                // Filter out rows where the first element is empty
                const filteredDataset = dataset.filter(row => row[0] !== "");
            
                // Remove columns with empty headers
                return filteredDataset.map(row => row.filter((_, index) => !emptyIndexes.includes(index)));
            };

            skaterRows = removeEmptyColumns(skaterRows, rawHeaders);
            goalieRows = removeEmptyColumns(goalieRows, goalieHeaders);

            setSkaterData(skaterRows.filter(row => row.length > 6));
            setGoalieData(goalieRows.filter(row => row.length > 6));
        };

        fetchSheetData();
    }, [team, setLoading]);

    const sortData = (data, headers, sortConfig) => {
        return data.sort((a, b) => {
            const indexA = headers.indexOf(sortConfig.key);
            const indexB = headers.indexOf(sortConfig.key);

            if (indexA === -1 || indexB === -1) return 0; // Key not found

            let valueA = a[indexA];
            let valueB = b[indexB];

            // Convert to numbers if numeric values for proper comparison
            valueA = isNaN(+valueA) ? valueA : +valueA;
            valueB = isNaN(+valueB) ? valueB : +valueB;

            return (valueA < valueB ? -1 : 1) * (sortConfig.direction === 'asc' ? 1 : -1);
        });
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><PropagateLoader color="#3498db" /></div>;
    }

    const handleRequestSortSkaters = (property) => {
        const table = document.getElementById('skaterTable');
        if (table) {
            setSkaterScrollPos({
                scrollLeft: table.scrollLeft,
                scrollTop: table.scrollTop,
            });
        }
        const isAsc = sortConfigSkaters.key === property && sortConfigSkaters.direction === 'asc';
        setSortConfigSkaters({ key: property, direction: isAsc ? 'desc' : 'asc' });
    };

    const handleRequestSortGoalies = (property) => {
        const table = document.getElementById('goalieTable');
        if (table) {
            setGoalieScrollPos({
                scrollLeft: table.scrollLeft,
                scrollTop: table.scrollTop,
            });
        }
        const isAsc = sortConfigGoalies.key === property && sortConfigGoalies.direction === 'asc';
        setSortConfigGoalies({ key: property, direction: isAsc ? 'desc' : 'asc' });
    };

    const RenderTable = ({ data, headers, sortConfig, onSortChange, id, handleSort }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const sortedData = useMemo(() => sortData(data, headers, sortConfig), [data, headers, sortConfig]);
    
        useEffect(() => {
            const skaterTable = document.getElementById('skaterTable');
            if (skaterTable) {
                skaterTable.scrollLeft = skaterScrollPos.scrollLeft;
                skaterTable.scrollTop = skaterScrollPos.scrollTop;
            }
        }, [skaterScrollPos, skaterData]); // Also depend on skaterData to ensure updates after data changes
    
        useEffect(() => {
            const goalieTable = document.getElementById('goalieTable');
            if (goalieTable) {
                goalieTable.scrollLeft = goalieScrollPos.scrollLeft;
                goalieTable.scrollTop = goalieScrollPos.scrollTop;
            }
        }, [goalieScrollPos, goalieData]); // Also depend on goalieData to ensure updates after data changes
    
        const handleRowClick = (username) => {
            navigate(`${location.pathname}/${username}`);
        };
    
        return (
            <Box sx={{ maxWidth: '98%', m: 'auto', alignItems: 'center', overflowX: 'auto' }}>
                <TableContainer component={Paper} sx={{ marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 3 : 3, marginBottom: 5, zIndex: 'tooltip' }} id={`${id}`}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#1C4587' }}>
                                {headers.map((header, index) => (
                                    <TableCell
                                        key={header}
                                        sx={{
                                            color: 'white',
                                            top: 0,
                                            p: '4px',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            position: index === 0 ? 'sticky' : undefined,
                                            left: index === 0 ? 0 : undefined,
                                            backgroundColor: '#1C4587', // Consistent blue color for all headers
                                            zIndex: index === 0 ? 2 : 1,
                                        }}
                                    >
                                        <TableSortLabel
                                            active={sortConfig.key === header}
                                            direction={sortConfig.key === header ? sortConfig.direction : 'asc'}
                                            onClick={() => handleSort(header)}
                                        >
                                            {header}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((row, rowIndex) => (
                                //onClick={() => handleRowClick(row[0])}
                                <TableRow hover key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <TableCell
                                            key={cellIndex}
                                            sx={{
                                                padding: '8px',
                                                fontWeight: '500',
                                                borderRight: cellIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                                position: cellIndex === 0 ? 'sticky' : undefined,
                                                left: cellIndex === 0 ? 0 : undefined,
                                                backgroundColor: cellIndex === 0 ? 'white' : undefined, // Use your table's background color
                                                zIndex: 1,
                                                // cursor: 'pointer'
                                            }}
                                        >
                                            {cell === "" ? '0' : cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    return (
        <Box>
            {loading ? (
                <Box display="flex" justifyContent="center">
                    <PropagateLoader />
                </Box>
            ) : (
                <>
                    <Box sx={{ maxWidth: '100%', m: 'auto', alignItems: 'center' }}>
                        {/* <LeagueTeamBanner team={lowercaseteam} league={league} text={team.toLocaleUpperCase()} color={color}/> */}
                        <Typography variant="h6" sx={{ marginLeft: windowWidth < 600 ? 2 : windowWidth < 900 ? 2 : 2, marginTop: 4, color: 'white' }}>SKATERS</Typography>
                        <RenderTable data={skaterData} headers={skaterHeaders} sortConfig={sortConfigSkaters} onSortChange={setSortConfigSkaters} id={'skaterTable'} handleSort={handleRequestSortSkaters}/>
                        <Typography variant="h6" sx={{ marginLeft: windowWidth < 600 ? 2 : windowWidth < 900 ? 2 : 2, marginTop: 4, color: 'white' }}>GOALIES</Typography>
                        <RenderTable data={goalieData} headers={goalieHeaders} sortConfig={sortConfigGoalies} onSortChange={setSortConfigGoalies} id={'goalieTable'} handleSort={handleRequestSortGoalies}/>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default LeaguesTeamPageSixes;