import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const logoPath = require(`./Logos/image.png`);

  if (windowWidth < 600) {
    return (
      <Box component="footer" sx={{ bgcolor: '#1f1f1f', color: 'white', py: 2, marginTop: 2 }}>
        <Container sx={{ px: 2 }}>
          <Box display="flex" alignItems="center" sx={{ maxWidth: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
              <img src={logoPath} alt="Logo" style={{ marginRight: 8, height: '1.5rem' }} />
              <Typography variant="body1">Chel stats</Typography>
            </Box>
            <Button variant="contained" color="primary" href="https://www.buymeacoffee.com/chelstats" sx={{ mr: 1 }}>
              🍺 Buy Me a Beer
            </Button>
          </Box>
          <Typography variant="body2" align="center" sx={{ marginTop: 3 }}>
            This site is not affiliated with or endorsed by EA Sports
          </Typography>
        </Container>
      </Box>
    );
  } else {
    return (
      <Box sx={{ bgcolor: '#1f1f1f', color: 'white', marginTop: 3 }}>
        <Box display="flex" alignItems="center" sx={{ width: '100%', gap: 33, justifyContent: 'center', paddingY: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
            <img src={logoPath} alt="Logo" style={{ marginRight: 8, height: '1.5rem' }} />
            <Typography variant="body1">Chel stats</Typography>
          </Box>
          <Typography variant="body2" align="center">
            This site is not affiliated with or endorsed by EA Sports
          </Typography>
          <Button variant="contained" color="primary" href="https://www.buymeacoffee.com/chelstats" sx={{ mr: 1 }}>
            🍺 Buy Me a Beer
          </Button>
        </Box>
      </Box>
    );
  }
};

export default Footer;
