import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, MenuItem, Select, InputLabel, FormControl, InputAdornment, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const SearchAndDropdownClubs = ({ onConsolegenChange }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [teamname, setTeamName] = useState('');
    const [consolegen, setConsolegen] = useState('common-gen5');
    const [refreshKey, setRefreshKey] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setTeamName(queryParams.get('teamname') || '');
        setConsolegen(queryParams.get('console') || 'common-gen5');
    }, [location.search]);

    const handleTeamNameChange = (event) => setTeamName(event.target.value);

    const handleConsoleChange = (event) => {
        const newConsolegen = event.target.value;
        setConsolegen(newConsolegen);
        onConsolegenChange(newConsolegen);
    };

    const handleButtonClick = () => {
        let targetUrl = `/clubs?teamname=${teamname.trim()}&console=${consolegen}`;
      
        // Build the full URL with the current domain
        const fullUrl = window.location.origin + targetUrl;
      
        // Check if the current URL is the same as the target URL
        if (window.location.href === fullUrl) {
          // If already at the location, refresh the page to force data reload
          window.location.reload();
        } else {
          // Navigate to the new URL and replace the current history entry
          window.location.replace(fullUrl); // This changes the URL and reloads the page
        }
      };

    return (
        <Box sx={{ display: 'flex', flexWrap: windowWidth < 600 ? 'wrap' : windowWidth < 900 ? 'nowrap' : 'nowrap', gap: 1, alignItems: 'center', margin: 'auto', marginTop: 3 }}>
            <TextField
                label="Club name"
                variant="outlined"
                value={teamname}
                onChange={handleTeamNameChange}
                onInput={handleTeamNameChange} // To handle autofill
                sx={{
                    width: '48.85%',
                    input: {
                      color: 'white', // Text color
                      '&:-webkit-autofill': {
                        WebkitTextFillColor: 'white !important',
                        transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
                        WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                      }
                    },
                    label: { color: 'gray' }, // Label color when not focused
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'gray' }, // Border color
                      '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                      '&.Mui-focused fieldset': { borderColor: 'white' }, // Border color when focused
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'white' } // Label color when focused
                  }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setTeamName('')} sx={{ color: 'white' }}>
                                {teamname ? <ClearIcon /> : null}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <FormControl fullWidth sx={{ width: '48%' }}>
                <InputLabel id="consolegen-select-label" sx={{ color: 'gray', '&.Mui-focused': { color: 'white' } }}>Console</InputLabel>
                <Select
                    labelId="consolegen-select-label"
                    id="consolegen"
                    value={consolegen}
                    label="Console"
                    onChange={handleConsoleChange}
                    sx={{
                        color: 'white',
                        '& .MuiSelect-icon': { color: 'white' },
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' },
                    }}
                >
                    <MenuItem value={'common-gen5'}>Current gen (PS5/Xbox series X)</MenuItem>
                    <MenuItem value={'common-gen4'}>Old gen (PS4/Xbox one)</MenuItem>
                </Select>
            </FormControl>
            <Button sx={{ width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '20%' : '20%', height: windowWidth < 600 ? 'unset' : windowWidth < 900 ? '50px' : '50px' }} variant="contained" color="primary" onClick={handleButtonClick}>
                Search
            </Button>
        </Box>
    );
}

export default SearchAndDropdownClubs;
