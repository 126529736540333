import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function WOHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        document.title = "Chel stats | CNC Home";
    }, []);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/CNC_Logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={logoPath} alt={`WOHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to CNC!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for CNC!
                </Typography>
                <Button variant="contained" color="primary" href="/CNC/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/CNC/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/CNC/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'anaheim-ducks'} text={'Anaheim Ducks'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'arizona-coyotes'} text={'Arizona Coyotes'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'boston-bruins'} text={'Boston Bruins'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'buffalo-sabres'} text={'Buffalo Sabres'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'calgary-flames'} text={'Calgary Flames'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'carolina-hurricanes'} text={'Carolina Hurricanes'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'chicago-blackhawks'} text={'Chicago Blackhawks'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'colorado-avalanche'} text={'Colorado Avalanche'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'columbus-blue-jackets'} text={'Columbus Blue Jackets'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'dallas-stars'} text={'Dallas Stars'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'detroit-red-wings'} text={'Detroit Red Wings'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'edmonton-oilers'} text={'Edmonton Oilers'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'florida-panthers'} text={'Florida Panthers'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'los-angeles-kings'} text={'Los Angeles Kings'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'minnesota-wild'} text={'Minnesota Wild'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'montreal-canadiens'} text={'Montreal Canadiens'} league={'CNC'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>    
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'nashville-predators'} text={'Nashville Predators'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'new-jersey-devils'} text={'New Jersey Devils'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'new-york-islanders'} text={'New York Islanders'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'new-york-rangers'} text={'New York Rangers'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'ottawa-senators'} text={'Ottawa Senators'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'philadelphia-flyers'} text={'Philadelphia Flyers'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'pittsburgh-penguins'} text={'Pittsburgh Penguins'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'san-jose-sharks'} text={'San Jose Sharks'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'seattle-kraken'} text={'Seattle Kraken'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'stlouis-blues'} text={'St Louis Blues'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'tampa-bay-lightning'} text={'Tampa Bay Lightning'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'toronto-maple-leafs'} text={'Toronto Maple Leafs'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'vancouver-canucks'} text={'Vancouver Canucks'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'vegas-golden-knights'} text={'Vegas Golden Knights'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'washington-capitals'} text={'Washington Capitals'} league={'CNC'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'winnipeg-jets'} text={'Winnipeg Jets'} league={'CNC'} />
                    </Container>
                </Container>
            </Box>

            {/* Repeat for more sections as needed */}
        </Container>
    );
}



export default WOHL_Home;