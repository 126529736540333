import React, { useEffect, useState } from 'react';
import { Box, Button, } from '@mui/material';
import { TextField, MenuItem, Select, InputLabel, FormControl, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const SearchAndDropdown = ({ username, setUsername, teamname, setTeamName, refreshKey, setRefreshKey }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [consolegen, setConsolegen] = React.useState('common-gen5');
  const handleUsernameChange = (event) => setUsername(event.target.value);
  const handleTeamNameChange = (event) => setTeamName(event.target.value);
  const handleConsoleChange = (event) => setConsolegen(event.target.value);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setUsername(queryParams.get('username') || '');
    setTeamName(queryParams.get('teamname') || '');
    setConsolegen(queryParams.get('console') || consolegen);
  }, [location.search, setUsername, setTeamName, setConsolegen, refreshKey]);

  const handleButtonClick = () => {
    let targetUrl = `/players?username=${username.trim()}&console=${consolegen}`;
    if (teamname) {
      targetUrl += `&teamname=${teamname.trim()}`;
    } else {
      targetUrl += `&freeAgent=True`;
    }

    // Build the full URL with the current domain
    const fullUrl = window.location.origin + targetUrl;

    // Check if the current URL is the same as the target URL
    if (window.location.href === fullUrl) {
      // If already at the location, refresh the page to force data reload
      window.location.reload();
    } else {
      // Navigate to the new URL and replace the current history entry
      window.location.replace(fullUrl); // This changes the URL and reloads the page
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Box sx={{ display: 'flex', flexWrap: windowWidth < 600 ? 'wrap' : windowWidth < 900 ? 'nowrap' : 'nowrap', gap: 1, alignItems: 'center', margin: 'auto', marginTop: 3 }}>
      <TextField
        label="Gamertag"
        variant="outlined"
        value={username}
        onInput={handleUsernameChange}
        onBlur={handleUsernameChange}
        sx={{
          width: '48.85%',
          input: {
            color: 'white', // Text color
            '&:-webkit-autofill': {
              WebkitTextFillColor: 'white !important',
              transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
              WebkitBoxShadow: '0 0 0px 1000px transparent inset',
            }
          },
          label: { color: 'gray' }, // Label color when not focused
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'gray' }, // Border color
            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
            '&.Mui-focused fieldset': { borderColor: 'white' }, // Border color when focused
          },
          '& .MuiInputLabel-root.Mui-focused': { color: 'white' } // Label color when focused
        }}
        
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setUsername('')}
              edge="end"
              sx={{ color: 'white' }}
            >
              {username ? <ClearIcon /> : null}
            </IconButton>
          </InputAdornment>
        ),
      }}
      />

      <TextField
        label="Club name (optional)"
        variant="outlined"
        value={teamname}
        onInput={handleTeamNameChange}
        onBlur={handleTeamNameChange}
        sx={{
          width: '48.85%',
          input: {
            color: 'white', // Text color
            '&:-webkit-autofill': {
              WebkitTextFillColor: 'white !important',
              transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
              WebkitBoxShadow: '0 0 0px 1000px transparent inset',
            }
          },
          label: { color: 'gray' }, // Label color when not focused
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'gray' }, // Border color
            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
            '&.Mui-focused fieldset': { borderColor: 'white' }, // Border color when focused
          },
          '& .MuiInputLabel-root.Mui-focused': { color: 'white' } // Label color when focused
        }}
        
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setTeamName('')}
                edge="end"
                sx={{ color: 'white' }}
              >
                {username ? <ClearIcon /> : null}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl fullWidth sx={{ width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '50%' : '50%', color: 'white', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'gray' } } }}>
        <InputLabel id="demo-simple-select-label" sx={{ color: 'gray', '&.Mui-focused': { color: 'white' } }}>Console</InputLabel>
        <Select
          labelId="consolegen-select-label"
          id="consolegen"
          value={consolegen}
          label="console"
          onChange={handleConsoleChange}
          sx={{
            color: 'white',
            '& .MuiSelect-icon': { color: 'white' }, // Dropdown icon color
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' }, // Border color
          }}
        >
          <MenuItem value='common-gen5'>Current gen (PS5/Xbox series X)</MenuItem>
          <MenuItem value='common-gen4'>Old gen (PS4/Xbox one)</MenuItem>
        </Select>
      </FormControl>
      <Button sx={{ width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '20%' : '20%', height: windowWidth < 600 ? 'unset' : windowWidth < 900 ? '50px' : '50px' }} variant="contained" color="primary" onClick={handleButtonClick}>
        Search
      </Button>
    </Box>
  );
}

export default SearchAndDropdown;