import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, ListItemAvatar, Avatar, Button } from '@mui/material';

const ClubSelectionDialog = ({ open, clubs, onSelect }) => {

    const getImageUrl = (crestID) => {
        if (crestID) {
            return [
                `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png`,
                `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
                'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
            ];
        } else {
            return ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
        }
    };

    const handleImageError = (event, crestID) => {
        const fallbackUrls = getImageUrl(crestID);
        const currentSrc = event.target.src;
        const nextIndex = fallbackUrls.indexOf(currentSrc) + 1;
        if (nextIndex < fallbackUrls.length) {
            event.target.src = fallbackUrls[nextIndex];
        }
    };

    return (
        <Dialog open={open} onClose={() => onSelect(null)} >
            <DialogTitle>Select a Club</DialogTitle>
            <List>
                {clubs.map(club => (
                    <ListItem button onClick={() => onSelect(club)} key={club.teamId} sx={{paddingX: 5}}>
                        <img style={{width: '50px', height: '50px', marginRight: 15}} src={getImageUrl(club.clubInfo.customKit.crestAssetId)[0]} onError={(event) => handleImageError(event, club.clubInfo.customKit.crestAssetId)}></img>
                        {/* <ListItemAvatar>
                            <Avatar
                                src={getImageUrl(club.clubInfo.customKit.crestAssetId)[0]}
                                imgProps={{
                                    onError: (event) => handleImageError(event, club.clubInfo.customKit.crestAssetId)
                                }}
                                alt={`${club.clubInfo.name} logo`}
                            />
                        </ListItemAvatar> */}
                        <ListItemText primary={club.clubInfo.name} secondary={"Record: " + club.record} />
                    </ListItem>
                ))}
            </List>
            <Button onClick={() => onSelect(null)} sx={{mb: 1}}>Cancel</Button>
        </Dialog>
    );
};

export default ClubSelectionDialog;
