import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Link, Container, Icon, Divider } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
function LogoButtonWithIcon({ team, text, league }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const style = {
        width: windowWidth < 600 ? '95%' : windowWidth < 900 ? '95%' : '95%',
        marginLeft: windowWidth < 600 ? '0' : windowWidth < 900 ? '20px' : '20px',

    };

    const MUIContainerStyle = {
        display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5,
        padding: windowWidth < 600 ? '0' : windowWidth < 900 ? '16px' : '16px',
    };

    const LinkStyle = {
        display: 'flex', color: 'white', fontWeight: '700', fontSize: windowWidth < 600 ? '12px' : windowWidth < 900 ? '18px' : '18px',
        alignItems: 'center', fontFamily: 'Inter, Arial, sans-serif', textDecoration: isHovered ? 'underline' : 'none',
    }

    const ArrowStyle = {
        fontSize: windowWidth < 600 ? '20px' : windowWidth < 900 ? '24px' : '24px',
    }

    const LogoStyle = {
        marginRight: 12, marginLeft: 10, width: windowWidth < 600 ? 40 : windowWidth < 900 ? 60 : 60, height: windowWidth < 600 ? 40 : windowWidth < 900 ? 60 : 60,
    }

    if (!team) {
        return null;
    }
    const logoPath = require(`./${league.toUpperCase()}/Logos/${team}.png`);
    const url = `/${league}/${team}`
    return (
        <div style={style}>
            <Container style={MUIContainerStyle}>
                <Link href={url} style={LinkStyle} onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>
                    <img src={logoPath} alt={`${team} logo`} style={LogoStyle} />
                    {text}
                    <ArrowOutwardIcon sx={{ color: 'rgb(152, 152, 158)', gap: '.4rem' }} style={ArrowStyle}></ArrowOutwardIcon>
                </Link>
            </Container>
        </div>

    );
}

export default LogoButtonWithIcon;
