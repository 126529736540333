import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function WOHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.title = "Chel stats | TTHL Home";
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };
 
    const ContainerStyle = {
        padding: '3px',
    };

    const logoPath = require(`./Logos/TTHL_Logo.png`);


    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <img src={logoPath} alt={`WOHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />
                
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Welcome to TTHL!
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{marginTop: 3}}>
                TTHL is one of the largest and most competitive 4s leagues here in EASHL. Ever since its creation in April of 2022, TTHL is ever-expanding and always looking to add more members of all skill levels on both PlayStation and Xbox. TTHL is home to one of the most competitive cross-platform 4s league environments, along with hosting several money tournaments throughout the year!
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for TTHL Season 7!
                </Typography>
                <Button variant="contained" color="primary" href="/TTHL/skater-leaderboard" sx={{fontSize: windowWidth < 600 ? '12px' : windowWidth < 900 ? '16px' : '16px',}}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/TTHL/goalie-leaderboard" sx={{ fontSize: windowWidth < 600 ? '12px' : windowWidth < 900 ? '16px' : '16px', marginLeft: windowWidth < 600 ? 1 : windowWidth < 900 ? 2 : 2 }}>
                    Goalie Leaderboard
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom sx={{marginLeft: windowWidth < 600 ? 2 : windowWidth < 900 ? 2 : 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{marginLeft: windowWidth < 600 ? 2 : windowWidth < 900 ? 2 : 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <LogoButtonWithIcon team={'anaheim-ducks'} text={'Anaheim Ducks'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'boston-bruins'} text={'Boston Bruins'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'carolina-hurricanes'} text={'Carolina Hurricanes'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'colorado-avalanche'} text={'Colorado Avalanche'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'florida-panthers'} text={'Florida Panthers'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />

                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <LogoButtonWithIcon team={'los-angeles-kings'} text={'Los Angeles Kings'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'nashville-predators'} text={'Nashville Predators'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'new-york-rangers'} text={'New York Rangers'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'washington-capitals'} text={'Washington Capitals'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'winnipeg-jets'} text={'Winnipeg Jets'} league={'TTHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
    
                    </Container>
                </Container>
            </Box>

            {/* Repeat for more sections as needed */}
        </Container>
    );
}



export default WOHL_Home;