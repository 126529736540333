import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function WOHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/WOHL_logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img src={logoPath} alt={`WOHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to WOHL!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for WOHL Season 12!
                </Typography>
                <Button variant="contained" color="primary" href="/WOHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/WOHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/WOHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0  }}>
                    Goalie Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/WOHL/bidding" sx={{ marginLeft: windowWidth < 600 ? 2 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0  }}>
                    Bidding
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'anaheim-ducks'} text={'Anaheim Ducks'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'carolina-hurricanes'} text={'Carolina Hurricanes'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'dallas-stars'} text={'Dallas Stars'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'edmonton-oilers'} text={'Edmonton Oilers'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'florida-panthers'} text={'Florida Panthers'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'montreal-canadiens'} text={'Montreal Canadiens'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'san-jose-sharks'} text={'San Jose Sharks'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'utah-hc'} text={'Utah HC'} league={'WOHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                </Container>
            </Box>

            {/* Repeat for more sections as needed */}
        </Container>
    );
}



export default WOHL_Home;