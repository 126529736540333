import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoading } from '../Contexts/LoadingContext';
import { Box, Container, Typography, Divider } from '@mui/material';
import PlayerInfo from './PlayerInfo';
import OverallRadarChart from './OverallRadarChart';
import HockeyDataComponent from './HockeyDataComponent';
import { PropagateLoader } from 'react-spinners';
import PositionToggle from '../EASHL/Players/PositionToggle';
import { useStats } from '../Contexts/StatsContext';
function PlayerProfile({ league }) {
    const { username } = useParams();
    const [skaterStats, setSkaterStats] = useState({});
    const [goalieStats, setGoalieStats] = useState({});
    const { loading, setLoading } = useLoading();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { state, dispatch } = useStats();
    const { positionState } = state;
    const CATEGORY_MAPPINGS_SK = {
        "SHT": [
            'Goals per Game',
            'ShotPerc',
            'Shots per Game',
            'Shots on net Perc',
        ],
        "PSS": [
            'Assists per Game',
            'PassPerc',
            'Passes per Game',
            'Saucer passes per Game',
            'Giveaways per Game'
        ],
        "HND": [
            'FaceoffPerc',
            'Deflections per Game',
            'Possession Per Game(Minutes)',
        ],
        "PHS": [
            'Penalties drawn per Game',
            'PIM per Game',
            'Hits per Game'
        ],
        "DEF": [
            'Takeaways per Game',
            'Interceptions per Game',
            'Blocked shots per Game',
            '(+/-) per Game'
        ]
    };

    const CATEGORY_MAPPINGS_G = {
        "REF": ["Diving saves per game", "Poke checks per game", "Saves per game"],
        "CON": ["Save %", "GAA"],
        "CLU": ["Shutouts per game", "Shutout periods per game", "Breakaway save %", "Penalty shot save %"]
    };

    const WEIGHTS_SK = {
        'TO': 1.5,
        'TD': 1.5,
        'Goals per Game': .95,
        'Assists per Game': 1.1,
        'ShotPerc': .004,
        'Shots per Game': 0.005,
        'PassPerc': .004,
        'Passes per Game': 0.1,
        'Saucer passes per Game': .9,
        'Deflections per Game': .5,
        'Penalties drawn per Game': 1.2,
        'PIM per Game': -0.35,
        'Hits per Game': 0.28,
        'Giveaways per Game': -0.11,
        'Takeaways per Game': 0.2,
        'Interceptions per Game': 0.2,
        'Blocked shots per Game': 0.35,
        'FaceoffPerc': 0.02,
        'Possession Per Game(Minutes)': 0.01,
        'Shots on net Perc': 0.002,
        '(+/-) per Game': 0.06,
    };

    const WEIGHTS_G = {
        "GAA": -0.15,
        "Saves per game": 0.15,
        "Diving saves per game": 0.3,
        "Save %": 0.2,
        "Breakaway save %": 1,
        "Penalty shot save %": 1,
        "Poke checks per game": 0.30,
        "Shutouts per game": 5,
        "Shutout periods per game": 1.7
    };

    const processPlayerData = (data, headers, isGoalie) => {
        const playerData = data.find(row => row[0] === username);
        if (!playerData) return {};

        const playerStats = {};
        headers.forEach((header, index) => {
            playerStats[header] = playerData[index];
        });

        // Perform calculations
        if (playerStats['Games Played'] && playerStats['Points']) {
            playerStats['Points per Game'] = (playerStats['Points'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Assists']) {
            playerStats['Assists per Game'] = (playerStats['Assists'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Goals']) {
            playerStats['Goals per Game'] = (playerStats['Goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Passes']) {
            playerStats['Passes per Game'] = (playerStats['Passes'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Saucer passes']) {
            playerStats['Saucer passes per Game'] = (playerStats['Saucer passes'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Deflections']) {
            playerStats['Deflections per Game'] = (playerStats['Deflections'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Penalties drawn']) {
            playerStats['Penalties drawn per Game'] = (playerStats['Penalties drawn'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['PIM']) {
            playerStats['PIM per Game'] = (playerStats['PIM'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Hits']) {
            playerStats['Hits per Game'] = (playerStats['Hits'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Giveaways']) {
            playerStats['Giveaways per Game'] = (playerStats['Giveaways'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Interceptions']) {
            playerStats['Interceptions per Game'] = (playerStats['Interceptions'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Takeaways']) {
            playerStats['Takeaways per Game'] = (playerStats['Takeaways'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Blocked shots']) {
            playerStats['Blocked shots per Game'] = (playerStats['Blocked shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['(+/-)']) {
            playerStats['(+/-) per Game'] = (playerStats['(+/-)'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shots']) {
            playerStats['Shots per Game'] = (playerStats['Shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shot attempts']) {
            playerStats['Shot attempts per game'] = (playerStats['Shot attempts'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Missed shots']) {
            playerStats['Missed shots per game'] = (playerStats['Missed shots'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Powerplay goals']) {
            playerStats['Powerplay goals per game'] = (playerStats['Powerplay goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Shorthanded goals']) {
            playerStats['Shorthanded goals per game'] = (playerStats['Shorthanded goals'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Pass attempts']) {
            playerStats['Pass attempts per game'] = (playerStats['Pass attempts'] / playerStats['Games Played']).toFixed(2);
        }
        if (playerStats['Games Played'] && playerStats['Missed Shots']) {
            playerStats['Missed shots per game'] = (playerStats['Missed Shots'] / playerStats['Games Played']).toFixed(2);
        }








        if (playerStats['Team Name']) {
            playerStats['transformedTeamName'] = transformTeamName(playerStats['Team Name']);
        }

        playerStats['ShotPerc'] = playerStats['Shot %'].replace('%', '');
        playerStats['PassPerc'] = playerStats['Pass %'].replace('%', '');
        playerStats['FaceoffPerc'] = playerStats['Faceoff %'].replace('%', '');
        playerStats['Shots on net Perc'] = playerStats['Shots on net %'].replace('%', '');

        if (isGoalie) {
            playerStats['Overall rating'] = calculateOverallRatingG(playerStats, WEIGHTS_G, playerStats['Games Played'], CATEGORY_MAPPINGS_G, playerStats['Penalty shot attempts per game']);
        } else {
            playerStats['Overall rating'] = calculateOverallRatingSK(playerStats);
        }

        let playstyle = "";
        let overall_cats = playerStats['Overall rating']['Category Ratings']
        let position = playerStats.Position;
        if (position == "rightWing") {
            position = "RW";
        } else if (position == "leftWing") {
            position = "LW";
        } else if (position == "center") {
            position = "C";
        } else if (position == "defenseMen") {
            position = "D";
        } else if (position == "goalie") {
            position = "G";
        }

        let sortedCats = Object.keys(overall_cats).sort((a, b) => overall_cats[b] - overall_cats[a]);

        // Initialize an array to hold the largest categories
        let largestCats = [sortedCats[0], sortedCats[1]];

        if (['C', 'LW', 'RW', 'G'].includes(position)) {

            switch (largestCats[0]) {
                case "SHT":
                    playstyle = "Sniper";
                    break;
                case "PSS":
                    playstyle = "Playmaker";
                    break;
                case "HND":
                    playstyle = "Dangler";
                    break;
                case "PHS":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Hitting Sniper";
                            break;
                        case "PSS":
                            playstyle = "Hitting Playmaker";
                            break;
                        case "HND":
                            playstyle = "Hitting Dangler";
                            break;
                        case "DEF":
                            playstyle = "Two-way Forward";
                            break;
                    }
                    break;
                case "DEF":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Defensive Sniper";
                            break;
                        case "PSS":
                            playstyle = "Defensive Playmaker";
                            break;
                        case "HND":
                            playstyle = "Defensive Dangler";
                            break;
                        case "PHS":
                            playstyle = "Two-way Forward";
                            break;
                    }
                    break;
            }
        } else if (['D', 'G'].includes(position)) {
            switch (largestCats[0]) {
                case "SHT":
                    switch (largestCats[1]) {
                        case "PSS":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "HND":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Two-way Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Two-way Defenseman";
                            break;
                    }
                    break;
                case "HND":
                    switch (largestCats[1]) {
                        case "PSS":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "SHT":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Two-way Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Two-way Defenseman";
                            break;
                    }
                    break;
                case "PSS":
                    switch (largestCats[1]) {
                        case "SHT":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "HND":
                            playstyle = "Offensive Defenseman";
                            break;
                        case "PHS":
                            playstyle = "Puck-moving Defenseman";
                            break;
                        case "DEF":
                            playstyle = "Puck-moving Defenseman";
                            break;
                    }
                    break;
                case "PHS":
                    playstyle = "Defensive Defenseman";
                    break;
                case "DEF":
                    playstyle = "Defensive Defenseman";
                    break;
            }
        }
        playerStats['Playstyle'] = playstyle

        return playerStats;
    };

    const filterColumns = (headers, rows, startColIndex, endColIndex) => {
        const validHeaders = headers.slice(startColIndex, endColIndex + 1);
        const validRows = rows.map(row => row.slice(startColIndex, endColIndex + 1));
        return { validHeaders, validRows };
    };

    const transformTeamName = (teamName) => {
        return teamName
            .replace(/^[^\s]+\s/, '') // Remove the league prefix (e.g., "NAEHL ")
            .toLowerCase()
            .replace(/\s+/g, '-'); // Replace spaces with hyphens
    };

    const calculateCategoryRatingSK = (stats, category, scalingNum = 60) => {
        let categoryRating = 0;
        const maxOvr = 99;
        const minOvr = 50;

        CATEGORY_MAPPINGS_SK[category].forEach(statName => {
            let weight = WEIGHTS_SK[statName] || 0;
            let statValue = parseFloat(stats[statName] || 0);
            if (statName.includes("%")) {
                statValue /= 100.0;
            }

            categoryRating += statValue * weight;
        });

        categoryRating *= 100;
        categoryRating = Math.max(categoryRating, minOvr);
        let scaledOvr = maxOvr * (1 - Math.exp(-categoryRating / scalingNum));
        scaledOvr = Math.max(Math.round(scaledOvr), minOvr);
        return scaledOvr;
    };

    const calculateOverallRatingSK = (stats) => {
        let compositeOvr = 0.0;
        let allCategoryRatings = {};

        for (let category in CATEGORY_MAPPINGS_SK) {
            let rating = calculateCategoryRatingSK(stats, category);
            allCategoryRatings[category] = rating;
        }

        let weightedAverageForPosition = Object.values(allCategoryRatings).reduce((a, b) => a + b, 0) / Object.keys(allCategoryRatings).length;
        compositeOvr = Math.round(weightedAverageForPosition);

        return {
            "Overall Rating": compositeOvr,
            "Category Ratings": allCategoryRatings
        };
    };

    const calculateCategoryRatingG = (stats, weights, category, isThreeVsThreePlayer) => {
        let categoryRating = 0;
        const PENALTY_FACTOR = 1.07; // Boost factor for 3s players
        const maxOvr = 99;
        const minOvr = 50;

        CATEGORY_MAPPINGS_G[category].forEach(statName => {
            let weight = weights[statName] || 0;
            let statValue = 0;

            if (stats[statName] && !stats[statName].includes("-")) {
                statValue = parseFloat(stats[statName]);
                if (statName.includes("Save %")) {
                    statValue *= 10.0;
                } else if (statName.includes("Breakaway save %") || statName.includes("Penalty shot save %")) {
                    statValue /= 10.0;
                }
            }

            categoryRating += statValue * weight;
        });

        if (isThreeVsThreePlayer) {
            categoryRating *= PENALTY_FACTOR;
        }

        categoryRating *= 100;
        categoryRating = Math.max(categoryRating, minOvr);
        let scaledOvr = maxOvr * (1 - Math.exp(-categoryRating / 50));
        scaledOvr = Math.max(Math.round(scaledOvr), minOvr);
        return Math.round(scaledOvr);
    };

    const calculateOverallRatingG = (stats, weights, gamesPlayed, categoryMappings, penaltyShotAttemptsPerGame) => {
        const THREE_VS_THREE_PENALTY_THRESHOLD = 0.18;
        const isThreeVsThreePlayer = penaltyShotAttemptsPerGame >= THREE_VS_THREE_PENALTY_THRESHOLD;

        let compositeOvr = 0.0;
        let allCategoryRatings = {};

        Object.keys(categoryMappings).forEach(category => {
            let rating = calculateCategoryRatingG(stats, weights, category, isThreeVsThreePlayer);
            allCategoryRatings[category] = rating;
        });

        let scalingFactor = 1.0; // Adjust as necessary based on your data
        let weightedAverage = Object.values(allCategoryRatings).reduce((a, b) => a + b, 0) / Object.keys(allCategoryRatings).length;
        compositeOvr += weightedAverage * scalingFactor;

        let avgRatings = Object.fromEntries(Object.entries(allCategoryRatings).map(([category, rating]) => [category, Math.round(rating)]));
        compositeOvr = Math.round(compositeOvr);
        let mainGameMode = isThreeVsThreePlayer ? "3s" : "6s";

        return {
            "Overall Rating": Math.round(compositeOvr),
            "Category Ratings": avgRatings,
            "Main Game Mode": mainGameMode
        };
    };

    useEffect(() => {
        const fetchPlayerData = async () => {
            setLoading(true);
            try {
                // Fetch skater data
                const skaterResponse = await fetch(`/api/fetch/leaderboard?league=${league}&type=skater-master`);
                if (!skaterResponse.ok) throw new Error('Failed to fetch skater data');
                const skaterData = await skaterResponse.json();
                const skaterHeaders = skaterData.values[1]; // Assume headers are in the second row
                const skaterRows = skaterData.values.slice(2); // Rest are data rows

                const { validHeaders: validSkaterHeaders, validRows: validSkaterRows } = filterColumns(skaterHeaders, skaterRows, 4, 64); // Columns E (index 4) to BM (index 66)

                // Fetch goalie data
                const goalieResponse = await fetch(`/api/fetch/leaderboard?league=${league}&type=goalie-master`);
                if (!goalieResponse.ok) throw new Error('Failed to fetch goalie data');
                const goalieData = await goalieResponse.json();
                const goalieHeaders = goalieData.values[1]; // Assume headers are in the second row
                const goalieRows = goalieData.values.slice(2); // Rest are data rows

                const { validHeaders: validGoalieHeaders, validRows: validGoalieRows } = filterColumns(goalieHeaders, goalieRows, 4, 64); // Columns E (index 4) to BM (index 66)

                // Process and set the data
                const skaterStats = processPlayerData(validSkaterRows, validSkaterHeaders, false);
                const goalieStats = processPlayerData(validGoalieRows, validGoalieHeaders, true);

                setSkaterStats(skaterStats);
                setGoalieStats(goalieStats);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch player data:', error);
                setLoading(false);
            }
        };

        fetchPlayerData();
    }, [username, league, setLoading]);

    const overallRating = skaterStats["Overall rating"]?.["Overall Rating"] || goalieStats["Overall rating"]?.["Overall Rating"] || 0;

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><PropagateLoader color="#3498db" /></div>;
    }

    const renderStats = () => {
        if (positionState === 'goalie') {
            return (
                <>
                    <PlayerInfo playerData={goalieStats} position={goalieStats.Position} overall={goalieStats["Overall rating"]?.["Overall Rating"]} league={league} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Overview</Typography>
                        <PositionToggle />
                    </Box>
                    <Divider sx={{ borderColor: '#424549', marginTop: 1.5 }} />

                    <OverallRadarChart playerData={goalieStats} />
                    <Box elevation={3} sx={{ margin: 'auto', marginBottom: 5, maxWidth: windowWidth < 600 ? 500 : windowWidth < 900 ? '100%' : '100%', textAlign: 'center' }}>
                        <HockeyDataComponent playerStats={goalieStats} />
                    </Box>
                </>
            );
        } else {
            return (
                <>
                    <PlayerInfo playerData={skaterStats} position={skaterStats.Position} overall={skaterStats["Overall rating"]?.["Overall Rating"]} league={league} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                        <Typography variant="h4" sx={{ color: 'white' }}>Overview</Typography>
                        <PositionToggle />
                    </Box>
                    <Divider sx={{ borderColor: '#424549', marginTop: 1.5 }} />

                    <OverallRadarChart playerData={skaterStats} />
                    <Box elevation={3} sx={{ margin: 'auto', marginBottom: 5, maxWidth: windowWidth < 600 ? 500 : windowWidth < 900 ? '100%' : '100%', textAlign: 'center' }}>
                        <HockeyDataComponent playerStats={skaterStats} />
                    </Box>
                </>
            );
        }
    };

    return (
        <div>
            <Container>
                <Box>
                    {renderStats()}
                </Box>
            </Container>
        </div>
    );
}

export default PlayerProfile;
