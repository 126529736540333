// StatsContext.js
import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  positionState: 'skater', // Default position
  freeAgentState: false
};

function statsReducer(state, action) {
  switch (action.type) {
    case 'SET_POSITION':
      return {
        ...state,
        positionState: action.payload,
      };
    case 'SET_FREE_AGENT': // New action type for setting the team
      return {
        ...state,
        freeAgentState: action.payload,
      };
    default:
      return state;
  }
}

const StatsContext = createContext();

export const StatsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(statsReducer, initialState);

  return (
    <StatsContext.Provider value={{ state, dispatch }}>
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => {
    const context = useContext(StatsContext);
    if (context === undefined) {
      throw new Error('useStats must be used within a StatsProvider');
    }
    return context;
  };
