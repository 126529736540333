// UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch the session on initial load
    fetch('/api/auth/session')
      .then(response => response.json())
      .then(data => {
        if (data.isLoggedIn) {
          setUser(data.user); // Set user details if logged in
        } else {
          setUser(null); // Reset user details if not logged in
        }
      })
      .catch(error => console.error('Error checking session:', error));
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

// Custom hook to use user context
export function useUser() {
  return useContext(UserContext);
}
