import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Button, Paper, Container, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
import AdComponent from './AdComponent';

// Create a dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function FreeAgentsList() {
    const [freeAgents, setFreeAgents] = useState([]);
    const [filter, setFilter] = useState('');
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        document.title = `Chel stats | Free Agents`;
    }, []);

    useEffect(() => {
        fetchFreeAgents();
    }, []);

    const fetchFreeAgents = async () => {
        try {
            const response = await fetch('/api/free-agents');
            const data = await response.json();
            setFreeAgents(data);
        } catch (error) {
            console.error('Failed to fetch free agents:', error);
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleViewStats = (username, teamname, consolegen) => {
        navigate(`/players?username=${username}&teamname=${teamname}&console=${consolegen}`); // Navigate to the player's stats page
    };

    if (freeAgents)
        return (
            <ThemeProvider theme={darkTheme}>
                <Container maxWidth="sm" component="main">
                    <Typography variant="h4" component="h1" gutterBottom align="center" sx={{color: 'white', marginY: 5}}>
                        Free Agents
                    </Typography>
                    <Typography variant="h6" component="h6" gutterBottom align="center" sx={{color: 'white', marginY: 5}}>
                        Check out the users who have set their profiles to be free agents!
                    </Typography>

                    <Paper elevation={3} sx={{ mt: 4, p: 1, backgroundColor: '#121212' }}>

                        <FormControl fullWidth>
                            <InputLabel id="position-filter-label">Filter by Position</InputLabel>
                            <Select
                                labelId="position-filter-label"
                                id="position-filter"
                                value={filter}
                                label="Filter by Position"
                                onChange={handleFilterChange}
                                sx={{ mb: 2 }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Center">Center</MenuItem>
                                <MenuItem value="Right wing">Right wing</MenuItem>
                                <MenuItem value="Left wing">Left wing</MenuItem>
                                <MenuItem value="Right defense">Right defense</MenuItem>
                                <MenuItem value="Left defense">Left defense</MenuItem>
                                <MenuItem value="Goalie">Goalie</MenuItem>
                            </Select>
                        </FormControl>
                        <List>
                            {freeAgents.filter(agent => filter === "" || agent.claimedProfile.settings.position === filter).map((agent) => (
                                <ListItem key={agent._id} sx={{ border: '1px solid #333', mb: 2, borderRadius: 2 }}>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={`https://cdn.discordapp.com/avatars/${agent.discordId}/${agent.avatar}.png`}
                                            alt={`${agent.username}'s avatar`}
                                            sx={{ width: 56, height: 56, mr: 2 }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={agent.username}
                                        secondary={`Position: ${agent.claimedProfile.settings.position}`}
                                        secondaryTypographyProps={{ color: 'primary.light' }}
                                    />
                                    <Button color="primary" href={`https://discord.com/users/${agent.discordId}`} target="_blank" rel="noopener noreferrer" sx={{ ml: 2 }}>
                                        Contact
                                    </Button>
                                    <Button color="primary" onClick={() => handleViewStats(agent.claimedProfile.username, agent.claimedProfile.teamname, agent.claimedProfile.consolegen)} sx={{ ml: 2 }}>
                                        View Stats
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>

                </Container>
            </ThemeProvider>
        );
}

export default FreeAgentsList;
