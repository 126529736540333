import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Link, Avatar, Button, ThemeProvider } from '@mui/material';
import AdComponent from '../Main Components/AdComponent';

const ImagineNotScoringThere = ({ channelId }) => {
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        async function fetchMessages() {
            const response = await fetch(`/api/messages/${channelId}`);
            const data = await response.json();
            setMessages(data);
        }
        fetchMessages();
    }, [channelId]);
    useEffect(() => {
        document.title = `Chel stats | Imagine Not Scoring There`;
      }, []);

    // Pagination handlers
    const indexOfLastMessage = currentPage * itemsPerPage;
    const indexOfFirstMessage = indexOfLastMessage - itemsPerPage;
    const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

    const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => (prev > 1 ? prev - 1 : 1));
    };

    return (
        <Box sx={{ margin: 4, bgcolor: 'transparent' }}>
            <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Welcome to Imagine not scoring there!</Typography>
            <Typography variant="body1" color="white" paragraph>For all members of the Chel stats discord server, let's have a laugh and enjoy the brutal showcase of players missing wide open scoring chances!</Typography>

            {currentMessages.map(msg => {
                if (msg.videos.length == 0 && msg.images.length == 0) return null
                return (
                    <Card key={msg.id} sx={{ maxWidth: '100%', my: 2, backgroundColor: '#171717' }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Avatar
                                    alt={msg.author}
                                    src={`https://cdn.discordapp.com/avatars/${msg.authorId}/${msg.avatar}.png?size=256`}
                                    sx={{ width: 40, height: 40, marginRight: 1 }}
                                />
                                <Typography gutterBottom variant="h6" sx={{ color: 'white' }}>
                                    @{msg.author}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{ color: 'white' }}>
                                {msg.content.replace(/https:\/\/[\n\S]+/g, '')}
                            </Typography>
                        </CardContent>
                        {msg.images && msg.images.map((image, index) => (
                            <CardMedia
                                key={index}
                                component="img"
                                image={image}
                                alt="Discord attachment"
                                sx={{ maxWidth: windowWidth < 600 ? '100%' : windowWidth < 900 ? '30%' : '30%', height: 'auto',  }}
                            />
                        ))}
                        {msg.videos && msg.videos.map((video, index) => (
                            <video key={index} controls src={video} style={{ width: '100%' }} />
                        ))}
                    </Card>
                )
            })}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</Button>
                <Button onClick={handleNextPage} disabled={currentPage * itemsPerPage >= messages.length}>Next</Button>
            </Box>
        </Box>
    );
};

export default ImagineNotScoringThere;
