import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function IGHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/IGHL_logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={logoPath} alt={`IGHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to IGHL!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for IGHL!
                </Typography>
                <Button variant="contained" color="primary" href="/IGHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/IGHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/IGHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'day-star-87s'} text={'Day Star 87s'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'blues'} text={'Blues'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'northeast-voyageurs'} text={'Northeast Voyageurs'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'border-chiefs'} text={'Border Chiefs'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'warriors'} text={'Warriors'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'ilex-islanders'} text={'Ilex Islanders'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'badge-wolverines'} text={'Badge Wolverines'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'elsipogtog-oilers'} text={'Elsipogtog Oilers'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'town-indians'} text={'Town Indians'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'shubenacadie-river-kings'} text={'Shubenacadie River Kings'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'pa-raiders'} text={'PA Raiders'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'sc-wolfpack'} text={'Sucker Creek Wolfpack'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'golden-hawks'} text={'Golden Hawks'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'glf-flames'} text={'Goodfish Flames'} league={'IGHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default IGHL_Home;