import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function OHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/OHL_logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={logoPath} alt={`OHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to OHL!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for OHL Season 11!
                </Typography>
                <Button variant="contained" color="primary" href="/OHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/OHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/OHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'arizona-coyotes'} text={'Arizona Coyotes'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'buffalo-sabres'} text={'Buffalo Sabres'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'carolina-hurricanes'} text={'Carolina Hurricanes'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'colorado-avalanche'} text={'Colorado Avalanche'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'minnesota-wild'} text={'Minnesota Wild'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'los-angeles-kings'} text={'Los Angeles Kings'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'pittsburgh-penguins'} text={'Pittsburgh Penguins'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'philadelphia-flyers'} text={'Philadelphia Flyers'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'seattle-kraken'} text={'Seattle Kraken'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'toronto-maple-leafs'} text={'Toronto Maple Leafs'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'vegas-golden-knights'} text={'Vegas Golden Knights'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'washington-capitals'} text={'Washington Capitals'} league={'OHL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default OHL_Home;