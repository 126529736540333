import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function NAEHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/SBHL_Logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={logoPath} alt={`SBHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to SBHL!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for SBHL!
                </Typography>
                <Button variant="contained" color="primary" href="/SBHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/SBHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/SBHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/SBHL/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/SBHL/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard Playoffs
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'boston-bruins'} text={'Boston Bruins'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'colorado-avalanche'} text={'Colorado Avalanche'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'detroit-red-wings'} text={'Detroit Red Wings'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'edmonton-oilers'} text={'Edmonton Oilers'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'nashville-predators'} text={'Nashville Predators'} league={'SBHL'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'new-york-rangers'} text={'New York Rangers'} league={'SBHL'} />    
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'ottawa-senators'} text={'Ottawa Senators'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'toronto-maple-leafs'} text={'Toronto Maple Leafs'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'vegas-golden-knights'} text={'Vegas Golden Knights'} league={'SBHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'winnipeg-jets'} text={'Winnipeg Jets'} league={'SBHL'} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default NAEHL_Home;