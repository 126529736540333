import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Button, Paper, Container, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdComponent from './AdComponent';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function ScoutingClubsList() {
    const [clubs, setClubs] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        document.title = `Chel stats | Scouting Clubs`;
    }, []);

    useEffect(() => {
        fetchScoutingClubs();
    }, []);

    const fetchScoutingClubs = async () => {
        try {
            const response = await fetch('/api/scouting');
            const data = await response.json();
            setClubs(data);
        } catch (error) {
            console.error('Failed to fetch scouting clubs:', error);
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const getScoutingPositions = (scouting) => {
        return scouting.map(s => `${s.position} (${s.console})`).join(', ');
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Container maxWidth="sm" component="main">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'white', marginY: 5 }}>
                    Scouting Clubs
                </Typography>
                <Typography variant="h6" component="h6" gutterBottom align="center" sx={{color: 'white', marginY: 5}}>
                        Check out the clubs who have set their scouting preferences!
                    </Typography>
                <Paper elevation={3} sx={{ mt: 4, p: 1, backgroundColor: '#121212' }}>
                    <FormControl fullWidth>
                        <InputLabel id="scouting-filter-label">Filter by Position</InputLabel>
                        <Select
                            labelId="scouting-filter-label"
                            id="scouting-filter"
                            value={filter}
                            label="Filter by Position"
                            onChange={handleFilterChange}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Center">Center</MenuItem>
                            <MenuItem value="Right wing">Right wing</MenuItem>
                            <MenuItem value="Left wing">Left wing</MenuItem>
                            <MenuItem value="Right defense">Right defense</MenuItem>
                            <MenuItem value="Left defense">Left defense</MenuItem>
                            <MenuItem value="Goalie">Goalie</MenuItem>
                        </Select>
                    </FormControl>
                    <List>
                        {clubs.filter(club => filter === "" || club.scouting.some(s => s.position === filter)).map((club) => (
                            <ListItem key={club.teamId} sx={{ border: '1px solid #333', mb: 2, borderRadius: 2 }}>
                                <ListItemText
                                    primary={club.clubname}
                                    secondary={`Owner: ${club.owner} - Scouting: ${getScoutingPositions(club.scouting)}`}
                                />
                                <Button color="primary" onClick={() => window.location.href = `/clubs?teamname=${club.clubname}&console=${club.consolegen}#info`} sx={{ ml: 2 }}>
                                    View Details
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

export default ScoutingClubsList;
