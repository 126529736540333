import React from 'react';
import { Box } from '@mui/material';

import ClubsInfo from './ClubsInfo';

import ClubTabs from './ClubTabs';
import AdComponent from '../../Main Components/AdComponent';

function SetClub({clubData, scoutingInfo, clubInfo, team, consolegen}) {

    if (clubData) {
    
        return (
            <Box>
                <ClubsInfo clubData={clubData} crestID={clubData.teamData.clubInfo.customKit.crestAssetId}/>

                <ClubTabs clubData={clubData} scoutingInfo={scoutingInfo} clubInfo={clubInfo} team={team} consolegen={consolegen}/>

            </Box>
        );
    
    }
}

export default SetClub;