import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Container, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ClubLeaders from './ClubLeaders';
import RecentGames from './RecentGames';
import ClubPlayerStatsTable from './ClubPlayerStatsTable';
import ClubScouting from './ClubScouting';
import ClubInfo from './ClubInfo';
import AdComponent from '../../Main Components/AdComponent';
import { useLocation, useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: '100%', overflow: 'auto', minHeight: '200px' }} // You might merge this with `...other` if passing styles via props
    >
      {value === index && (
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ClubTabs({ clubData, scoutingInfo, clubInfo, team, consolegen }) {
  const [value, setValue] = React.useState('overview');
  const [gameType, setGameType] = useState('RegularSeason'); // default value
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    // Extract hash and ignore the '#' character
    const hash = location.hash.replace('#', '');
    if (hash) setValue(hash);
  }, [location.hash]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Maintain existing pathname and search parameters while updating only the hash
    navigate(`${location.pathname}${location.search}#${newValue}`, { replace: true });
  };
  const handleGameTypeChange = (event) => {
    setGameType(event.target.value);
  };


  return (
    <Box sx={{ marginTop: 5, marginBottom: 5 }}>
      <Container sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="stats tabs" variant="scrollable">
          <Tab key='overview' label='Overview' value='overview' sx={{ color: 'white' }} />
          <Tab key='roster' label='Roster' value='roster' sx={{ color: 'white' }} />
          <Tab key='scouting' label='Scouting' value='scouting' sx={{ color: 'white' }} />
          <Tab key='info' label='Info' value='info' sx={{ color: 'white' }} />

        </Tabs>
      </Container>
      <TabPanel value={value} index='overview'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '16px' }}>
          <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>Recent games</Typography>
          <FormControl variant="standard" size="medium" sx={{ minWidth: 120, '& .MuiSelect-icon': { color: 'white' } }}>
            <Select
              labelId="game-type-select-label"
              id="game-type-select"
              value={gameType}
              onChange={handleGameTypeChange}
              label="Game Type"
              sx={{ color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
            >
              <MenuItem value="RegularSeason">Regular season</MenuItem>
              <MenuItem value="ClubFinals">Club finals</MenuItem>
              <MenuItem value="PrivateGames">Private</MenuItem>
            </Select>
          </FormControl>
        </div>
        <RecentGames games={clubData.recentGames[gameType]} gametype={gameType} id={clubData.teamData.clubId} team={team} consolegen={consolegen} />
        <Divider sx={{ borderColor: '#424549', marginTop: 5, marginBottom: 5, width: windowWidth < 600 ? '90%' : windowWidth < 900 ? '96%' : '96%', marginX: 'auto' }} />
        <Typography variant="h6" gutterBottom sx={{ color: 'white', marginLeft: 1, marginTop: 4 }}>Team leaders</Typography>
        <ClubLeaders clubData={clubData}></ClubLeaders>
        {/* <AdComponent slotID={"7688723993"} /> */}
      </TabPanel>
      <TabPanel value={value} index='roster'>
        <ClubPlayerStatsTable clubData={clubData}></ClubPlayerStatsTable>
        {/* <AdComponent slotID={"7688723993"} /> */}

      </TabPanel>
      <TabPanel value={value} index='scouting'>
        <ClubScouting scoutingInfo={scoutingInfo} />
        {/* <AdComponent slotID={"7688723993"} /> */}

      </TabPanel>
      <TabPanel value={value} index='info'>
        <ClubInfo clubInfo={clubInfo} href="#Club-Info" />
        {/* <AdComponent slotID={"7688723993"} /> */}

      </TabPanel>
    </Box>
  );
}

export default ClubTabs;
