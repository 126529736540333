import React, { useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link } from '@mui/material';
import AdComponent from './AdComponent';

const data = {
  PlayStation: [
    // {
    //   name: "VGHL (Virtual Gaming Hockey League)",
    //   console: "PlayStation 4",
    //   format: "6s",
    //   discord: "https://discord.gg/mtKC5r6",
    //   website: "https://vghl.myvirtualgaming.com/",
    //   social: "https://twitter.com/mvghl",
    //   description: "Our league is the longest standing EASHL league on PS4. We play on Sun, Mon, Wed, and Thur evenings. We have been around for 11 years and have players of all different skill levels."
    // },
    // {
    //   name: "TGL (Threes Gaming League)",
    //   console: "PlayStation",
    //   format: "3s",
    //   discord: "https://discord.gg/qqUYSTfXAm",
    //   website: "",
    //   social: "https://www.instagram.com/threesgamingleague/",
    //   description: "Our League is going into the 3rd season. We have over 150+ active players and a great competitive atmosphere."
    // },
    // {
    //   name: "PHA (Prospect Hockey Association)",
    //   console: "PlayStation",
    //   format: "6s",
    //   discord: "https://discord.gg/3tBUBXguNu",
    //   website: "https://sportsillustratedprospects.com/esports/main/prospects-ehockey-association/",
    //   social: "https://www.instagram.com/psi_ehockey/",
    //   description: "6s league, sponsored by Sports Illustrated. High skill ceiling. Very fun league and live draft! Weekly newsletter and 28 teams."
    // },
    // {
    //   name: "MGHL (Modern Gaming Hockey League)",
    //   console: "PlayStation",
    //   format: "6s",
    //   discord: "https://discord.gg/MgW6gfTvez",
    //   website: "https://www.moderngamings.com/",
    //   social: "https://twitter.com/NBHL2021",
    //   description: "The NBHL is an elite league moving into its second season with over 300 players. The league consists of 10 games per week."
    // },
    {
      name: "WOHL (Western Only Hockey League)",
      console: "PlayStation",
      format: "6s",
      discord: "https://discord.gg/hSjTqeSdpy",
      website: "",
      social: "",
      description: "Sunday, Monday, and Tuesdays at 7:15, 8, 8:45pm PST."
    },
    {
      name: "CNC (Chirps N Cheers)",
      console: "PlayStation",
      format: "6s",
      discord: "https://discord.gg/Q25FKX8Tuk",
      website: "https://www.mystatsonline.com/cnc",
      social: "",
      description: "PS4/5 6’s league looking at a M/W/F schedule. Each player must have a minimum 100 games played online to be draft eligible."
    },
    // {
    //   name: "BRHL (Big Richard's Hockey League)",
    //   console: "PlayStation 4",
    //   format: "3s",
    //   discord: "https://discord.gg/FZ7Sq2ZNgB",
    //   website: "",
    //   social: "https://youtu.be/-OnXPl6Oqug",
    //   description: "A chill chel community that runs short seasons in order for players to play with different teammates and keep things fresh! 18+"
    // },
    // {
    //   name: "ITHL (Ice Tilt Hockey League)",
    //   console: "PlayStation 4 & 5",
    //   format: "6s",
    //   discord: "https://discord.gg/zgh3Wvydfj",
    //   website: "https://icetilthockeyleague.com/",
    //   social: "https://twitter.com/IceTiltHL",
    //   description: "A tiered BYOC 6s League that tries to engage its members through many forms of media. Schedule your own game times."
    // },
    // {
    //   name: "TLX (TLX College League)",
    //   console: "PlayStation 4",
    //   format: "3s & 6s",
    //   discord: "https://discord.gg/ZvVUEaR7Wh",
    //   website: "https://cl.tealx.net",
    //   social: "",
    //   description: "Fairly competitive but we don't allow the top end players in the community in as they mess with the balance."
    // },
    // {
    //   name: "NACHL (North American Confederacy Hockey League)",
    //   console: "PlayStation 4",
    //   format: "6s",
    //   discord: "https://discord.gg/WtNrU9pUKu",
    //   website: "https://nachl.webnode.page/",
    //   social: "",
    //   description: "6s, Rank 3 - 1, Four days, TBA, Lots of good moderation, lots of work put into the league, Great Media group."
    // },
    // {
    //   name: "FBHL (Facebook Hockey League)",
    //   console: "PlayStation 4",
    //   format: "6s",
    //   discord: "https://discord.gg/ErPvXszjPh",
    //   website: "https://www.fbhl.org/",
    //   social: "",
    //   description: "League is going into season 15 on July 12th. Roughly 500 players signed up. Games are Tuesdays-Fridays 9-10:30pm Eastern time."
    // },
    // {
    //   name: "FSHL (Full Send Hockey League)",
    //   console: "PlayStation 4 & 5",
    //   format: "6s",
    //   discord: "https://discord.gg/9wuN8XvrkA",
    //   website: "http://www.fullsend6s.com",
    //   social: "https://twitter.com/FullSend6s",
    //   description: "Full Send 6s looks to bridge the gap during the middle of the week for players. We operate Tuesday/Wednesday/Thursday."
    // },
    {
      name: "TTHL (Top Tier Hockey League)",
      console: "PlayStation 5",
      format: "3s",
      discord: "https://discord.gg/BwBV5xTQkf",
      website: "http://www.toptierhockey.com/",
      social: "",
      description: "Many Top 100 Club PS5 players. Thursdays & Fridays are game nights. Auto Stat input website coming soon."
    },
    {
      name: "PGL (Premier gaming leagues)",
      console: "PlayStation 5",
      format: "3s",
      discord: "https://discord.gg/premiergaming",
      website: "https://premieronlinegamingleagues.com/nhl-24",
      social: "",
      description: "PGL is one of the fastest growing communities in chel! We are an open and positive environment for all skill levels to come in and compete or continue to improve at the game. SEASON 4 BIDDING STARTS FRIDAY 2-23-2024. We have our own media team that casts the games live, does pregame shows, and does live community updates! We encourage everyone to come in a support and watch some of your future teammates play now!"
    },
    {
      name: "OHL (Omega Hockey League)",
      console: "PlayStation 5",
      format: "3s",
      discord: "https://discord.gg/kfxw5XjmHf",
      website: "",
      social: "",
      description: ""
    },
    // {
    //   name: "League of the People",
    //   console: "PlayStation 4",
    //   format: "6s",
    //   discord: "https://discord.gg/wfPe9kcnET",
    //   website: "",
    //   social: "",
    //   description: "Brand New League, tournament style until NHL 23 is released, all skill levels welcome."
    // },
    // {
    //   name: "M6 (Meta Hockey League)",
    //   console: "PlayStation 5",
    //   format: "6s",
    //   discord: "https://discord.gg/vXgz2wg7DN",
    //   website: "",
    //   social: "",
    //   description: "M6 is a salary-based franchise EASHL. Where manages are able to build core groups to compete for multiple seasons."
    // },
    // {
    //   name: "VHL (Vintage Hockey League)",
    //   console: "PlayStation 4/XB1",
    //   format: "6s",
    //   discord: "https://discord.gg/aQy3Y3aEGV",
    //   website: "https://www.mystatsonline.com/hockey/visitor/league/home/home_hockey.aspx?IDLeague=60935",
    //   social: "",
    //   description: "The VHL was established in May 2020. It's a bring-your-own-team format. Teams are made up of buddies."
    // }
  ],
  Xbox: [
    // {
    //   name: "GCHL (Global Chel Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/wM4JfrR",
    //   website: "",
    //   social: "",
    //   description: "A redraft league with chill members who just want to have fun. Games run on Tuesday, Thursday and Sunday at 9 and 10."
    // },
    // {
    //   name: "NZHL (Neutral Zone Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/WvWqUjf",
    //   website: "https://www.mystatsonline.com/hockey/visitor/league/stats/skater_hockey.aspx?IDLeague=60202",
    //   social: "https://www.twitch.tv/nzhl",
    //   description: "NZHL is a 6s Xbox based league presented by the In The Neutral Zone hockey podcast. The league is community driven with media coverage."
    // },
    // {
    //   name: "ICHL (International Chel Hockey League)",
    //   console: "Xbox",
    //   format: "3s",
    //   discord: "https://discord.gg/UbsbuTU",
    //   website: "",
    //   social: "",
    //   description: "ICHL is the Gold Standard for semi-competitive EASHL 3s and 6s gameplay on Xbox. Key features include: Salary system for roster balance, New player bidding every Friday, BO7 Playoffs, BO7 Championship, End of season awards."
    // },
    // {
    //   name: "VSHL (Virtual Sixes Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/bG3UTtS6wW",
    //   website: "",
    //   social: "",
    //   description: "New League with much experience in staff."
    // },
    // {
    //   name: "BLH (Bar League Hockey)",
    //   console: "Xbox One & Xbox Series X/S",
    //   format: "3s & 6s",
    //   discord: "https://discord.gg/MZzsRnaMb8",
    //   website: "https://sites.google.com/view/barleaguehockey/welcome",
    //   social: "https://www.Twitch.tv/flankenberry",
    //   description: "Bar League Hockey has been around for almost a year now! We are an Old Gen League, who are hoping to move to a New Gen league next year."
    // },
    // {
    //   name: "THL (Titan Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/dfFC5xqcHF",
    //   website: "",
    //   social: "",
    //   description: "A wide variety of skill level for 10pm & 11pm EST games. We are a new league and growing fast are accepting new people and are ready for you to join and start scouting."
    // },
    // {
    //   name: "UNHL (United Nations Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/AvZSDw7NGh",
    //   website: "",
    //   social: "",
    //   description: "A small but growing league that plays 2 games a night, Mon-Fri, at 9pm and 9:45pm EST."
    // },
    // {
    //   name: "OCHL (Online Collegiate Hockey League)",
    //   console: "Xbox",
    //   format: "6s",
    //   discord: "https://discord.gg/sX9BDejwey",
    //   website: "",
    //   social: "",
    //   description: "OCHL is a fun and competitive league with college based teams and rules that allows flexibility for players."
    // },
    // {
    //   name: "6HL (6s Hockey League)",
    //   console: "Xbox One & Xbox Series X/S",
    //   format: "6s",
    //   discord: "https://discord.gg/tt2XaUJq2J",
    //   website: "",
    //   social: "",
    //   description: "The 6s Hockey League (6HL) is currently taking sign-ups for Season 7. We promote a fun, friendly, and competitive playing environment."
    // },
    // {
    //   name: "E6XHL (Eastern 6s Xbox Hockey League)",
    //   console: "Xbox One",
    //   format: "3s",
    //   discord: "https://discord.gg/SrhGPG9Gpn",
    //   website: "",
    //   social: "",
    //   description: "We are a small league that is up and coming. We have draft on October 21. We are currently in S1 and a 2 week scouting period."
    // },
    // {
    //   name: "WGHL (World Gaming Hockey League)",
    //   console: "Xbox Series X/S",
    //   format: "6s",
    //   discord: "https://discord.gg/DYUXJrsR6y",
    //   website: "",
    //   social: "",
    //   description: "WGHL is a next-gen 6s league coming back for Season 4 we play Thu, Fri, Sat, Sun at 11 & 11:30 pm EST."
    // },
    // {
    //   name: "SDHL (Small Dekes Hockey League)",
    //   console: "Xbox One",
    //   format: "6s",
    //   discord: "https://discord.gg/KNy3H9R2e6",
    //   website: "",
    //   social: "",
    //   description: "SDHL is a weekend hockey league. Games are Saturday Sunday 9:00 and 9:45 EST."
    // },
    // {
    //   name: "W3HL (World 3s Hockey League)",
    //   console: "Xbox One",
    //   format: "3s",
    //   discord: "https://discord.gg/P65n6CMz",
    //   website: "https://www.mystatsonline.com/hockey/visitor/league/home/home_hockey.aspx?IDLeague=52965",
    //   social: "",
    //   description: "A brand new competitive league. Games from 9pm EST to as late as 11pm EST. 2 games each weekday for 2 months."
    // },
    // {
    //   name: "AXHL (American Xbox Hockey League)",
    //   console: "Xbox Series X/S & Xbox One",
    //   format: "6s",
    //   discord: "https://discord.gg/tgKHPKBf2Q",
    //   website: "https://www.theaxhl.com",
    //   social: "",
    //   description: "We are an BYOT style hockey league where you can choose from being either an NHL, AHL, CHL or College team."
    // },
    // {
    //   name: "MHL (Moose Hockey League)",
    //   console: "Xbox Series X/S",
    //   format: "6s",
    //   discord: "https://discord.gg/EgGBT2YJDt",
    //   website: "https://mhl.hockey/",
    //   social: "",
    //   description: "6s league that has been around since NHL 10 giving players a constant source for fun, competitive EASHL action."
    // },
    // {
    //   name: "TSHL (Top Shelf Hockey League)",
    //   console: "Xbox One",
    //   format: "3s",
    //   discord: "https://discord.gg/5ScxCxQaAC",
    //   website: "",
    //   social: "",
    //   description: "This is a brand new 3s league that we have been running. The skill is mediocre. Games are Monday-Friday 9 and 9:30pm EST."
    // },
    // {
    //   name: "GHL (Gamer's Hockey League)",
    //   console: "Xbox Series X/S",
    //   format: "6s",
    //   discord: "https://discord.gg/K6X4f3S2jS",
    //   website: "",
    //   social: "",
    //   description: "Inaugural season for the league. Looking to emphasize on everyone having a good time and being respectful both in game and online."
    // },
    // {
    //   name: "VHL (Vintage Hockey League)",
    //   console: "Xbox One/PS4",
    //   format: "6s",
    //   discord: "https://discord.gg/aQy3Y3aEGV",
    //   website: "https://www.mystatsonline.com/hockey/visitor/league/home/home_hockey.aspx?IDLeague=60935",
    //   social: "",
    //   description: "The VHL was established in May 2020. It's a bring-your-own-team format. Teams are made up of buddies."
    // },
    // {
    //   name: "SDHL (Small Dekes Hockey League)",
    //   console: "Xbox One",
    //   format: "6s",
    //   discord: "https://discord.gg/gmHzewR4",
    //   website: "",
    //   social: "",
    //   description: "Going into the 2nd season and this is a competitive league for all ages and skill levels."
    // },
    // {
    //   name: "ACHL (American Canadian Hockey League)",
    //   console: "Xbox Series X/S",
    //   format: "6s",
    //   discord: "https://discord.gg/YJyePERa4r",
    //   website: "",
    //   social: "",
    //   description: "Tuesday Wednesday Thursday 9 and 945 est games. Skill level is everywhere between LGTC to LGNHL players."
    // }
  ]
};

const LeagueTable = ({ leagues, consoleType }) => (
  <TableContainer component={Paper} elevation={3} >
    <Table aria-label={`${consoleType} leagues table`}>
      <TableHead>
        <TableRow>
          <TableCell>League</TableCell>
          <TableCell align="right">Console</TableCell>
          <TableCell align="right">Format</TableCell>
          <TableCell align="right">Discord</TableCell>
          <TableCell align="right">Website</TableCell>
          <TableCell align="right">Social Media</TableCell>
          <TableCell align="right">Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(leagues).map((league, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">{league.name}</TableCell>
            <TableCell align="right">{league.console}</TableCell>
            <TableCell align="right">{league.format}</TableCell>
            <TableCell align="right"><Link href={league.discord}>Discord</Link></TableCell>
            <TableCell align="right">{league.website ? <Link href={league.website}>Website</Link> : ''}</TableCell>
            <TableCell align="right">{league.social ? <Link href={league.social}>Social Media</Link> : ''}</TableCell>
            <TableCell align="right">{league.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const EASHLDirectory = () => {

  useEffect(() => {
    document.title = `Chel stats | League Directory`;
  }, []);

  return (
    <div style={{ margin: '20px', marginTop: 30 }}>
      <Typography variant="h4" gutterBottom component="div" sx={{ color: 'white' }}>
        EASHL League Directory
      </Typography>
      <Typography variant="h6" gutterBottom component="div" sx={{ color: 'white' }}>
        PlayStation Leagues
      </Typography>
      {/* <AdComponent slotID={"6496676229"} /> */}

      <LeagueTable leagues={data.PlayStation} consoleType="PlayStation" />
      {/* <Typography variant="h6" gutterBottom component="div" sx={{color: 'white', marginTop: 5}}>
          Xbox Leagues
        </Typography>
        <LeagueTable leagues={data.Xbox} consoleType="Xbox" /> */}
      {/* <AdComponent slotID={"2557431213"} /> */}

    </div>
  );
};

export default EASHLDirectory;
