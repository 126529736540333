import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';

function ClubsInfo({ crestID, clubData }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let imageUrls = [];
    if (crestID) {
        imageUrls = [
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png`,
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage, setCurrentImage] = useState(imageUrls[0]);

    const handleImageError = () => {
        const nextIndex = imageUrls.indexOf(currentImage) + 1;
        if (nextIndex < imageUrls.length) {
            setCurrentImage(imageUrls[nextIndex]);
        }
    };

    const winPerc = ((Number(clubData.team_stats.wins)/Number(clubData.team_stats.totalGames))*100).toFixed(1)
    const divURL = `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/divisioncrest/divisioncrest${clubData.teamData.currentDivision}.png`
    
    let atAGlanceStats = [
        { label: 'G', value: clubData.team_stats.goals},
        { label: 'GPG', value: clubData.team_stats.goals_per_game },
        { label: 'GA', value: clubData.team_stats.goalsAgainst },
        { label: 'GAPG', value: clubData.team_stats.goalsAgainst_per_game },
    ];

    return (
        <Box sx={{ 
            maxHeight: '500px', 
            width: '95%', 
            borderRadius: 5, 
            backgroundColor: '#171717', 
            display: 'flex', 
            flexDirection: 'column', 
            position: 'relative', 
            color: 'white', 
            marginTop: 8, 
            marginX: 'auto', 
            fontSize: 22,
            overflow: 'clip',
            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
            border: 'solid #656565 .001rem',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: windowWidth < 600 ? -60 : windowWidth < 900 ? 20 : 20,
                bottom: 0,
                backgroundImage: `url(${currentImage})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: windowWidth < 600 ? '75%' : windowWidth < 900 ? '30%' : '30%',
                opacity: 0.1, 
                zIndex: 2,
            }
        }}>
            <div style={{ zIndex: 10, marginTop: 15, marginLeft: 15,}}>
                <Typography sx={{ fontSize: 36, fontWeight: 750, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',}}>{clubData.teamData.clubInfo.name.toUpperCase()}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 6,zIndex: 10, marginTop: 10, marginLeft: 15,alignItems: 'center'}}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',}}>Division </Typography>
                <img style={{width: '40px', height: '40px'}} src={divURL}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10,zIndex: 10, marginTop: 5, marginLeft: 15,}}>
                <Typography sx={{ fontSize: 16, fontWeight: 500, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',}}>Current season: {Number(clubData.teamData.seasons) + 1}</Typography>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10,zIndex: 10, marginTop: 5, marginLeft: 15,alignItems: 'center'}}>
                <Typography sx={{ fontSize: 16, fontWeight: 600, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',}}>{clubData.teamData.record}, {winPerc}%</Typography>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 10,zIndex: 10, marginTop: 5, marginLeft: 15,}}>
                <Typography sx={{ fontSize: 16, fontWeight: 500, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',}}>Top100 rank: {clubData.top100Ranking}</Typography>

            </div>
            <img src={currentImage} onError={handleImageError} alt="" style={{ display: 'none' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', marginTop: 1 }}>
                        {atAGlanceStats.map((stat, index) => (
                            <React.Fragment key={stat.label}>
                                {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto',marginY: 'auto', height: 55 }} />}
                                <Box sx={{ padding: 2, paddingX: windowWidth < 600 ? 2 : windowWidth < 900 ? 5 : 5, textAlign: 'start' }}>
                                <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#a5a5a5', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                    {stat.label}
                                </Typography>
                                <Typography variant="h7" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                    {stat.value}
                                </Typography>
                                
                                </Box>
                            </React.Fragment>
                        ))}
                    </Box>
        </Box>
    );
}

export default ClubsInfo;
