import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

function ThreeStarsDisplay({ stars }) {
    if (!stars) return null;

    const getStarIcons = (count) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {[...Array(count)].map((_, i) => <StarIcon key={i} style={{ color: 'white' }} />)}
        </div>
    );

    return (
        <Grid container spacing={1} justifyContent="center">
            {stars.map((star, index) => (
                <Grid item key={index} xs={12} sm={4}>
                    <Card variant="outlined" sx={{ margin: 1, backgroundColor: '#171717', boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)', border: 'solid #656565 .001rem', }}>
                        <CardContent>
                            {getStarIcons(index + 1)}
                            <Typography variant="h6" sx={{color: 'white'}}>
                                {star.playerName}
                            </Typography>
                            <Typography sx={{color: 'white'}}>
                                Team: {star.teamName}
                            </Typography>
                            <Typography sx={{color: 'white'}}>
                                Score: {star.performanceScore.toFixed(1)}
                            </Typography>
                            <Typography sx={{color: 'white'}}>
                                Goals: {star.goals}
                            </Typography>
                            <Typography sx={{color: 'white'}}>
                                Assists: {star.assists}
                            </Typography>
                            {/* Add more stats here if necessary */}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ThreeStarsDisplay;
