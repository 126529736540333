import React from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';

// Styled progress bar
const RatingProgress = styled(LinearProgress)({
    height: '20px',
    borderRadius: '10px',
    '& .MuiLinearProgress-bar': {
        borderRadius: '10px',
        backgroundColor: '#2196f3', // Set your preferred filled color
        transition: 'none',
    },
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#eee', // Background color for unfilled part
    },
});

const OverallRatingBar = ({ rating, category }) => {
    const validRating = isFinite(rating) ? rating : 0;

    const normalizedRating = (validRating / 99) * 100; // Normalizing the rating to a percentage

    return (
        <Box sx={{ width: '100%', padding: '10px' }}>
            <Typography variant="h6" color="white" sx={{ mb: 1, fontSize: '14px' }}>
                {category} Rating
            </Typography>
            <Box position="relative" width="100%" height="20px">
                <RatingProgress variant="determinate" value={normalizedRating} />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pointerEvents: 'none' }}
                >
                    <Typography variant="caption" sx={{ color: 'black', fontWeight: 'bold', fontSize: '14px' }}>
                        {`${isFinite(rating) ? rating : 0}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default OverallRatingBar;
