import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AdComponent from './AdComponent';

function AboutPage() {
  const handleExploreStats = () => {
    navigate(`/players`);
  };

  const handleExploreClubStats = () => {
    navigate(`/clubs`);
  };

  const handleExploreEDGE = () => {
    navigate(`/clubs`);
  };

  const handleExploreChelHub = () => {
    navigate(`/chel-hub`);
  };
  const navigate = useNavigate();

  return (
    <Container>
      <Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
        <Typography variant="h6" color="white" fontWeight="800" gutterBottom> Unmatched Stats</Typography>

        <Typography variant="body1" color="white" paragraph>Explore advanced stats, recent performance metrics, and advanced overall ratings/rankings to help you gauge player effectiveness on the ice like never before. Club managers and players alike will benefit from our in-depth club statistics, including records, rosters, and recent games performance.</Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleExploreStats()}>Explore player stats</Button>
      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>

        <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Recent Game Insights and Analytics</Typography>

        <Typography variant="body1" color="white" paragraph>Experience games through scoring timelines that provide a simulated play-by-play breakdown of game events. Our platform highlights the top performers in each match with the 'three stars' feature and offers unique game write-ups powered by ChatGPT, enhancing your understanding and engagement with every match. The "Deserve to Win" scores provide a data-driven look at each team's performance, giving you insights beyond the scoreboard.</Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleExploreClubStats()}>Explore club stats</Button>

      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>

        <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Discord Integration and Community Features</Typography>

        <Typography variant="body1" color="white" paragraph>Log in effortlessly using our Discord integration, which allows you to save your favorite searches, declare yourself a free agent, or set up scouting preferences for your club. Our community features are designed to foster a connected and engaged user base, making it easier than ever to interact with other players and clubs. More features include connected discord channels within the website which allows for seemless community engagement</Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1, width: 'fit-content' }} href="https://discord.com/oauth2/authorize?client_id=1201905788386283570&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fauth%2Fdiscord%2Fcallback&scope=identify+guilds">Login</Button>

      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>

        <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Subscriptions</Typography>

        <Typography variant="body1" color="white" paragraph>Users who subscribe will gain unique abilities within our platform, such as ad disabling, queue HUT styled cards in the discord server that are based on your EASHL overall, the abilty to upload their highlights on discord and put themselves in the spotlight, and unique access to channels that allow for direct influence on the feature development of the platform</Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1, width: 'fit-content' }} href="https://buymeacoffee.com/chelstats/membership">Browse subscriptions</Button>

      </Box><Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>

        <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Chel Stats | EDGE</Typography>

        <Typography variant="body1" color="white" paragraph>Leagues looking to elevate their game will find a powerful ally in Chel Stats | EDGE, our brand-new, state-of-the-art automated API. Designed specifically for the EASHL community, Chel Stats | EDGE revolutionizes league management and enhances the overall league experience by integrating advanced statistical analysis.</Typography>
        {/* <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleExploreEDGE()}>Chel stats | EDGE</Button> */}

      </Box>

      <Box my={2} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>

        <Typography variant="h6" color="white" fontWeight="800" gutterBottom>A Platform for Creators</Typography>

        <Typography variant="body1" color="white" paragraph>Check out our content creator page, where influencers and fans contribute to the vibrant EASHL community. Whether you're looking to catch up on the latest strategies or find entertaining gameplay, our platform supports and promotes content creators dedicated to enriching the EASHL community.</Typography>
        <Button variant="contained" color="primary" sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 1 }} onClick={() => handleExploreChelHub()}>Chel Hub</Button>

      </Box>
    </Container>
  );
}

export default AboutPage;
