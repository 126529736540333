import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Container, Divider, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useStats } from '../../Contexts/StatsContext';
import SearchAndDropdownClubs from '../../Main Components/SearchAndDropdownClubs';
import ClubOptions from './ClubOptions';
import { useUser } from '../../Contexts/UserContext';
import { useLoading } from '../../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import SetClub from './SetClub';
import { refreshUserData } from '../Players/PlayerStats';
import VignetteAdComponent from '../../Main Components/VignetteAdComponent';
import slapShotSound from './Slap-shot.mp3'; // path to your sound file
import goalHornSound from './Goal-horn.mp3'; // path to your sound file
import ooohhSound from './oohhh.mp3'; // path to your sound file
import cheerSound from './cheer.mp3'; // path to your sound file
import postSound from './post.mp3'; // path to your sound file
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import useSound from 'use-sound';
import './scoreOn.css';
import ClubSelectionDialog from './ClubSelectionDialog'
function ClubStats() {
    const [clubId, setClubId] = useState('');
    const [teamname, setTeamName] = useState('');
    const [consolegen, setConsolegen] = useState('');
    const [teamMembers, setTeamMembers] = useState('');
    const dataFetched = useRef(false);
    const { dispatch } = useStats();
    const { state } = useStats();
    const [clubClaimed, setClubClaimed] = useState(false);
    const [hasClaimedClub, setHasClaimedClub] = useState(false);
    const [userInClub, setuserInClub] = useState(false);
    const { loading, setLoading } = useLoading();
    const { user, setUser } = useUser();
    const [clubData, setClubData] = useState(null);
    const location = useLocation();
    const [refreshKey, setRefreshKey] = useState(0);
    const [initialLoad, setInitialLoad] = useState(true);
    const [scoutingInfo, setScoutingInfo] = useState('');
    const [clubInfo, setClubInfo] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [scoredOnCount, setScoredOnCount] = useState(0);
    const [openClubSelection, setOpenClubSelection] = useState(false);
    const [matchingTeams, setMatchingTeams] = useState([]);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarOpen2, setSnackbarOpen2] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [params, setParams] = useState({});
    const [transState, setTransState] = React.useState({
        open: false,
        Transition: Slide,
    });
    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (clubData) {
            document.title = `Chel stats | Club stats`;
        }
    }, []);

    const processClubData = (data) => {
        setClubData(data);
        setClubId(data.teamData.clubId);
        getClubScouting(data.teamData.clubId);
        getClubInfo(data.teamData.clubId);
        setTeamName(data.teamData.name)
        let clubMembers = [];
        const clubDataMembers = data.memberData;
        for (var key in clubDataMembers) {
            clubMembers.push(clubDataMembers[key].Username);
        }

        setTeamMembers(clubMembers);
    };

    const fetchClubData = async (forceRefresh = false) => {
        if (forceRefresh) {
            setRefreshKey(prevKey => prevKey + 1); // Increment to trigger useEffect
            return; // Early return; let useEffect handle the actual fetching
        }
        setLoading(true);
        const queryParams = new URLSearchParams(location.search);
        const teamNameParam = queryParams.get('teamname');
        const consoleGenParam = queryParams.get('console');

        let apiUrl = '/api/clubs/stats';
        let queryParts = [];

        if (teamNameParam) {
            queryParts.push(`teamname=${encodeURIComponent(teamNameParam)}`);
        }
        if (consoleGenParam) {
            queryParts.push(`console=${encodeURIComponent(consoleGenParam)}`);
        }

        // Construct the full API URL with query parameters if available
        if (queryParts.length) {
            apiUrl += `?${queryParts.join('&')}`;
        }
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');

            }
            const data = await response.json();

            if (data.matchingTeams) {
                setOpenClubSelection(true); // Assuming you have a state to control the dialog visibility
                setMatchingTeams(data.matchingTeams); // Assuming you have a state to store the matching teams
                return; // Stop further execution until a club is selected
            }

            processClubData(data);

        } catch (error) {
            console.error('Failed to fetch club data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClubSelect = async (club) => {
        if (club) {
            setLoading(true);
            setError('');

            const queryParams = new URLSearchParams(location.search);
            const teamNameParam = queryParams.get('teamname');
            const consoleGenParam = queryParams.get('console');
            const teamIdParam = club.clubId;

            let apiUrl = '/api/clubs/stats';
            let queryParts = [];

            if (teamNameParam) {
                queryParts.push(`teamname=${encodeURIComponent(teamNameParam)}`);
            }
            if (consoleGenParam) {
                queryParts.push(`console=${encodeURIComponent(consoleGenParam)}`);
            }
            if (teamIdParam) {
                queryParts.push(`teamId=${encodeURIComponent(teamIdParam)}`);
            }

            // Construct the full API URL with query parameters if available
            if (queryParts.length) {
                apiUrl += `?${queryParts.join('&')}`;
            }
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');

                }
                const data = await response.json();

                if (data.matchingTeams) {
                    setOpenClubSelection(true); // Assuming you have a state to control the dialog visibility
                    setMatchingTeams(data.matchingTeams); // Assuming you have a state to store the matching teams
                    return; // Stop further execution until a club is selected
                }

                processClubData(data);

            } catch (error) {
                console.error('Failed to fetch club data:', error);
                setError(`Can't find stats for ${queryParams.get('teamname')}`);

            } finally {
                setLoading(false);
            }  // Assume this function processes and sets state related to the selected club
        }
        setOpenClubSelection(false);  // Close the selection dialog
    };

    const handleConsolegenChange = (newConsolegen) => {
        setConsolegen(newConsolegen);
    };

    async function getClubScouting(teamID) {
        try {
            const response = await fetch(`/api/club/${teamID}/scouting`, { credentials: 'include' });
            if (response.ok) {
                const scouting = await response.json();
                setScoutingInfo(scouting);
            }
        } catch (error) {
            console.error('Failed to get club scouting data:', error);
        }
    };

    async function getClubInfo(teamID) {
        try {
            const response = await fetch(`/api/club/${teamID}/info`, { credentials: 'include' });
            if (response.ok) {
                const clubInfo = await response.json();
                setClubInfo(clubInfo);
            }
        } catch (error) {
            console.error('Failed to get club scouting data:', error);
        }
    };


    useEffect(() => {
        if (location.search && !dataFetched.current) {
            fetchClubData();
            dataFetched.current = true;
        }
    }, [location.search, refreshKey]);

    useEffect(() => {
        if (user) {
            fetchClaimedClubStatus();
        }
    }, [user]);

    useEffect(() => {
        const checkClubClaimed = () => {
            if (!user || !clubData) return false;
            return user.claimedClub && user.claimedClub.clubId === clubData.teamData.clubId;
        };

        setClubClaimed(checkClubClaimed());
    }, [user, clubData]);

    useEffect(() => {
        if (user && initialLoad) {
            refreshUserData(setUser, setLoading).then(() => setInitialLoad(false));
        }
    }, [user, initialLoad]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setTeamName(queryParams.get('teamname') || '');
        const consoleQueryParam = queryParams.get('console');
        if (consoleQueryParam) {
            handleConsolegenChange(consoleQueryParam);
        }
    }, [location.search]);

    useEffect(() => {
        async function fetchScoredOnCount() {
            try {
                const response = await fetch(`/api/getscoredoncount/${clubId}`, { method: 'GET', credentials: 'include' });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setScoredOnCount(data.scoredOn);
            } catch (error) {
                console.error('Error sending bodycheck request:', error);
            }
        }

        if (clubId) { // Ensure username is not empty before making the request
            fetchScoredOnCount();
        }

    }, [setScoredOnCount, clubId]);

    const fetchClaimedClubStatus = async () => {
        try {
            const response = await fetch('/api/club/claimed-club-status');
            const data = await response.json();
            if (response.ok) {
                setHasClaimedClub(data.hasClaimedClub);
            } else {
                // Handle failure (optional)
                console.error('Failed to fetch claim status');
            }
        } catch (error) {
            console.error('Error fetching claim status:', error);
        }
    };


    const isCurrentClubClaimed = () => {
        if (!user || !clubData) return false;
        const hasClaimedData = user.claimedClub && (
            user.claimedClub.clubId
        );

        if (!hasClaimedData) {
            return false;
        }

        const clubId = clubData.teamData.clubId;
        const userClubId = user.claimedProfile.clubId;

        return userClubId === clubId;
    };

    const isUserInClub = () => {

        if (!user || !clubData) return false;
        let clubMembers = []
        const clubDataMembers = clubData.memberData;

        for (var key in clubDataMembers) {
            clubMembers.push(clubDataMembers[key].Username)
        }

        const userUsername = user.claimedProfile.username;
        const areThey = clubMembers.includes(userUsername)
        return areThey;
    };

    const showClaimButton = user && !hasClaimedClub && isUserInClub();
    const showUnclaimButton = user && isCurrentClubClaimed();
    const showOptionsButton = user && isCurrentClubClaimed();

    const handleUnclaimClub = async () => {
        try {
            const response = await fetch('/api/user/unclaim-club', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any needed headers, like authentication tokens
                },
                credentials: 'include', // Needed if you're using sessions
            });
            const data = await response.json();
            if (response.ok) {
                // Assuming setUser is a method provided by your context to update the user state
                setUser({ ...user, claimedClub: null }); // Update user state to reflect the unclaimed profile
                console.log(data.message);
                refreshUserData(setUser);// Optional: log success message
            } else {
                throw new Error(data.message || 'Failed to unclaim club');
            }
        } catch (error) {
            console.error('Error unclaiming profile:', error.message);
        }
    };

    const handleClaimClub = async (clubId, teamname, consolegen) => {
        // Example details, replace with actual data as needed
        const clubDetails = { clubId, teamname, consolegen };
        try {
            const response = await fetch('/api/user/claim-club', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(clubDetails),
            });
            if (response.ok) {
                // Update local state or inform the user of success
                setHasClaimedClub(true);
                const updatedUser = { ...user, claimedClub: { clubId } };
                setUser(updatedUser);
                refreshUserData(setUser);
            } else {
                // Handle errors or failure
                console.error('Failed to claim club');
            }
        } catch (error) {
            console.error('Error claiming club:', error);
        }
    };

    const handleSaveOptions = (options) => {
        // Here, you would update the user's settings in your state or make an API call
        console.log(options);
    };

    useEffect(() => {
        const urlParams = getUrlParams();
        setParams(urlParams);

        // Check if the club search is already saved
        const savedClub = localStorage.getItem(`club_${urlParams['teamname']}_${urlParams['console']}`);
        setIsSaved(!!savedClub);
    }, []);

    const getUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        const paramsObject = {};
        params.forEach((value, key) => {
            paramsObject[key] = value;
        });
        return paramsObject;
    };

    // Function to save URL parameters to local storage
    const handleSave = () => {
        const clubParams = { teamname: params['teamname'], console: params['console'] };
        localStorage.setItem(`club_${params['teamname']}_${params['console']}`, JSON.stringify(clubParams));
        setIsSaved(true);
        setSnackbarMessage('Search saved');
        setSnackbarOpen2(true);
    };
    const handleUnsave = () => {
        if (isSaved) {
            localStorage.removeItem(`club_${params['teamname']}_${params['console']}`);
            setIsSaved(false);
            setSnackbarMessage('Saved search removed');
            setSnackbarOpen2(true);
        }
    };

    const handleCloseSnackbar2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen2(false);
    };

    const handleShareClub = () => {
        // Copy the current URL to the clipboard
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                // You can display a message to the user indicating the link was successfully copied
                console.log('Club link copied to clipboard!');
            })
            .catch(err => {
                // Handle any errors
                console.error('Failed to copy the club link:', err);
            });
        setSnackbarOpen(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const [shake, setShake] = useState(false);
    const [playShot] = useSound(slapShotSound);
    const [playGoal] = useSound(goalHornSound);
    const [playCheer] = useSound(cheerSound);
    const [playPost] = useSound(postSound);
    const [playOhhh] = useSound(ooohhSound);

    const triggerShot = async () => {
        // Randomly determine if the shot is a goal
        const isGoal = Math.random() < .3;  // Adjust the probability as needed, here it's 50%
        playShot()
        if (isGoal) {
            // Play goal sound if the shot is a goal
            setShake(true);
            // Turn off shake after the animation duration
            setTimeout(() => setShake(false), 8000);
            playGoal();  // Replace with your actual function to play the goal sound
            playCheer()
            // Duration of the animation
        } else {
            const isPost = Math.random() < 0.4;  // Adjust the probability as needed, here it's 50%

            if (isPost) {
                setTimeout(() => playOhhh(), 1000);
                setTimeout(() => playPost(), 1000);
            } else {
                setTimeout(() => playOhhh(), 800);
            }
        }



        if (isGoal) {
            try {
                const response = await fetch(`/api/scoreOn/${clubId}`, { method: 'POST', credentials: 'include' });
                const data = await response.json();
                if (response.ok) {
                    setScoredOnCount(data.scoredOn);
                } else {
                    console.error('Failed to score on:', data);
                }
            } catch (error) {
                console.error('Error sending score on request:', error);
            }
        }
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><PropagateLoader color="#3498db" /></div>;
    }

    return (
        <div className={shake ? 'goal-scored' : ''}>
            <Container>
                <SearchAndDropdownClubs
                    teamname={teamname}
                    setTeamName={setTeamName}
                    setConsolegen={setConsolegen}
                    onConsolegenChange={handleConsolegenChange}
                    fetchClubData={fetchClubData}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                />
                {clubData && (
                    <>
                        {showClaimButton && (
                            <Button sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }} onClick={() => handleClaimClub(clubId, teamname, consolegen)}>Claim Club</Button>
                        )}
                        {showUnclaimButton && (
                            <Button sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }} onClick={handleUnclaimClub}>Unclaim Club</Button>
                        )}
                        {showOptionsButton && (
                            <ClubOptions onSave={handleSaveOptions} clubId={clubId} roster={teamMembers} />
                        )}
                        <Button
                            onClick={triggerShot}
                            sx={{
                                marginTop: 2,
                                marginRight: 1,
                                paddingLeft: 2,
                                paddingRight: 2,
                                backgroundColor: '#1f1f1f',
                                borderRadius: '20px',
                                color: 'white',
                                fontWeight: '300',
                                fontSize: '12px'
                            }}
                        >
                            Score on {scoredOnCount ? `(${scoredOnCount})` : ''}
                        </Button>
                    </>
                )}
                <Button
                    sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                    onClick={handleShareClub}
                >
                    Share
                </Button>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={2400} // Snackbar will close after 6000ms
                    onClose={handleCloseSnackbar}
                    TransitionComponent={state.Transition}
                    message="URL copied"
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: '#1976d2', // Snackbar background color
                            color: '#fff' // Text color
                        }
                    }}
                    action={
                        <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                            Close
                        </Button>
                    }
                />
                <Button
                    sx={{ marginTop: 2, marginRight: 1, paddingLeft: 2, paddingRight: 2, backgroundColor: '#1f1f1f', borderRadius: '20px', color: 'white', fontWeight: '300', fontSize: '12px' }}
                    onClick={isSaved ? handleUnsave : handleSave}
                >
                    {isSaved ? 'Unsave search' : 'Save search'}
                </Button>

                <Snackbar
                    open={snackbarOpen2}
                    autoHideDuration={2400} // Snackbar will close after 6000ms
                    onClose={handleCloseSnackbar2}
                    TransitionComponent={state.Transition}
                    message={snackbarMessage}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: '#1976d2', // Snackbar background color
                            color: '#fff' // Text color
                        }
                    }}
                    action={
                        <Button color="inherit" size="small" onClick={handleCloseSnackbar2}>
                            Close
                        </Button>
                    }
                />


                <div style={{ marginTop: 30 }}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div style={{ color: 'white' }}>{error}</div> // Display the error message
                    ) : (
                        <div>
                            {/* Your existing player data display logic here */}
                        </div>
                    )}
                </div>
                <SetClub clubData={clubData} scoutingInfo={scoutingInfo} clubInfo={clubInfo} team={teamname} consolegen={consolegen} />
                <ClubSelectionDialog
                    open={openClubSelection}
                    clubs={matchingTeams}
                    onSelect={handleClubSelect}
                />
            </Container>
        </div>
    );
}

export default ClubStats;