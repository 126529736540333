import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useUser } from '../Contexts/UserContext';
function AuthButton() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, setUser } = useUser();
  useEffect(() => {
    fetch('/api/auth/session')
      .then(response => response.json())
      .then(data => setIsLoggedIn(data.isLoggedIn))
      .catch(error => console.error('Error checking login session:', error));
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include', // Important if sessions are used
      });
      if (response.ok) {
        setUser(null); // Update user state to reflect logout
        fetch('/api/auth/session')
          .then(response => response.json())
          .then(data => setIsLoggedIn(data.isLoggedIn))
          .catch(error => console.error('Error checking login session:', error));
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <Button fullWidth
          variant="outlined" sx={{ color: 'white' }} onClick={handleLogout}>Logout</Button>
      ) : (
        <Button fullWidth
          variant="outlined" sx={{ color: 'white' }} onClick={() => {
            window.location.href = "https://discord.com/oauth2/authorize?client_id=1201905788386283570&response_type=code&redirect_uri=https%3A%2F%2Fwww.chelstats.app%2Fauth%2Fdiscord%2Fcallback&scope=identify+guilds";
          }}>Login</Button>
      )}
    </div>
  );
}

export default AuthButton;
