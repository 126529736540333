import React, { useState, useEffect, useMemo } from 'react';
import { PropagateLoader } from 'react-spinners';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Box
} from '@mui/material';

const DIVISION_NAMES = ['Atlantic Division', 'Metropolitan Division', 'Central Division', 'Pacific Division'];

function CNCStandings() {
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'POINTS',
    direction: 'desc'
  });

  useEffect(() => {
    document.title = "Chel stats | CNC Standings";
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchSheetData = async () => {
      try {
        const response = await fetch(`/api/fetch/cnc-standings`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let data = await response.json();
        // Assume the server does the slicing and processing, so you might not need to do it here again
        setRawData(data); // Adjust according to how data is structured
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch sheet data:', error);
        setLoading(false);
      }
    };
  
    fetchSheetData();
  }, [setLoading]);
  

  const handleSort = (key) => {
    const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
    setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
  };

  const sortedData = useMemo(() => {
    if (!rawData) return null;
    return rawData.map(data => {
      const columnIndex = data[0]?.indexOf(sortConfig.key);
      if (columnIndex > -1) {
        return [
          data[0],
          ...data.slice(1).sort((a, b) => {
            if (sortConfig.key === 'RECORD AGAINST >.500') {
              const extractWins = (record) => {
                const match = record.match(/^\((\d*)/);
                return match ? parseInt(match[1], 10) : 0;
              };
              const aValue = extractWins(a[columnIndex]);
              const bValue = extractWins(b[columnIndex]);
              return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
            } else {
              const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
              const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];
              return (aValue < bValue ? -1 : 1) * (sortConfig.direction === 'asc' ? 1 : -1);
            }
          }),
        ];
      }
      return data;
    });
  }, [rawData, sortConfig]);



  if (loading) {
    return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
  }

  if (!rawData) {return null} else {if (rawData.length === 0) {return null}};

  return (
    <Box sx={{ maxWidth: '90%', m: 'auto', alignItems: 'center' }}>
      {sortedData.map((data, divisionIndex) => (
        <React.Fragment key={divisionIndex}>
          <Typography variant="h6" sx={{ m: 'auto',marginTop: 4,marginBottom: 2, color: 'white' }}>
          {DIVISION_NAMES[divisionIndex]} Standings
          </Typography>
          <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#1C4587' }}>
                  {data[0].map((header, index) => (
                    <TableCell
                      key={header}
                      align="center"
                      sx={{
                        backgroundColor: '#1C4587',
                        color: 'white',
                        p: '8px',
                        whiteSpace: 'nowrap',
                        borderRight: index === data[0].length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig.key === header}
                        direction={sortConfig.key === header ? sortConfig.direction : 'asc'}
                        onClick={() => handleSort(header)}
                      >
                        {header}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex} align="center" sx={{ fontWeight: '500', borderRight: rowIndex === data.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)' }}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ))}
    </Box>
  );
}

export default CNCStandings;
