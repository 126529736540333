import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Link } from '@mui/material';

const DiscordMessages = ({ channelId }) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        async function fetchMessages() {
            const response = await fetch(`http://localhost:5000/messages/${channelId}`);
            const data = await response.json();
            setMessages(data);
        }
        fetchMessages();
    }, [channelId]);

    const getYoutubeId = url => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const getTikTokId = url => {
        const regExp = /tiktok\.com\/@[^\/]+\/video\/(\d+)/;
        const match = url.match(regExp);
        return match ? match[1] : null;
    };

    return (
        <Box sx={{ margin: 4, bgcolor: 'background.paper' }}>
            <Typography variant="h4" gutterBottom>Messages from Discord</Typography>
            {messages.map(msg => {
                const youtubeId = getYoutubeId(msg.content);
                const tikTokId = msg.tikTokId || getTikTokId(msg.content);

                return (
                    <Card key={msg.id} sx={{ maxWidth: '100%', my: 2 }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {msg.author}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {msg.content.replace(/https:\/\/[\n\S]+/g, '')}
                            </Typography>
                        </CardContent>
                        {msg.images && msg.images.map((image, index) => (
                            <CardMedia
                                key={index}
                                component="img"
                                image={image}
                                alt="Discord attachment"
                                sx={{ maxWidth: '100%', height: 'auto' }}
                            />
                        ))}
                        {youtubeId && (
                            <CardMedia
                                component="iframe"
                                height="194"
                                src={`https://www.youtube.com/embed/${youtubeId}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        )}
                        {tikTokId && (
                            <CardMedia
                                component="iframe"
                                height="500"
                                src={`https://www.tiktok.com/embed/${tikTokId}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        )}
                        {msg.videos && msg.videos.map((video, index) => (
                            <video key={index} controls src={video} style={{ width: '100%' }} />
                        ))}
                        {msg.links && msg.links.map((link, index) => (
                            <Typography key={index}>
                                <Link href={link} target="_blank" rel="noopener noreferrer">{link}</Link>
                            </Typography>
                        ))}
                    </Card>
                );
            })}
        </Box>
    );
};

export default DiscordMessages;
