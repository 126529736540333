import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, TextField, TablePagination } from '@mui/material';

const SheetData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/sheet-data');
        const sheetData = response.data.data.slice(1);
        setData(sheetData);
        setFilteredData(sheetData);
        setTimeLeft(Math.ceil(response.data.timeRemaining / 1000)); // Reset countdown after data fetch
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000); // Fetch data every 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft(prev => (prev > 0 ? prev - 1 : 30));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    setFilteredData(data.filter(row => row[0] && row[0].toLowerCase().includes(filter.toLowerCase())));
  }, [filter, data]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box sx={{ maxWidth: '95%', m: 'auto', alignItems: 'center' }}>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6" sx={{ marginTop: 4, color: 'white' }}>
            WOHL Bidding
          </Typography>
          <Button variant="h6" sx={{ pointerEvents: 'none', backgroundColor: '#1C4587', marginTop: 4, color: 'white' }}>
            Updating in: {timeLeft} seconds
          </Button>
        </Box>
        <TextField
          label="Filter by Username"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          sx={{
            marginTop: 2, marginBottom: 0.5, width: 200, input: { color: 'white' }, // Text color
            label: { color: 'gray' }, // Label color when not focused
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'gray' }, // Border color
              '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
              '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
            },
            '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
          }}
          freeSolo // Allows arbitrary input not limited to the options
          componentsProps={{
            clearIndicator: {
              style: {
                color: 'gray', // Change to your desired color
              },
            },
          }}
        />
        <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 5, position: 'relative', overflowX: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#1C4587' }}>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Gamertag</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Platform</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Availability</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Primary Position</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Current Bid</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Timestamp</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Time Remaining on Bid</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Bid Ended</TableCell>
                <TableCell sx={{ backgroundColor: '#1C4587', color: 'white', p: '2px', paddingLeft: '6px', textAlign: 'center', whiteSpace: 'nowrap' }}>Winning Team</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index} sx={{ backgroundColor: row[10] === 'YES' ? 'lightgreen' : 'inherit' }}>
                    <TableCell>{row[0]}</TableCell>
                    <TableCell>{row[1]}</TableCell>
                    <TableCell>{row[2]}</TableCell>
                    <TableCell>{row[3]}</TableCell>
                    <TableCell>{row[5]}</TableCell>
                    <TableCell>{row[6]}</TableCell>
                    <TableCell>{row[8]}</TableCell>
                    <TableCell>{row[10]}</TableCell>
                    <TableCell>{row[12]}</TableCell>
                    {/* Add more columns as needed */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </div>
  );
};

export default SheetData;
