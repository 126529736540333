import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function NAEHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/NAEHL_Logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={logoPath} alt={`NAEHL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to NAEHL!
                    </Typography>
                </Box>

            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: .2 }}>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for NAEHL!
                </Typography>
                <Button variant="contained" color="primary" href="/NAEHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard Playoffs
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{ marginLeft: 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'malmo-redhawks'} text={'Malmo Redhawks'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'lahti-pelicans'} text={'Lahti Pelicans'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'dynamo-parbudice'} text={'Dynamo Parbudice'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'red-bull-munchen'} text={'Red Bull Munchen'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'adler-mannheim'} text={'Adler Mannheim'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'straubing-tigers'} text={'Straubing Tigers'} league={'NAEHL'} />

                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'geneve-servette-hc'} text={'Geneve Servette HC'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'iserlohn-roosters'} text={'Iserlohn Roosters'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'farjestad-bk'} text={'Farjestad BK'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'skelleftea-aik'} text={'Skelleftea AIK'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'stavanger-oilers'} text={'Stavanger Oilers'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'krefeld-pinguine'} text={'Krefeld Pinguine'} league={'NAEHL'} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default NAEHL_Home;