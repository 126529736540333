import React, { useEffect } from 'react';
import { Container, Typography, Link, Paper, List, ListItem } from '@mui/material';
import AdComponent from './AdComponent';

const ChangeLog = () => {
    const changes = [
        {
            date: "May 22, 2024",
            content: [
                "Added the ability for any user to save player/club searches for quick access from the home page",
                "Added button links for club navigation from the recent game pages",
                "Moved the home pages about info to it's own separate page"
            ],
        },
        {
            date: "April 29, 2024",
            content: [
                "Chel stats 3.0!",
                "Welcome to the 3rd rendition of Chel stats, where I've completely rewrote the website using the React framework. This change allows for an incredible upgrade to responsiveness in terms of functionality and design as well as a long list of improvements as described below:",
                "Added completely new design/styling using MUI",
                "All data is no longer stored in local storage and loads directly from taking URL parameters and completely refreshes on each page load. This also means links are sharable and direct users to specific player/club stat endpoints.",
                "Updated stat viewing components across the board",
                "Added the ability to login with Discord which provides a plethora of new features such as: Saving stat profiles, utilizing discord IDs to direct users to contact other users, discord server message extraction (for highlights/Imagine not scoring there pages), role identication (For disabling ads)",
                "Added overall rating rankings to the radar chart, as well as website-wide comparisons, and recent performance comparisons",
                "Added the ability for users to set their saved profile as a free agent to be viewable in the free agents page",
                "Added the ability for clubs to set their scouting preferences to be viewable in the scouting page, as well as the abiity to link their discord servers for users to join as they please",
                "Added new features for recent game viewing like: ChatGPT generated game writeups, simulated scoring timelines, and three stars",
                "Chel stats | EDGE, a brand new API for leagues featuring advanced statistics like WAR, makes it's way onto the site where it provides league statistic hosting for many of the top leagues around. Feel free to explore and join leagues in the league directory!",

            ],
        },
        // More entries here...
    ];

    useEffect(() => {
        document.title = `Chel stats | Change Log`;
    }, []);

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: 'white', marginY: 5 }}>
                Change Log
            </Typography>
            {changes.map((change, index) => (
                <Paper key={index} elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <Typography variant="h6">{change.date}</Typography>
                    {change.content.map((item, idx) => (
                        <Typography key={idx} paragraph>
                            - {item}
                        </Typography>
                    ))}
                </Paper>
            ))}

        </Container>
    );
};

export default ChangeLog;
