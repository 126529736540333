import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useStats } from '../../Contexts/StatsContext';

function PlayerInfo({ crestID, playerData, position, skaterOverall, goalieOverall, playstyle, clubInfo, jersey }) {
    const { state } = useStats();
    const { positionState } = state;
    const [roleLetter, setRoleLetter] = useState('')
    const [jerseyNum, setJerseyNum] = useState('')

    let imageUrls = [];
    if (crestID) {
        imageUrls = [
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png`,
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`,
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'
        ];
    } else {
        imageUrls = ['https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png'];
    }

    const [currentImage, setCurrentImage] = useState(imageUrls[0]);

    const handleImageError = () => {
        const nextIndex = imageUrls.indexOf(currentImage) + 1;
        if (nextIndex < imageUrls.length) {
            setCurrentImage(imageUrls[nextIndex]);
        }
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    let atAGlanceStats = [];
    let overall_rating = ""
    let playStyle = ""
    let Position = ""
    let record = ""
    let winPerc = ""
    if (positionState === 'skater') {
        atAGlanceStats = [
            { label: 'GP', value: playerData["Games Played"] },
            { label: 'G', value: playerData.Goals },
            { label: 'A', value: playerData.Assists },
            { label: 'P', value: playerData.Points },
        ];

        playStyle = (
            <span>
                <span>&#8226;</span> {playstyle}
            </span>
        );
        overall_rating = skaterOverall
        Position = position
        record = playerData.Record
        winPerc = playerData["Win %"]

    } else if (positionState === 'goalie') {
        atAGlanceStats = [
            { label: 'GGP', value: playerData["Goalie games played"] },
            { label: 'GAA', value: playerData.GAA },
            { label: 'SV%', value: playerData["Save %"] },
            { label: 'SO', value: playerData.Shutouts },
        ];

        playStyle = ""
        overall_rating = goalieOverall
        Position = "G"
        record = playerData["Goalie record"]
        winPerc = String(((parseInt(playerData["Goalie wins"]) / parseInt(playerData["Goalie games played"])) * 100).toFixed(1))
    }

    useEffect(() => {
        async function getRoleLetter(info, username) {
            if (username === info.captain) {
                setRoleLetter("'C'"); // Captain
            } else if (username === info.alternateCaptain1 || username === info.alternateCaptain2) {
                setRoleLetter("'A'"); // Alternate Captain
            } else {
                setRoleLetter(''); // No special role
            }
        }

        if (clubInfo) {
            getRoleLetter(clubInfo.info, playerData.Username);
        }

        if (jersey) {
            setJerseyNum(jersey ? `&#8226; #${jersey}` : '');
        }

    }, [setRoleLetter, clubInfo, jersey]);


    return (
        <Box sx={{
            maxHeight: '500px',
            width: '95%',
            borderRadius: 5,
            backgroundColor: '#171717',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            color: 'white',
            marginTop: 8,
            marginX: 'auto',
            fontSize: 22,
            overflow: 'clip',
            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.8)',
            border: 'solid #656565 .001rem',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: windowWidth < 600 ? -60 : windowWidth < 900 ? 20 : 20,
                bottom: 0,
                backgroundImage: `url(${currentImage})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: windowWidth < 600 ? '75%' : windowWidth < 900 ? '30%' : '30%',
                opacity: 0.165,
                zIndex: 2,
            }
        }}>
            <div style={{ zIndex: 10, marginTop: 15, marginLeft: 15, }}>
                <Typography sx={{ fontSize: 36, fontWeight: 750, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData.Username.toUpperCase()} {roleLetter}</Typography>
                <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{playerData['[object Object]'].teamName.toUpperCase()} <span>&#8226;</span> {Position} <span dangerouslySetInnerHTML={{ __html: jerseyNum }}></span></Typography>
                <Typography sx={{ marginTop: .4, fontSize: 16, fontWeight: 450, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{overall_rating} OVR {playStyle}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, zIndex: 10, marginTop: 4, marginLeft: 15, alignItems: 'center' }}>
                <Typography sx={{ fontSize: 13, fontWeight: 600, fontStyle: 'italic', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', }}>{record}, {winPerc}%</Typography>
            </div>
            <img src={currentImage} onError={handleImageError} alt="" style={{ display: 'none' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', marginTop: 1 }}>
                {atAGlanceStats.map((stat, index) => (
                    <React.Fragment key={stat.label}>
                        {index > 0 && <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'grey.400', marginX: 'auto', marginY: 'auto', height: 55 }} />}
                        <Box sx={{ padding: 2, paddingX: windowWidth < 600 ? 2 : windowWidth < 900 ? 5 : 5, textAlign: 'start' }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#e1e1e1', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.label}
                            </Typography>
                            <Typography variant="h7" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'protipo-narrow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                {stat.value}
                            </Typography>

                        </Box>
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
}

export default PlayerInfo;
