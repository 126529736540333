import React, { useState } from 'react';
import { Box } from '@mui/material';

function TeamLogo({ crestID }) {
    // Array of URLs in the order you want to try them: original first, then fallbacks
    let imageUrls = []
    if (crestID) {
        imageUrls = [
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/custom-crests/${crestID}.png`,
            `https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t${crestID}.png`, // First fallback image
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png' // Second fallback image
            // You can add more fallback images if needed
        ];
    } else {
        imageUrls = [
            'https://media.contentapi.ea.com/content/dam/eacom/nhl/pro-clubs/crests/t1000.png' // Second fallback image
            // You can add more fallback images if needed
        ];
    }

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleImageError = () => {
        // Move to the next image URL in the list, if available
        if (currentImageIndex < imageUrls.length - 1) {
            setCurrentImageIndex(prevIndex => prevIndex + 1);
        }
    };

    return (
        <Box
            sx={{
                margin: 'auto',
                width: 50,
                height: 50,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img
                src={imageUrls[currentImageIndex]}
                onError={handleImageError}
                alt="Team Crest"
                style={{
                    width: '100%', // Ensure the image covers the Box area
                    height: '100%',
                    objectFit: 'cover', // This will cover the area without stretching the image
                }}
            />
        </Box>
    );
}

export default TeamLogo;