import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Link, Avatar, Button } from '@mui/material';
import AdComponent from '../Main Components/AdComponent';

const HighlightReel = ({ channelId }) => {
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        document.title = `Chel stats | Highlight Reel`;
    }, []);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        async function fetchMessages() {
            const response = await fetch(`/api/threads/${channelId}`);
            if (response.ok) {
                const data = await response.json();
                setMessages(data.map(message => ({
                    ...message,
                    reactions: message.reactions || {}  // Ensure each message has a reactions object
                })));
            } else {
                console.error('Failed to fetch messages');
            }
        }
        fetchMessages();
    }, [channelId]);

    const indexOfLastMessage = currentPage * itemsPerPage;
    const indexOfFirstMessage = indexOfLastMessage - itemsPerPage;
    const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

    const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => (prev > 1 ? prev - 1 : 1));
    };

    return (
        <Box sx={{ margin: 4, bgcolor: 'transparent' }}>
            <Typography variant="h6" color="white" fontWeight="800" gutterBottom>Welcome to the official Chel Stats Highlight Reel!</Typography>
            <Typography variant="body1" color="white" paragraph>Gold tier members in the Chel Stats Discord server can upload in the highlights channel.</Typography>

            {messages.map(msg => {
                if (msg.videos.length == 0) return null
                return (
                    <Card key={msg.id} sx={{ maxWidth: '100%', my: 2, backgroundColor: '#171717' }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Avatar
                                    alt={msg.author}
                                    src={`https://cdn.discordapp.com/avatars/${msg.authorId}/${msg.avatar}.png?size=256`}
                                    sx={{ width: 40, height: 40, marginRight: 1 }}
                                />
                                <Typography gutterBottom variant="h6" sx={{ color: 'white' }}>
                                    @{msg.author}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{ color: 'white' }}>
                                {msg.content.replace(/https:\/\/[\n\S]+/g, '')}
                            </Typography>
                        </CardContent>
                        {msg.images && msg.images.map((image, index) => (
                            <CardMedia
                                key={index}
                                component="img"
                                image={image}
                                alt="Discord attachment"
                                sx={{ maxWidth: windowWidth < 600 ? '100%' : windowWidth < 900 ? '30%' : '30%', height: 'auto' }}
                            />
                        ))}
                        {msg.videos && msg.videos.map((video, index) => (
                            <video key={index} controls src={video} style={{ maxWidth: windowWidth < 600 ? '100%' : windowWidth < 900 ? '30%' : '30%' }} />
                        ))}
                    </Card>
                );
            })}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</Button>
                <Button onClick={handleNextPage} disabled={currentPage * itemsPerPage >= messages.length}>Next</Button>
            </Box>
        </Box>
    );
};

export default HighlightReel;
