import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function THL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/THL_Logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '95px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img src={logoPath} alt={`THL logo`} style={{ justifyContent: 'center', marginRight: 20, width: 100, height: 100 }} />

                    <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                        Welcome to THL!
                    </Typography>
                </Box>
                <Typography variant="body1" color="white" paragraph sx={{marginTop: 3}}>
                <Link>http://discord.gg/thl</Link>                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for THL!
                </Typography>
                <Button variant="contained" color="primary" href="/THL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/THL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/THL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0  }}>
                    Goalie Leaderboard
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h4" color="white" fontWeight="800" gutterBottom>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'pit-vipers'} text={'Pit Vipers'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'wanderers'} text={'Wanderers'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'north-side-storm'} text={'North Side Storm'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'comet-crushers'} text={'Comet Crushers'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'forsworn-knights'} text={'Forsworn Knights'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'bandits'} text={'Bandits'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'roaring-tigers'} text={'Roaring Tigers'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'flaming-toros'} text={'Flaming Toros'} league={'THL'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                </Container>
            </Box>

            {/* Repeat for more sections as needed */}
        </Container>
    );
}



export default THL_Home;