import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material';

function StandingsPage({league}) {
    const [sheetData, setSheetData] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'POINTS',
        direction: 'desc'
    });
    const visibleColumns = [
        { key: 'Team', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Wins', sortable: true },
        { key: 'Losses', sortable: true },
        { key: 'OTL', sortable: true },
        { key: 'POINTS', sortable: true },
        { key: 'GOALS FOR', sortable: true },
        { key: 'GOALS AGAINST', sortable: true },
        { key: 'DIFFERENTIAL', sortable: true },
        { key: 'RECORD AGAINST >.500', sortable: true }, // Ensure this matches your actual Points column header
    ];
    const pointsColumnIndex = 6; // Adjust if the Points column index changes in the sheet

    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/standings?league=${league}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSheetData(data.values);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };
    
        fetchSheetData();
    }, [setLoading, league]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const sortedData = useMemo(() => {
        const columnIndex = sheetData[0]?.indexOf(sortConfig.key);
        if (columnIndex > -1) {
            return sheetData.slice(1).sort((a, b) => {
                if (sortConfig.key === 'RECORD AGAINST >.500') {
                    const extractWins = (record) => {
                        const match = record.match(/^\((\d*)/);
                        return match ? parseInt(match[1], 10) : 0;
                    };

                    const aValue = extractWins(a[columnIndex]);
                    const bValue = extractWins(b[columnIndex]);

                    return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
                } else {
                    const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                    const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];
                    if (aValue < bValue) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sheetData.slice(1); // Return the data without the header row if not sorting
    }, [sheetData, sortConfig]);

    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    return (
        <Box sx={{ maxWidth: '90%', m: 'auto', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ m: 'auto',marginTop: 4, color: 'white' }}>{league.toLocaleUpperCase()} Standings - Season 4</Typography>
            <TableContainer component={Paper} sx={{marginTop: 5, marginBottom: 5}}>
                <Table stickyHeader>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#1C4587' }}>
                        {visibleColumns.map((column, columnIndex) => {
                            const { key, sortable } = column;
                            return (
                                <TableCell
                                    key={key}
                                    sx={{
                                        backgroundColor: '#1C4587',
                                        color: 'white',
                                        p: '8px',
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap',
                                        borderRight: columnIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    {sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key === key}
                                            direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                                            onClick={() => handleSort(key)}
                                        >
                                            {key.toLocaleUpperCase()}
                                        </TableSortLabel>
                                    ) : key.toLocaleUpperCase()}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                    <TableBody >
                        {sortedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {visibleColumns.map(({ key }, colIndex) => {
                                    const cellValue = row[sheetData[0].indexOf(key)];
                                    return <TableCell key={colIndex} sx={{fontWeight: '500', borderRight: colIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'}}>{cellValue}</TableCell>;
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default StandingsPage;
