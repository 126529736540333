import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const SavedSearches = () => {
  const [playerSearches, setPlayerSearches] = useState([]);
  const [clubSearches, setClubSearches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve all player searches from local storage
    const playerKeys = Object.keys(localStorage).filter(key => key.startsWith('player_'));
    const players = playerKeys.map(key => {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        console.error('Error parsing JSON from local storage:', e);
        return null;
      }
    }).filter(search => search !== null);
    setPlayerSearches(players);

    // Retrieve all club searches from local storage
    const clubKeys = Object.keys(localStorage).filter(key => key.startsWith('club_'));
    const clubs = clubKeys.map(key => {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        console.error('Error parsing JSON from local storage:', e);
        return null;
      }
    }).filter(search => search !== null);
    setClubSearches(clubs);
  }, []);

  const handlePlayerClick = (username, teamname, consolegen) => {
    if (teamname != undefined) {
      navigate(`/players?username=${username}&teamname=${teamname}&console=${consolegen}`);
    } else {
      navigate(`/players?username=${username}&console=${consolegen}&freeAgent=True`);
    }
  };

  const handleClubClick = (teamname, consolegen) => {
    navigate(`/clubs?teamname=${teamname}&console=${consolegen}`);
  };

  return (
    <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '40px' }}>
      <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
        Saved Searches
      </Typography>
      <Typography color="white" fontWeight="700" gutterBottom sx={{ fontSize: '18px',marginTop: 4, marginBottom: 2 }}>
        Player Searches
      </Typography>
      {playerSearches.length === 0 ? (
        <Typography variant="body1" color="white">
          You have no saved player searches.
        </Typography>
      ) : (
        <Grid container spacing={1}>
          {playerSearches.map((search, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Button
                endIcon={<ChevronRightIcon />}
                variant="contained"
                color="primary"
                sx={{
                  
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 'max-content',
                }}
                onClick={() => handlePlayerClick(search.username, search.teamname, search.console)}
              >
                {search.username} - {search.teamname}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
      <Typography color="white" fontWeight="700" gutterBottom sx={{ fontSize: '18px',marginTop: 4, marginBottom: 2 }}>
        Club Searches
      </Typography>
      {clubSearches.length === 0 ? (
        <Typography variant="body1" color="white">
          You have no saved club searches.
        </Typography>
      ) : (
        <Grid container spacing={1}>
          {clubSearches.map((search, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Button
                endIcon={<ChevronRightIcon />}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 'max-content',
                }}
                onClick={() => handleClubClick(search.teamname, search.console)}
              >
                {search.teamname} - {search.console}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default SavedSearches;
