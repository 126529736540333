import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useHref } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, styled } from '@mui/material';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box, ThemeProvider, createTheme, Collapse, Breadcrumbs, Divider, Avatar, Drawer, SwipeableDrawer } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import DomainIcon from '@mui/icons-material/Domain';
import HubIcon from '@mui/icons-material/Hub';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { StatsProvider } from './Contexts/StatsContext';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import HomePage from './Main Components/HomePage';
import PlayerStats from './EASHL/Players/PlayerStats';
import ClubStats from './EASHL/Clubs/ClubStats';
import RecentGame from './EASHL/Clubs/RecentGame'
import { refreshUserData } from './EASHL/Players/PlayerStats';
import AboutPage from './Main Components/AboutPage';
import Footer from './Main Components/Footer';
import myBanner from './Main Components/Logos/image.png';
import AuthButton from './Main Components/AuthButton';
import { UserProvider, useUser } from './Contexts/UserContext';
import { LoadingProvider } from './Contexts/LoadingContext';
import StandingsPage from './Leagues/StandingsPage';
import LeaguesTeamPageThrees from './Leagues/LeaguesTeamPageThrees';
import LeaguesTeamPageSixes from './Leagues/LeaguesTeamPageSixes';
import PGL_Home from './Leagues/PGL/PGL_Home';
import SkaterLeaderboardThrees from './Leagues/SkaterLeaderboardThrees';
import GoalieLeaderboardThrees from './Leagues/GoalieLeaderboardThrees';
import WOHL_Home from './Leagues/WOHL/WOHL_Home';
import WOHLStandings from './Leagues/WOHL/WOHLStandings';
import WAHL_Home from './Leagues/WAHL/WAHL_Home';
import SkaterLeaderboardSixes from './Leagues/SkaterLeaderboardSixes';
import SkaterLeaderboardSixesPlayoffs from './Leagues/SkaterLeaderboardSixesPlayoffs';
import GoalieLeaderboardSixesPlayoffs from './Leagues/GoalieLeaderboardSixesPlayoffs';
import GoalieLeaderboardSixes from './Leagues/GoalieLeaderboardSixes';
import TTHL_Home from './Leagues/TTHL/TTHL_Home';
import CNC_Home from './Leagues/CNC/CNC_Home'
import CNCStandings from './Leagues/CNC/CNCStandings';
import OHL_Home from './Leagues/OHL/OHL_Home'
import OHLStandings from './Leagues/OHL/OHLStandings';
import EASHLDirectory from './Main Components/EASHLDirectory';
import DiscordMessages from './Content Creators/DiscordMessages';
import ImagineNotScoringThere from './Chel Hub/ImagineNotScoringThere'
import HighlightReel from './Chel Hub/HighlightReel'
import queryString from 'query-string';
import FreeAgentsList from './Main Components/FreeAgentsList'
import ScoutingClubsList from './Main Components/ScoutingClubsList'
import StatsInfo from './Main Components/StatsInfo'
import Redirect from './Main Components/Redirect'
import ChangeLog from './Main Components/ChangeLog'
import IGHL_Home from './Leagues/IGHL/IGHL_Home'
import IGHL_Standings from './Leagues/IGHL/IGHLStandings'
import BBHL_Home from './Leagues/BBHL/BBHL_Home'
import NAEHL_Home from './Leagues/NAEHL/NAEHL_Home'
// import HockeyCards from './EASHL/Players/HockeyCards';
import SBHL_Home from './Leagues/SBHL/SBHL_Home'
import UPHL_Home from './Leagues/UPHL/UPHL_Home'
import UPHLStandings from './Leagues/UPHL/UPHLStandings'
import PlayerProfile from './Leagues/PlayerProfile';
import SkaterLeaderboardUPHL from './Leagues/UPHL/SkaterLeaderboardUPHL';
import Bidding from './Leagues/WOHL/Bidding'
import THL_Home from './Leagues/THL/THL_Home'
// Theme creation
const theme = createTheme({
    palette: {
        background: {
            default: '#121212', // Use your desired color
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1f1f1f',
                    color: '#fff',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px #121212 inset', // Change #000 to match your desired background color
                        WebkitTextFillColor: 'white', // Adjust text color as needed
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontSize: '24px',
            fontWeight: 700, // Medium weight for h1
        }
        // Continue for other variants as needed
    },
});

function Navigation({ mobileOpen, handleDrawerToggle }) {
    const location = useLocation();
    const [statsOpen, setStatsOpen] = useState(false);
    const [playersOpen, setPlayersOpen] = useState(false);
    const [clubsOpen, setClubsOpen] = useState(false);
    const [agencyOpen, setAgencyOpen] = useState(false);
    const [freeAgentsOpen, setFreeAgentsOpen] = useState(false);
    const [scoutingOpen, setScoutingOpen] = useState(false);
    const [leaguesOpen, setLeaguesOpen] = useState(false);
    const [hubOpen, setHubOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = false;

    const handleStatsClick = () => { // New handler for Players collapse
        setStatsOpen(!statsOpen);
    };
    const handlePlayersClick = () => { // New handler for Players collapse
        setPlayersOpen(!playersOpen);
    };
    const handleClubsClick = () => { // New handler for Players collapse
        setClubsOpen(!clubsOpen);
    };
    const handleAgencyClick = () => { // New handler for Players collapse
        setAgencyOpen(!agencyOpen);
    };
    const handleHubClick = () => { // New handler for Players collapse
        setHubOpen(!hubOpen);
    };
    const handleLeaguesClick = () => { // New handler for Players collapse
        setLeaguesOpen(!leaguesOpen);
    };
    const handleInfoClick = () => { // New handler for Players collapse
        setInfoOpen(!infoOpen);
    };

    const handleItemClick = (event, path) => {
        // Close the drawer
        handleDrawerToggle();
        // Navigate if necessary (if using programmatic navigation)
        //history.push(path); // Uncomment if using useHistory for navigation
    };

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    };

    const DrawerComponent = isTouchDevice() ? SwipeableDrawer : Drawer;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <DrawerComponent
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            disableBackdropTransition={!iOS} // Improve performance on iOS by disabling backdrop transition
            disableDiscovery={iOS} // Disable the swipe to open feature on iOS because it can interfere with navigation gestures
            swipeAreaWidth={15}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 270,
                    backgroundColor: '#121212',
                    color: 'white', '&::-webkit-scrollbar': { display: 'none' }, // For WebKit browsers
                    scrollbarWidth: 'none', // For Firefox
                    '-ms-overflow-style': 'none'

                },
            }}
        >
            <Box sx={{ backgroundColor: '#121212' }}>
                <Box
                    sx={{
                        margin: 'auto',
                        width: 125,
                        height: 175,
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundSize: '100%', // or a specific percentage
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${myBanner})`,
                    }}
                />
            </Box>
            <List sx={{ padding: 0 }}>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button component={Link} to="/" key="home" sx={{ color: 'white', bgcolor: location.pathname === '/' ? 'primary.main' : 'inherit' }} onClick={(event) => handleItemClick(event, '/')}>
                    <ListItemIcon ><HomeIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Home" sx={{ color: 'white' }} />
                </ListItem>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleStatsClick}>
                    <ListItemIcon><CalculateIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="EASHL Stats" sx={{ color: 'white' }} />
                    {statsOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Divider sx={{ borderColor: '#424549' }} />
                <Collapse in={statsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/players/') ? 'primary.light' : 'inherit' }} component={Link} to="/players/" onClick={(event) => handleItemClick(event, '/players/')}>
                            <ListItemIcon>
                                <ArrowRightRoundedIcon sx={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Players" />
                        </ListItem>
                    </List>
                    <Divider sx={{ borderColor: '#424549' }} />

                    <List component="div" disablePadding>
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/clubs/') ? 'primary.light' : 'inherit' }} component={Link} to="/clubs/" onClick={(event) => handleItemClick(event, '/clubs/')}>
                            <ListItemIcon>
                                <ArrowRightRoundedIcon sx={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Clubs" />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleHubClick}>
                    <ListItemIcon><HubIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Chel Hub" sx={{ color: 'white' }} />
                    {hubOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={hubOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/about') ? 'primary.light' : 'inherit' }} component={Link} to="/about" onClick={(event) => handleItemClick(event, '/about')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="About" sx={{ color: 'white' }} />
                        </ListItem> */}
                        {/* <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/content-creators') ? 'primary.light' : 'inherit' }} component={Link} to="/chel-hub/content-creators" onClick={(event) => handleItemClick(event, '/content-creators')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Content Creators" sx={{ color: 'white' }} />
                        </ListItem> */}
                        {/* <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/cards') ? 'primary.light' : 'inherit' }} component={Link} to="/cards" onClick={(event) => handleItemClick(event, '/cards')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Cards" sx={{ color: 'white' }} />
                        </ListItem> */}
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/imagine-not-scoring-there') ? 'primary.light' : 'inherit' }} component={Link} to="/imagine-not-scoring-there" onClick={(event) => handleItemClick(event, '/imagine-not-scoring-there')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Imagine Not Scoring There" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/highlight-reel') ? 'primary.light' : 'inherit' }} component={Link} to="/highlight-reel" onClick={(event) => handleItemClick(event, '/highlight-reel')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Highlight Reel" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleAgencyClick}>
                    <ListItemIcon><DomainIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Agency" sx={{ color: 'white' }} />
                    {agencyOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={agencyOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/free-agents') ? 'primary.light' : 'inherit' }} component={Link} to="/free-agents" onClick={(event) => handleItemClick(event, '/free-agents')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Free Agents" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/scouting') ? 'primary.light' : 'inherit' }} component={Link} to="/scouting" onClick={(event) => handleItemClick(event, '/scouting')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Scouting" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleLeaguesClick}>
                    <ListItemIcon><SportsHockeyIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Leagues" sx={{ color: 'white' }} />
                    {leaguesOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={leaguesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/league-directory') ? 'primary.light' : 'inherit' }} component={Link} to="/league-directory" onClick={(event) => handleItemClick(event, '/league-directory')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="League Directory" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/PGL') ? 'primary.light' : 'inherit' }} component={Link} to="/PGL" onClick={(event) => handleItemClick(event, '/PGL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="PGL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/WOHL') ? 'primary.light' : 'inherit' }} component={Link} to="/WOHL" onClick={(event) => handleItemClick(event, '/WOHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="WOHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/WAHL') ? 'primary.light' : 'inherit' }} component={Link} to="/WAHL" onClick={(event) => handleItemClick(event, '/WAHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="WAHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/CNC') ? 'primary.light' : 'inherit' }} component={Link} to="/CNC" onClick={(event) => handleItemClick(event, '/CNC')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="CNC" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/TTHL') ? 'primary.light' : 'inherit' }} component={Link} to="/TTHL" onClick={(event) => handleItemClick(event, '/TTHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="TTHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/OHL') ? 'primary.light' : 'inherit' }} component={Link} to="/OHL" onClick={(event) => handleItemClick(event, '/OHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="OHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/IGHL') ? 'primary.light' : 'inherit' }} component={Link} to="/IGHL" onClick={(event) => handleItemClick(event, '/IGHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="IGHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/BBHL') ? 'primary.light' : 'inherit' }} component={Link} to="/BBHL" onClick={(event) => handleItemClick(event, '/BBHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="BBHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/NAEHL') ? 'primary.light' : 'inherit' }} component={Link} to="/NAEHL" onClick={(event) => handleItemClick(event, '/NAEHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="NAEHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/SBHL') ? 'primary.light' : 'inherit' }} component={Link} to="/SBHL" onClick={(event) => handleItemClick(event, '/SBHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="SBHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/UPHL') ? 'primary.light' : 'inherit' }} component={Link} to="/UPHL" onClick={(event) => handleItemClick(event, '/UPHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="UPHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/THL') ? 'primary.light' : 'inherit' }} component={Link} to="/THL" onClick={(event) => handleItemClick(event, '/THL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="THL" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleInfoClick}>
                    <ListItemIcon><InfoIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Info" sx={{ color: 'white' }} />
                    {infoOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={infoOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/subscriptions') ? 'primary.light' : 'inherit' }} component={Link} to="/subscriptions" onClick={(event) => handleItemClick(event, '/subscriptions')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Subscriptions" sx={{ color: 'white' }} />
                        </ListItem> */}
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/about') ? 'primary.light' : 'inherit' }} component={Link} to="/about" onClick={(event) => handleItemClick(event, '/about')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="About" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/discord-server') ? 'primary.light' : 'inherit' }} component={Link} to="/discord-server" onClick={(event) => handleItemClick(event, '/discord-server')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Official discord server" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/change-log') ? 'primary.light' : 'inherit' }} component={Link} to="/change-log" onClick={(event) => handleItemClick(event, '/change-log')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Change Log" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/overall-ratings-info') ? 'primary.light' : 'inherit' }} component={Link} to="/overall-ratings-info" onClick={(event) => handleItemClick(event, '/overall-ratings-info')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Overall ratings info" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/privacy-policy') ? 'primary.light' : 'inherit' }} component={Link} to="/privacy-policy" onClick={(event) => handleItemClick(event, '/privacy-policy')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Privacy Policy" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
            </List>

            <Box p={2} >
                <AuthButton></AuthButton>
            </Box>
        </DrawerComponent>
    );
}

const CustomLink = styled(MuiLink)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '12px',
    color: 'white !important',
    textDecoration: 'none',
    cursor: 'pointer',
    "&:visited, &:hover, &:active": {
        color: 'white !important',

    },
}));

function StyledRouterLink(props) {
    return <CustomLink component={RouterLink} {...props} />;
}

function filterParamsForPath(path, fullParams) {
    const params = queryString.parse(fullParams);
    const filteredParams = {};

    // Example logic for filtering parameters
    if (path.includes('/clubs')) {
        filteredParams['teamname'] = params['teamname']; // Assume 'id' is necessary for 'details'
        filteredParams['console'] = params['console'];
    }

    // Return the filtered parameters as a query string
    return queryString.stringify(filteredParams);
}

const BreadcrumbNav = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginLeft: '15px', marginTop: '5px', fontSize: '12px', color: 'white' }}>
            <StyledRouterLink to="/">home</StyledRouterLink>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const pathToIndex = `/${pathnames.slice(0, index + 1).join('/')}`;
                const filteredSearch = filterParamsForPath(pathToIndex, location.search);
                const to = `${pathToIndex}${filteredSearch ? `?${filteredSearch}` : ''}`;

                return last ? (
                    <Typography color="textPrimary" key={to} sx={{ fontSize: '12px', color: 'white' }}>
                        {value}
                    </Typography>
                ) : (
                    <StyledRouterLink to={to} key={to}>
                        {value}
                    </StyledRouterLink>
                );
            })}
        </Breadcrumbs>
    );
};

function MyAppBar({ handleDrawerToggle }) {
    const { user, setUser } = useUser();
    useEffect(() => {
        refreshUserData(setUser);
    }, [setUser]);
    let userImage = ''; // URL of the user's image
    let username = ''
    let userId = ''
    let profilePicURL = ''
    if (user) {
        username = user.username;
        userImage = user.avatar;
        userId = user.discordId
        profilePicURL = `https://cdn.discordapp.com/avatars/${userId}/${userImage}.png?size=256`

    }

    return (
        <AppBar position="fixed" >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 0 }}>
                    Chel stats
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        alt={username}
                        src={profilePicURL}
                        sx={{ width: 24, height: 24, marginRight: 1 }} // Adjust the size as needed and add margin
                    />
                    <Typography variant="body2" color="inherit" noWrap>
                        {username}
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

function App() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <LoadingProvider>
            <UserProvider>
                <StatsProvider>
                    <ThemeProvider theme={theme} >
                        <Router basename="/">
                            {/* <AdComponent/> */}
                            {/* <Router> */}
                            <Box display="flex" flexDirection="column" height="100vh" >
                                <CssBaseline />
                                <MyAppBar handleDrawerToggle={handleDrawerToggle} />
                                <Toolbar /> {/* This is to offset content below AppBar */}
                                <Box component="main" flex="1">
                                    <Navigation mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                                    <BreadcrumbNav />
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/players/" element={<PlayerStats />} />
                                        {/* <Route path="/cards/" element={<HockeyCards />} /> */}
                                        <Route path="/clubs/" element={<ClubStats />} />
                                        <Route path="/clubs/recent-games" element={<RecentGame />} />
                                        <Route path="/league-directory" element={<EASHLDirectory />} />
                                        <Route path="/content-creators" element={<DiscordMessages channelId={'1151578330567549028'} />} />
                                        <Route path="/imagine-not-scoring-there" element={<ImagineNotScoringThere channelId={'1149204952049258517'} />} />
                                        <Route path="/highlight-reel" element={<HighlightReel channelId={'1151578330567549028'} />} />
                                        <Route path="/free-agents" element={<FreeAgentsList />} />
                                        <Route path="/scouting" element={<ScoutingClubsList />} />
                                        <Route path="/overall-ratings-info" element={<StatsInfo />} />
                                        <Route path="/privacy-policy" element={<Redirect to="https://drive.google.com/file/d/1D31ArV-DBI2sHGspoSsc-1mYMw3JZzW6/view?usp=drive_link" />} />
                                        <Route path="/discord-server" element={<Redirect to="https://discord.gg/GHx7Zvwk2P" />} />
                                        <Route path="/change-log" element={<ChangeLog />} />
                                        <Route path="/about" element={<AboutPage />} />
                                        <Route path="/PGL" element={<PGL_Home />} />
                                        <Route path="/PGL/standings" element={<StandingsPage league={'pgl'} />} />
                                        <Route path="/PGL/skater-leaderboard" element={<SkaterLeaderboardThrees league={'PGL'} />} />
                                        <Route path="/PGL/goalie-leaderboard" element={<GoalieLeaderboardThrees league={'PGL'} />} />
                                        <Route path="/PGL/stampede" element={<LeaguesTeamPageThrees team={'Stampede'} league={'PGL'} />} />
                                        <Route path="/PGL/bulldoggs" element={<LeaguesTeamPageThrees team={'Bulldoggs'} league={'PGL'} />} />
                                        <Route path="/PGL/blood-ravens" element={<LeaguesTeamPageThrees team={'Blood Ravens'} league={'PGL'} />} />
                                        <Route path="/PGL/newport-nukes" element={<LeaguesTeamPageThrees team={'Newport Nukes'} league={'PGL'} />} />
                                        <Route path="/PGL/southpark-cows" element={<LeaguesTeamPageThrees team={'Cows'} league={'PGL'} />} />
                                        <Route path="/PGL/death-bats" element={<LeaguesTeamPageThrees team={'Death Bats'} league={'PGL'} />} />
                                        <Route path="/WOHL" element={<WOHL_Home />} />
                                        <Route path="/WOHL/bidding" element={<Bidding />} />
                                        <Route path="/WOHL/standings" element={<WOHLStandings />} />
                                        <Route path="/WOHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'WOHL'} />} />
                                        <Route path="/WOHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'WOHL'} />} />
                                        <Route path="/WOHL/anaheim-ducks" element={<LeaguesTeamPageSixes team={'Anaheim Ducks'} league={'WOHL'} />} />
                                        <Route path="/WOHL/utah-hc" element={<LeaguesTeamPageSixes team={'Utah Hockey Club'} league={'WOHL'} />} />
                                        <Route path="/WOHL/san-jose-sharks" element={<LeaguesTeamPageSixes team={'San Jose Sharks'} league={'WOHL'} />} />
                                        <Route path="/WOHL/florida-panthers" element={<LeaguesTeamPageSixes team={'Florida Panthers'} league={'WOHL'} />} />
                                        <Route path="/WOHL/carolina-hurricanes" element={<LeaguesTeamPageSixes team={'Carolina Hurricanes'} league={'WOHL'} />} />
                                        <Route path="/WOHL/edmonton-oilers" element={<LeaguesTeamPageSixes team={'Edmonton Oilers'} league={'WOHL'} />} />
                                        <Route path="/WOHL/montreal-canadiens" element={<LeaguesTeamPageSixes team={'Montreal Canadiens'} league={'WOHL'} />} />
                                        <Route path="/WOHL/dallas-stars" element={<LeaguesTeamPageSixes team={'Dallas Stars'} league={'WOHL'} />} />
                                        <Route path="/WAHL" element={<WAHL_Home />} />
                                        <Route path="/WAHL/standings" element={<StandingsPage league={'wahl'} />} />
                                        <Route path="/WAHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'WAHL'} />} />
                                        <Route path="/WAHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'WAHL'} />} />
                                        <Route path="/WAHL/chicago-wolves" element={<LeaguesTeamPageSixes team={'Chicago Wolves'} league={'WAHL'} />} />
                                        <Route path="/WAHL/san-jose-barracuda" element={<LeaguesTeamPageSixes team={'San Jose Barracuda'} league={'WAHL'} />} />
                                        <Route path="/WAHL/charlotte-checkers" element={<LeaguesTeamPageSixes team={'Charlotte Checkers'} league={'WAHL'} />} />
                                        <Route path="/WAHL/bakersfield-condors" element={<LeaguesTeamPageSixes team={'Bakersfield Condors'} league={'WAHL'} />} />
                                        <Route path="/WAHL/tucson-roadrunners" element={<LeaguesTeamPageSixes team={'Tucson Roadrunners'} league={'WAHL'} />} />
                                        <Route path="/WAHL/san-diego-gulls" element={<LeaguesTeamPageSixes team={'San Diego Gulls'} league={'WAHL'} />} />
                                        <Route path="/WAHL/laval-rocket" element={<LeaguesTeamPageSixes team={'Laval Rocket'} league={'WAHL'} />} />
                                        <Route path="/WAHL/texas-stars" element={<LeaguesTeamPageSixes team={'Texas Stars'} league={'WAHL'} />} />
                                        <Route path="/TTHL" element={<TTHL_Home />} />
                                        <Route path="/TTHL/standings" element={<StandingsPage league={'tthl'} />} />
                                        <Route path="/TTHL/skater-leaderboard" element={<SkaterLeaderboardThrees league={'TTHL'} />} />
                                        <Route path="/TTHL/goalie-leaderboard" element={<GoalieLeaderboardThrees league={'TTHL'} />} />
                                        <Route path="/TTHL/anaheim-ducks" element={<LeaguesTeamPageThrees team={'Anaheim Ducks'} league={'TTHL'} />} />
                                        <Route path="/TTHL/boston-bruins" element={<LeaguesTeamPageThrees team={'Boston Bruins'} league={'TTHL'} />} />
                                        <Route path="/TTHL/carolina-hurricans" element={<LeaguesTeamPageThrees team={'Carolina Hurricanes'} league={'TTHL'} />} />
                                        <Route path="/TTHL/colorado-avalanche" element={<LeaguesTeamPageThrees team={'Colorado Avalanche'} league={'TTHL'} />} />
                                        <Route path="/TTHL/florida-panthers" element={<LeaguesTeamPageThrees team={'Florida Panthers'} league={'TTHL'} />} />
                                        <Route path="/TTHL/los-angeles-kings" element={<LeaguesTeamPageThrees team={'Los Angeles Kings'} league={'TTHL'} />} />
                                        <Route path="/TTHL/nashville-predators" element={<LeaguesTeamPageThrees team={'Nashville Predators'} league={'TTHL'} />} />
                                        <Route path="/TTHL/new-york-rangers" element={<LeaguesTeamPageThrees team={'New York Rangers'} league={'TTHL'} />} />
                                        <Route path="/TTHL/washington-capitals" element={<LeaguesTeamPageThrees team={'Washington Capitals'} league={'TTHL'} />} />
                                        <Route path="/TTHL/winnipeg-jets" element={<LeaguesTeamPageThrees team={'Winnipeg Jets'} league={'TTHL'} />} />
                                        <Route path="/CNC" element={<CNC_Home />} />
                                        <Route path="/CNC/standings" element={<CNCStandings />} />
                                        <Route path="/CNC/skater-leaderboard" element={<SkaterLeaderboardSixes league={'CNC'} />} />
                                        <Route path="/CNC/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'CNC'} />} />
                                        <Route path="/CNC/anaheim-ducks" element={<LeaguesTeamPageSixes team={'Anaheim Ducks'} league={'CNC'} lowercaseteam={'anaheim-ducks'} color='green' />} />
                                        <Route path="/CNC/arizona-coyotes" element={<LeaguesTeamPageSixes team={'Arizona Coyotes'} league={'CNC'} />} />
                                        <Route path="/CNC/boston-bruins" element={<LeaguesTeamPageSixes team={'Boston Bruins'} league={'CNC'} />} />
                                        <Route path="/CNC/buffalo-sabres" element={<LeaguesTeamPageSixes team={'Buffalo Sabres'} league={'CNC'} />} />
                                        <Route path="/CNC/calgary-flames" element={<LeaguesTeamPageSixes team={'Calgary Flames'} league={'CNC'} />} />
                                        <Route path="/CNC/carolina-hurricanes" element={<LeaguesTeamPageSixes team={'Carolina Hurricanes'} league={'CNC'} />} />
                                        <Route path="/CNC/chicago-blackhawks" element={<LeaguesTeamPageSixes team={'Chicago Blackhawks'} league={'CNC'} />} />
                                        <Route path="/CNC/colorado-avalanche" element={<LeaguesTeamPageSixes team={'Colorado Avalanche'} league={'CNC'} />} />
                                        <Route path="/CNC/columbus-blue-jackets" element={<LeaguesTeamPageSixes team={'Columbus Blue Jackets'} league={'CNC'} />} />
                                        <Route path="/CNC/dallas-stars" element={<LeaguesTeamPageSixes team={'Dallas Stars'} league={'CNC'} />} />
                                        <Route path="/CNC/detroit-redwings" element={<LeaguesTeamPageSixes team={'Detroit Red Wings'} league={'CNC'} />} />
                                        <Route path="/CNC/edmonton-oilers" element={<LeaguesTeamPageSixes team={'Edmonton Oilers'} league={'CNC'} />} />
                                        <Route path="/CNC/florida-panthers" element={<LeaguesTeamPageSixes team={'Florida Panthers'} league={'CNC'} />} />
                                        <Route path="/CNC/los-angeles-kings" element={<LeaguesTeamPageSixes team={'Los Angeles Kings'} league={'CNC'} />} />
                                        <Route path="/CNC/minnesota-wild" element={<LeaguesTeamPageSixes team={'Minnesota Wild'} league={'CNC'} />} />
                                        <Route path="/CNC/montreal-canadiens" element={<LeaguesTeamPageSixes team={'Montreal Canadiens'} league={'CNC'} />} />
                                        <Route path="/CNC/nashville-predators" element={<LeaguesTeamPageSixes team={'Nashville Predators'} league={'CNC'} />} />
                                        <Route path="/CNC/new-jersey-devils" element={<LeaguesTeamPageSixes team={'New Jersey Devils'} league={'CNC'} />} />
                                        <Route path="/CNC/new-york-islanders" element={<LeaguesTeamPageSixes team={'New York Islanders'} league={'CNC'} />} />
                                        <Route path="/CNC/new-york-rangers" element={<LeaguesTeamPageSixes team={'New York Rangers'} league={'CNC'} />} />
                                        <Route path="/CNC/ottawa-senators" element={<LeaguesTeamPageSixes team={'Ottawa Senators'} league={'CNC'} />} />
                                        <Route path="/CNC/philadelphia-flyers" element={<LeaguesTeamPageSixes team={'Philadelphia Flyers'} league={'CNC'} />} />
                                        <Route path="/CNC/pittsburgh-penguins" element={<LeaguesTeamPageSixes team={'Pittsburgh Penguins'} league={'CNC'} />} />
                                        <Route path="/CNC/san-jose-sharks" element={<LeaguesTeamPageSixes team={'San Jose Sharks'} league={'CNC'} />} />
                                        <Route path="/CNC/seattle-kraken" element={<LeaguesTeamPageSixes team={'Seattle Kraken'} league={'CNC'} />} />
                                        <Route path="/CNC/stlouis-blues" element={<LeaguesTeamPageSixes team={'St Louis Blues'} league={'CNC'} />} />
                                        <Route path="/CNC/tampa-bay-lightning" element={<LeaguesTeamPageSixes team={'Tampa Bay Lightning'} league={'CNC'} />} />
                                        <Route path="/CNC/toronto-maple-leafs" element={<LeaguesTeamPageSixes team={'Toronto Maple Leafs'} league={'CNC'} />} />
                                        <Route path="/CNC/vancouver-canucks" element={<LeaguesTeamPageSixes team={'Vancouver Canucks'} league={'CNC'} />} />
                                        <Route path="/CNC/vegas-golden-knights" element={<LeaguesTeamPageSixes team={'Vegas Golden Knights'} league={'CNC'} />} />
                                        <Route path="/CNC/washington-capitals" element={<LeaguesTeamPageSixes team={'Washington Capitals'} league={'CNC'} />} />
                                        <Route path="/CNC/winnipeg-jets" element={<LeaguesTeamPageSixes team={'Winnipeg Jets'} league={'CNC'} />} />
                                        <Route path="/OHL" element={<OHL_Home />} />
                                        <Route path="/OHL/standings" element={<OHLStandings />} />
                                        <Route path="/OHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'OHL'} />} />
                                        <Route path="/OHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'OHL'} />} />
                                        <Route path="/OHL/arizona-coyotes" element={<LeaguesTeamPageSixes team={'Arizona Coyotes'} league={'OHL'} />} />
                                        <Route path="/OHL/buffalo-sabres" element={<LeaguesTeamPageSixes team={'Buffalo Sabres'} league={'OHL'} />} />
                                        <Route path="/OHL/colorado-avalanche" element={<LeaguesTeamPageSixes team={'Colorado Avalanche'} league={'OHL'} />} />
                                        <Route path="/OHL/carolina-hurricanes" element={<LeaguesTeamPageSixes team={'Carolina Hurricanes'} league={'OHL'} />} />
                                        <Route path="/OHL/minnesota-wild" element={<LeaguesTeamPageSixes team={'Minnesota Wild'} league={'OHL'} />} />
                                        <Route path="/OHL/los-angeles-kings" element={<LeaguesTeamPageSixes team={'Los Angeles Kings'} league={'OHL'} />} />
                                        <Route path="/OHL/pittsburgh-penguins" element={<LeaguesTeamPageSixes team={'Pittsburgh Penguins'} league={'OHL'} />} />
                                        <Route path="/OHL/philadelphia-flyers" element={<LeaguesTeamPageSixes team={'Philadelphia Flyers'} league={'OHL'} />} />
                                        <Route path="/OHL/seattle-kraken" element={<LeaguesTeamPageSixes team={'Seattle Kraken'} league={'OHL'} />} />
                                        <Route path="/OHL/toronto-maple-leafs" element={<LeaguesTeamPageSixes team={'Toronto Maple Leafs'} league={'OHL'} />} />
                                        <Route path="/OHL/vegas-golden-knights" element={<LeaguesTeamPageSixes team={'Vegas Golden Knights'} league={'OHL'} />} />
                                        <Route path="/OHL/washington-capitals" element={<LeaguesTeamPageSixes team={'Washington Capitals'} league={'OHL'} />} />
                                        <Route path="/IGHL" element={<IGHL_Home />} />
                                        <Route path="/IGHL/standings" element={<IGHL_Standings />} />
                                        <Route path="/IGHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'IGHL'} />} />
                                        <Route path="/IGHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'IGHL'} />} />
                                        <Route path="/IGHL/day-star-87s" element={<LeaguesTeamPageSixes team={'Day Star 87s'} league={'IGHL'} />} />
                                        <Route path="/IGHL/blues" element={<LeaguesTeamPageSixes team={'Blues'} league={'IGHL'} />} />
                                        <Route path="/IGHL/northeast-voyageurs" element={<LeaguesTeamPageSixes team={'Northeast Voyageurs'} league={'IGHL'} />} />
                                        <Route path="/IGHL/border-chiefs" element={<LeaguesTeamPageSixes team={'Border Chiefs'} league={'IGHL'} />} />
                                        <Route path="/IGHL/warriors" element={<LeaguesTeamPageSixes team={'Warriors'} league={'IGHL'} />} />
                                        <Route path="/IGHL/ilex-islanders" element={<LeaguesTeamPageSixes team={'Ilex Islanders'} league={'IGHL'} />} />
                                        <Route path="/IGHL/badge-wolverines" element={<LeaguesTeamPageSixes team={'Badge Wolverines'} league={'IGHL'} />} />
                                        <Route path="/IGHL/elsipogtog-oilers" element={<LeaguesTeamPageSixes team={'Elsipogtog Oilers'} league={'IGHL'} />} />
                                        <Route path="/IGHL/town-indians" element={<LeaguesTeamPageSixes team={'Town Indians'} league={'IGHL'} />} />
                                        <Route path="/IGHL/shubenacadie-river-kings" element={<LeaguesTeamPageSixes team={'Shubenacadie River Kings'} league={'IGHL'} />} />
                                        <Route path="/IGHL/pa-raiders" element={<LeaguesTeamPageSixes team={'PA Raiders'} league={'IGHL'} />} />
                                        <Route path="/IGHL/sc-wolfpack" element={<LeaguesTeamPageSixes team={'Sucker Creek Wolfpack'} league={'IGHL'} />} />
                                        <Route path="/IGHL/golden-hawks" element={<LeaguesTeamPageSixes team={'Golden Hawks'} league={'IGHL'} />} />
                                        <Route path="/IGHL/glf-flames" element={<LeaguesTeamPageSixes team={'Goodfish Flames'} league={'IGHL'} />} />
                                        <Route path="/BBHL" element={<BBHL_Home />} />
                                        <Route path="/BBHL/standings" element={<StandingsPage league={'bbhl'} />} />
                                        <Route path="/BBHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'BBHL'} />} />
                                        <Route path="/BBHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'BBHL'} />} />
                                        <Route path="/BBHL/anaheim-ducks" element={<LeaguesTeamPageSixes team={'Anaheim Ducks'} league={'BBHL'} />} />
                                        <Route path="/BBHL/detroit-red-wings" element={<LeaguesTeamPageSixes team={'Detroit Red Wings'} league={'BBHL'} />} />
                                        <Route path="/BBHL/boston-bruins" element={<LeaguesTeamPageSixes team={'Boston Bruins'} league={'BBHL'} />} />
                                        <Route path="/BBHL/new-york-rangers" element={<LeaguesTeamPageSixes team={'New York Rangers'} league={'BBHL'} />} />
                                        <Route path="/BBHL/ottawa-senators" element={<LeaguesTeamPageSixes team={'Ottawa Senators'} league={'BBHL'} />} />
                                        <Route path="/BBHL/philadelphia-flyers" element={<LeaguesTeamPageSixes team={'Philadelphia Flyers'} league={'BBHL'} />} />
                                        <Route path="/BBHL/pittsburgh-penguins" element={<LeaguesTeamPageSixes team={'Pittsburgh Penguins'} league={'BBHL'} />} />
                                        <Route path="/BBHL/utah-hc" element={<LeaguesTeamPageSixes team={'Utah Hockey Club'} league={'BBHL'} />} />
                                        <Route path="/NAEHL" element={<NAEHL_Home />} />
                                        <Route path="/NAEHL/:team/:username" element={<PlayerProfile league={'NAEHL'}/>} />
                                        <Route path="/NAEHL/standings" element={<StandingsPage league={'naehl'} />} />
                                        <Route path="/NAEHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'NAEHL'} />} />
                                        <Route path="/NAEHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'NAEHL'} />} />
                                        <Route path="/NAEHL/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'NAEHL'} />} />
                                        <Route path="/NAEHL/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'NAEHL'} />} />
                                        <Route path="/NAEHL/malmo-redhawks" element={<LeaguesTeamPageSixes team={'Malmo Redhawks'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/lahti-pelicans" element={<LeaguesTeamPageSixes team={'Lahti Pelicans'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/dynamo-parbudice" element={<LeaguesTeamPageSixes team={'Dynamo Parbudice'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/red-bull-munchen" element={<LeaguesTeamPageSixes team={'Red Bull Munchen'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/adler-mannheim" element={<LeaguesTeamPageSixes team={'Adler Mannheim'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/farjestad-bk" element={<LeaguesTeamPageSixes team={'Farjestad BK'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/geneve-servette-hc" element={<LeaguesTeamPageSixes team={'Geneve Servette HC'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/iserlohn-roosters" element={<LeaguesTeamPageSixes team={'Iserlohn Roosters'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/stavanger-oilers" element={<LeaguesTeamPageSixes team={'Stavanger Oilers'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/skelleftea-aik" element={<LeaguesTeamPageSixes team={'Skelleftea AIK'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/straubing-tigers" element={<LeaguesTeamPageSixes team={'Straubing Tigers'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/krefeld-pinguine" element={<LeaguesTeamPageSixes team={'Krefeld Pinguine'} league={'NAEHL'} />} />
                                        <Route path="/SBHL" element={<SBHL_Home />} />
                                        <Route path="/SBHL/standings" element={<StandingsPage league={'sbhl'} />} />
                                        <Route path="/SBHL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'SBHL'} />} />
                                        <Route path="/SBHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'SBHL'} />} />
                                        <Route path="/SBHL/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'SBHL'} />} />
                                        <Route path="/SBHL/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'SBHL'} />} />
                                        <Route path="/SBHL/boston-bruins" element={<LeaguesTeamPageSixes team={'Boston Bruins'} league={'SBHL'} />} />
                                        <Route path="/SBHL/colorado-avalanche" element={<LeaguesTeamPageSixes team={'Colorado Avalanche'} league={'SBHL'} />} />
                                        <Route path="/SBHL/detroit-red-wings" element={<LeaguesTeamPageSixes team={'Detroit Red Wings'} league={'SBHL'} />} />
                                        <Route path="/SBHL/edmonton-oilers" element={<LeaguesTeamPageSixes team={'Edmonton Oilers'} league={'SBHL'} />} />
                                        <Route path="/SBHL/nashville-predators" element={<LeaguesTeamPageSixes team={'Nashville Predators'} league={'SBHL'} />} />
                                        <Route path="/SBHL/ottawa-senators" element={<LeaguesTeamPageSixes team={'Ottawa Senators'} league={'SBHL'} />} />
                                        <Route path="/SBHL/new-york-rangers" element={<LeaguesTeamPageSixes team={'New York Rangers'} league={'SBHL'} />} />
                                        <Route path="/SBHL/toronto-maple-leafs" element={<LeaguesTeamPageSixes team={'Toronto Maple Leafs'} league={'SBHL'} />} />
                                        <Route path="/SBHL/vegas-golden-knights" element={<LeaguesTeamPageSixes team={'Vegas Golden Knights'} league={'SBHL'} />} />
                                        <Route path="/SBHL/winnipeg-jets" element={<LeaguesTeamPageSixes team={'Winnipeg Jets'} league={'SBHL'} />} />
                                        <Route path="/UPHL" element={<UPHL_Home />} />
                                        <Route path="/UPHL/standings" element={<UPHLStandings/>} />
                                        <Route path="/UPHL/skater-leaderboard" element={<SkaterLeaderboardUPHL league={'UPHL'} />} />
                                        <Route path="/UPHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'UPHL'} />} />
                                        <Route path="/UPHL/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'UPHL'} />} />
                                        <Route path="/UPHL/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'UPHL'} />} />
                                        <Route path="/UPHL/boston-bruins" element={<LeaguesTeamPageSixes team={'Boston Bruins'} league={'UPHL'} />} />
                                        <Route path="/UPHL/colorado-avalanche" element={<LeaguesTeamPageSixes team={'Colorado Avalanche'} league={'UPHL'} />} />
                                        <Route path="/UPHL/detroit-red-wings" element={<LeaguesTeamPageSixes team={'Detroit Red Wings'} league={'UPHL'} />} />
                                        <Route path="/UPHL/edmonton-oilers" element={<LeaguesTeamPageSixes team={'Edmonton Oilers'} league={'UPHL'} />} />
                                        <Route path="/UPHL/chicago-blackhawks" element={<LeaguesTeamPageSixes team={'Chicago Blackhawks'} league={'UPHL'} />} />
                                        <Route path="/UPHL/los-angeles-kings" element={<LeaguesTeamPageSixes team={'Los Angeles Kings'} league={'UPHL'} />} />
                                        <Route path="/UPHL/ottawa-senators" element={<LeaguesTeamPageSixes team={'Ottawa Senators'} league={'UPHL'} />} />
                                        <Route path="/UPHL/new-york-rangers" element={<LeaguesTeamPageSixes team={'New York Rangers'} league={'UPHL'} />} />
                                        <Route path="/UPHL/toronto-maple-leafs" element={<LeaguesTeamPageSixes team={'Toronto Maple Leafs'} league={'UPHL'} />} />
                                        <Route path="/THL" element={<THL_Home />} />
                                        <Route path="/THL/standings" element={<StandingsPage league={'thl'} />} />
                                        <Route path="/THL/skater-leaderboard" element={<SkaterLeaderboardSixes league={'THL'} />} />
                                        <Route path="/THL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'THL'} />} />
                                        <Route path="/THL/pit-vipers" element={<LeaguesTeamPageSixes team={'Pit Vipers'} league={'THL'} />} />
                                        <Route path="/THL/wanderers" element={<LeaguesTeamPageSixes team={'Wanderers'} league={'THL'} />} />
                                        <Route path="/THL/north-side-storm" element={<LeaguesTeamPageSixes team={'North Side Storm'} league={'THL'} />} />
                                        <Route path="/THL/comet-crushers" element={<LeaguesTeamPageSixes team={'Comet Crushers'} league={'THL'} />} />
                                        <Route path="/THL/forsworn-knights" element={<LeaguesTeamPageSixes team={'Forsworn Knights'} league={'THL'} />} />
                                        <Route path="/THL/bandits" element={<LeaguesTeamPageSixes team={'Bandits'} league={'THL'} />} />
                                        <Route path="/THL/roaring-tigers" element={<LeaguesTeamPageSixes team={'Roaring Tigers'} league={'THL'} />} />
                                        <Route path="/THL/flaming-toros" element={<LeaguesTeamPageSixes team={'Flaming Toros'} league={'THL'} />} />
                                    </Routes>
                                </Box>
                                <Footer />
                            </Box>
                        </Router>
                    </ThemeProvider>
                </StatsProvider>
            </UserProvider>
        </LoadingProvider>
    );
}

export default App;
