import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import Autocomplete from '@mui/lab/Autocomplete';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, TextField } from '@mui/material';

function SkaterLeaderboardThrees({league}) {

    useEffect(() => {
        document.title = `Chel stats | ${league.toLocaleUpperCase()} Skater Leaderboard`;
    }, []);

    const [sheetData, setSheetData] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'Points',
        direction: 'desc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filterUsername, setFilterUsername] = useState('');
    const [usernameFilter, setUsernameFilter] = useState(null);
    const [positionFilter, setPositionFilter] = useState(null);

    const usernameOptions = useMemo(() => {
        const usernames = new Set(sheetData.slice(1).map(row => {
            const usernameIndex = sheetData[0].indexOf('USERNAME');
            return row[usernameIndex];
        }));
        return Array.from(usernames);
    }, [sheetData]);
    const positionOptions = useMemo(() => {
        const positions = new Set(sheetData.slice(1).map(row => {
            const positionIndex = sheetData[0].indexOf('Position');
            return row[positionIndex];
        }));
        return Array.from(positions);
    }, [sheetData]);

    const visibleColumns = [
        { key: 'USERNAME', sortable: true },
        { key: 'Individual Record', sortable: true },
        { key: 'Team Name', sortable: true },
        { key: 'Platform', sortable: true },
        { key: 'Position', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Offense Rtg', sortable: true },
        { key: 'Defense Rtg', sortable: true },
        { key: 'Teamplay Rtg', sortable: true },
        { key: 'Goals', sortable: true },
        { key: 'Assists', sortable: true },
        { key: 'Points', sortable: true },
        { key: 'G/PG', sortable: true },
        { key: 'A/PG', sortable: true },
        { key: 'P/PG', sortable: true },
        { key: '+/-', sortable: true },
        { key: 'Shots', sortable: true },
        { key: 'Shot attempts', sortable: true },
        { key: 'Shot %', sortable: true },
        { key: 'Shots on net %', sortable: true },
        { key: 'Deflections', sortable: true },
        { key: 'Passes', sortable: true },
        { key: 'Saucers', sortable: true },
        { key: 'Total Passes', sortable: true },
        { key: 'Pass %', sortable: true },
        { key: 'Giveaways', sortable: true },
        { key: 'Hits', sortable: true },
        { key: 'Ints', sortable: true },
        { key: 'Takeaways', sortable: true },
        { key: 'Blocked Shots', sortable: true },
        { key: 'Possession Mins Per Game', sortable: true },
        { key: 'Faceoff %', sortable: true },
        { key: 'PIM', sortable: true },
        { key: 'Penalties drawn', sortable: true },
        { key: 'SH TOI', sortable: true },
        { key: 'PP TOI', sortable: true },
        { key: 'EV TOI', sortable: true },
        { key: 'Shot Attempts', sortable: true },
        { key: 'Shots On Net', sortable: true },
        { key: 'Missed Shots', sortable: true },
        { key: 'Skater EV TOI', sortable: true },
        { key: 'EV Shots For (CF)', sortable: true },
        { key: 'EV Shots Against (CA)', sortable: true },
        { key: 'CF Rel', sortable: true },
        { key: 'Sht% at EV', sortable: true },
        { key: 'WAR', sortable: true },
        { key: 'EVO', sortable: true },
        { key: 'EVD', sortable: true },
        { key: 'SHD', sortable: true },
        { key: 'PTA', sortable: true },
        { key: 'PDA', sortable: true },
        { key: 'TO', sortable: true },
        { key: 'TD', sortable: true },
        { key: 'TPG', sortable: true },
        { key: 'GAR', sortable: true }
    ];


    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/leaderboard?league=${league}&type=skater`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                data = data.values.slice(2);
                setSheetData(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        fetchSheetData();
    }, [setLoading]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const filteredData = useMemo(() => {
        // Assumes the first row is headers and should always be included if no filters are set
        if (!usernameFilter && !positionFilter) return sheetData.slice(1);
    
        return sheetData.filter(row => {
            // If a usernameFilter is set, apply it
            if (usernameFilter) {
                const usernameIndex = sheetData[0].indexOf('USERNAME');
                // Proceed with this row if USERNAME matches or if the USERNAME column doesn't exist
                if (usernameIndex !== -1 && row[usernameIndex] === usernameFilter) {
                    return true;
                }
            }
            // If a positionFilter is set, apply it
            if (positionFilter) {
                const positionIndex = sheetData[0].indexOf('Position');
                // Proceed with this row if POSITION matches or if the POSITION column doesn't exist
                if (positionIndex !== -1 && row[positionIndex] === positionFilter) {
                    return true;
                }
            }
            // If neither filter is matched, don't include this row
            return false;
        });
    }, [sheetData, usernameFilter, positionFilter]);
    

    const sortedData = useMemo(() => {
        // Make sure to use filteredData here
        if (filteredData.length > 0 && filteredData[0].length > sheetData[0].indexOf(sortConfig.key)) {
            const columnIndex = sheetData[0].indexOf(sortConfig.key);
            if (columnIndex > -1) {
                return filteredData.sort((a, b) => {
                    if (sortConfig.key === 'Individual Record') {
                        const extractWins = (record) => {
                            const match = record.match(/^\((\d*)/);
                            return match ? parseInt(match[1], 10) : 0;
                        };

                        const aValue = extractWins(a[columnIndex]);
                        const bValue = extractWins(b[columnIndex]);

                        return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
                    } else {
                        const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                        const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];

                        if (aValue < bValue) {
                            return sortConfig.direction === 'asc' ? -1 : 1;
                        }
                        if (aValue > bValue) {
                            return sortConfig.direction === 'asc' ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }
        }
        return filteredData.map((item, index) => ({
            ...item,
            rank: index + 1 // Assuming rank starts at 1
        })); // Return filteredData directly if not sorting
    }, [filteredData, sortConfig]);

    const sortedAndRankedData = useMemo(() => {
        let dataToSort = [...filteredData]; // Create a shallow copy of the filtered data
    
        if (dataToSort.length > 0 && dataToSort[0].length > sheetData[0].indexOf(sortConfig.key)) {
            const columnIndex = sheetData[0].indexOf(sortConfig.key);
    
            dataToSort.sort((a, b) => {
                // Ensure sorting logic is correctly handling numerical values and strings
                let aValue = a[columnIndex];
                let bValue = b[columnIndex];
                if (!isNaN(+aValue) && !isNaN(+bValue)) {
                    aValue = +aValue;
                    bValue = +bValue;
                }
    
                if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
    
        // Apply ranking after sorting
        return dataToSort.map((item, index) => ({
            ...item,
            rank: index + 1 // Assign rank based on the sorted position
        }));
    }, [filteredData, sortConfig, sheetData]);
    

    const visibleColumnsWithRank = useMemo(() => [
        { key: 'rank', sortable: false, label: 'Rank' },
        ...visibleColumns
    ], [visibleColumns]);

    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };


    return (
        <Box sx={{ maxWidth: '95%', m: 'auto', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ m: 'auto', marginTop: 4, color: 'white' }}>{league} Skater Leaderboard</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                <Autocomplete
                    options={usernameOptions}
                    getOptionLabel={(option) => option ? option.toString() : ''}
                    value={usernameFilter}
                    onChange={(event, newValue) => {
                        setUsernameFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Filter by Username" variant="outlined" />}
                    sx={{
                        marginTop: 2, marginBottom: 0.5, width: 200, input: { color: 'white' }, // Text color
                        label: { color: 'gray' }, // Label color when not focused
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'gray' }, // Border color
                            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                            '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                    }}
                    freeSolo // Allows arbitrary input not limited to the options
                    componentsProps={{
                        clearIndicator: {
                            style: {
                                color: 'gray', // Change to your desired color
                            },
                        },
                    }}
                />
                <Autocomplete
                    options={positionOptions}
                    getOptionLabel={(option) => option ? option.toString() : ''}
                    value={positionFilter}
                    onChange={(event, newValue) => {
                        setPositionFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Filter by Position" variant="outlined" />}
                    sx={{
                        marginTop: 2, marginBottom: 0.5, width: 200, input: { color: 'white' }, // Text color
                        label: { color: 'gray' }, // Label color when not focused
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'gray' }, // Border color
                            '&:hover fieldset': { borderColor: 'white' }, // Border color on hover
                            '&.Mui-focused fieldset': { borderColor: 'gray' }, // Border color when focused
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                    }}
                    freeSolo // Allows arbitrary input not limited to the options
                    componentsProps={{
                        clearIndicator: {
                            style: {
                                color: 'gray', // Change to your desired color
                            },
                        },
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 5 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1C4587' }}>
                            {visibleColumnsWithRank.map((column, columnIndex) => {
                                const { key, sortable, label } = column;
                                return (
                                    <TableCell
                                        key={key}
                                        sx={{
                                            backgroundColor: '#1C4587',
                                            color: 'white',
                                            p: '2px',
                                            paddingLeft: '6px',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRight: columnIndex === visibleColumnsWithRank.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                            position: columnIndex === 1 ? 'sticky' : undefined,
                                            left: columnIndex === 1 ? 0 : undefined,
                                            zIndex: columnIndex === 1 ? 2 : 1,
                                        }}
                                    >
                                        {sortable ? (
                                            <TableSortLabel
                                                active={sortConfig.key === key}
                                                direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                                                onClick={() => handleSort(key)}
                                            >
                                                {label || key.toLocaleUpperCase()}
                                            </TableSortLabel>
                                        ) : label || key.toLocaleUpperCase()}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedAndRankedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {visibleColumnsWithRank.map(({ key }, colIndex) => {
                                    // For 'rank' column, directly use 'row.rank'. For others, use the original sheetData mapping.
                                    const cellValue = key === 'rank' ? row.rank : row[sheetData[0].indexOf(key)];
                                    return (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                fontWeight: '500',
                                                borderRight: colIndex === visibleColumnsWithRank.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                                position: colIndex === 1 ? 'sticky' : undefined,
                                                left: colIndex === 1 ? 0 : undefined,
                                                backgroundColor: colIndex === 1 ? 'white' : undefined,
                                                zIndex: 1,
                                            }}
                                        >
                                            {cellValue}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    );
}

export default SkaterLeaderboardThrees;
